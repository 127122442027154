<script>
  import { fly, slide } from "svelte/transition";
  import { tweened } from "svelte/motion";
  import { cubicIn, linear } from "svelte/easing";

  import Select from "svelte-select";

  export let aasm_object;
  export let aasm_api;
  export let colors = {};
  export let modals = {};
  export let selectedLineItems = [];
  export let detailsVisible = false;

  let responseMessage = null;
  let additionalClass = "";
  let requestingInfoAction = false;
  let submitFunction = null;
  let submitting = false;
  let submitApproveBlocker = true;
  let submitRejectBlocker = false;

  const actionColor = (actionName) => {
    return colors[actionName] || "success";
  };

  let takeAction = (name) => {
    const submitAction = async () => {
      let options = {};
      if (modals[name]) {
        for (let field of modals[name]) {
          if (field.field != "approval_reason") {
            options[field.field] = field.value;
          } else if (aasm_object.approval_reason == "Rescope") {
            options[field.field] = aasm_object.approval_reason;
          } else {
            options[field.field] = field.value.value;
          }
        }
      }

      if (selectedLineItems.length > 0) {
        options["lineItemIds"] = selectedLineItems.map((li) => li.id);
      }
      submitting = true;
      aasm_api[name](aasm_object.id, options).then(async (response) => {
        // Close the modal if any
        requestingInfoAction = false;
        submitFunction = null;
        submitting = false;

        if (response.ok) {
          const jsonData = await response.json();

          if (jsonData.success) {
            handleResponse(jsonData.message || "Success", actionColor(name));
          } else {
            handleResponse(
              jsonData.error || `Error (${response.status})`,
              "warning"
            );
          }
        } else {
          try {
            const jsonData = await response.json();
            handleResponse(
              jsonData.error || `Error (${response.status})`,
              "warning"
            );
          } catch (err) {
            handleResponse(`Error (${response.status})`, "warning");
          }
        }
      });
    };

    return async () => {
      if (submitting) {
        return; // Don't double-submit, e.g. on double-clicks
      }

      if (modals[name]) {
        requestingInfoAction = name;
        submitFunction = submitAction;
      } else {
        return submitAction();
      }
    };
  };

  let handleResponse = (message, bgName) => {
    detailsVisible = false;
    additionalClass = `list-group-item-${bgName}`;
    responseMessage = message;
    if (selectedLineItems.length == 0) {
      left.set(100);
    } else {
      window.location.reload();
    }
  };

  $: {
    switch (requestingInfoAction) {
      case "approve":
        submitApproveBlocker =
          modals[requestingInfoAction][0].value != null &&
          modals[requestingInfoAction][0].value.value
            ? false
            : true;
    }
  }

  $: {
    switch (requestingInfoAction) {
      case "reject":
        if (modals[requestingInfoAction][0].required) {
          submitRejectBlocker =
            modals[requestingInfoAction][0].value != null &&
            modals[requestingInfoAction][0].value
              ? false
              : true;
        }
    }
  }

  const left = tweened(0, { duration: 500, easing: cubicIn });

  const approvalReasonOptions = [
    "Missed",
    "Pricing",
    "Rework",
    "Opportunistic",
    "Unforeseen",
    "Rescope",
    "Savings",
    "Appliance Shortage",
    "Emergency Work",
  ];

  const validateOption = (option) => {
    submitApproveBlocker = option ? false : true;
    return "";
  };

  const selectedLineItemsForWorkApproval = (
    selectedLineItems,
    workApproval
  ) => {
    let included = false;
    for (let lineItem of selectedLineItems) {
      included = workApproval.line_items.includes(lineItem, 0);
    }
    return included;
  };
</script>

<li
  class="list-group-item p-0 {additionalClass}"
  style="overflow: {responseMessage || submitting ? 'hidden' : 'visible'}"
>
  <div
    class="row px-4 py-3"
    style="left: {$left}%; min-width: 100%; position: {$left > 0
      ? 'absolute'
      : 'relative'}"
  >
    <div
      class="col-md-8"
      style="cursor: pointer;"
      on:click={() => (detailsVisible = !detailsVisible)}
    >
      <slot name="header" />
    </div>

    <div class="col-md text-right">
      {#each aasm_object.actions as action}
        {#if action !== "edit_planner_category" && action != "reject_line_items"}
          <div
            class="btn btn-{actionColor(action)} ml-2 {submitting
              ? 'disabled'
              : ''}"
            on:click={takeAction(action)}
            disabled={submitting}
          >
            {#if action != "revision" && action != "reject_line_items"}
              {action[0].toUpperCase() + action.slice(1)}
            {:else}
              Request Revision
            {/if}
          </div>
        {/if}
        {#if action == "reject_line_items" && selectedLineItemsForWorkApproval(selectedLineItems, aasm_object)}
          <div
            class="btn btn-{actionColor(
              'rejectLineItems'
            )} ml-2 {!selectedLineItemsForWorkApproval(
              selectedLineItems,
              aasm_object
            )
              ? 'disabled'
              : ''}"
            on:click={takeAction("rejectLineItems")}
            disabled={!selectedLineItemsForWorkApproval(
              selectedLineItems,
              aasm_object
            )}
          >
            Reject Selected LIs
          </div>
        {/if}
      {/each}
    </div>
  </div>

  {#if detailsVisible}
    <div transition:slide>
      <slot name="details" />
    </div>
  {/if}

  {#if responseMessage}
    <div
      class="row px-4 py-3 text-center"
      style="position: relative; right: {100 - $left}%"
    >
      <div class="col">{@html responseMessage}</div>
    </div>
  {/if}

  {#if requestingInfoAction}
    <div
      class="modal-veil"
      on:click={() => {
        requestingInfoAction = false;
      }}
    ></div>
    <div class="modal-info card" transition:fly={{ y: -400 }}>
      <div class="card-header">
        Information Required
        <button
          type="button"
          class="close"
          aria-label="Close"
          on:click={() => {
            requestingInfoAction = false;
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="card-body">
        <div class="card-text">
          <form>
            {#each modals[requestingInfoAction] as field}
              <div class="form-group">
                <label class="font-weight-bold" for={field.field}
                  >{field.title}</label
                >
                {#if field.helperText}
                  <label
                    class="font-weight-light font-italic"
                    style="display:block"
                    for={field.helperText}>{field.helperText}</label
                  >
                {/if}
                {#if field.options}
                  <select
                    class="form-control"
                    id={field.field}
                    required="true"
                    bind:value={field.value}
                  >
                    <option value="">--Please choose an option--</option>
                    {#each field.options as opt}
                      <option value={opt}>{opt}</option>
                    {/each}
                  </select>
                {:else if requestingInfoAction != "approve"}
                  <input
                    class="form-control"
                    id={field.field}
                    bind:value={field.value}
                  />
                {:else if requestingInfoAction == "approve"}
                  {#if aasm_object.approval_reason == "Rescope"}
                    <div>
                      <strong
                        >CO submitted during scope acceptance period, type is
                        automatically re-scope.</strong
                      >
                    </div>
                    {validateOption(aasm_object.approval_reason)}
                    <br />
                  {:else}
                    <Select
                      placeholder="Select a reason..."
                      items={approvalReasonOptions}
                      bind:value={field.value}
                      on:select={validateOption(field.value)}
                    />
                  {/if}
                {/if}
              </div>
            {/each}
          </form>
        </div>
      </div>

      <div class="card-footer text-right">
        {#if requestingInfoAction == "cancel"}
          <button
            class="btn btn-outline-danger ml-2"
            on:click={() => {
              requestingInfoAction = false;
            }}
          >
            Cancel
          </button>
        {/if}

        {#if requestingInfoAction == "approve"}
          <button
            class="btn btn-{actionColor(requestingInfoAction)} ml-2"
            on:click={submitFunction}
            disabled={submitApproveBlocker}
          >
            {requestingInfoAction[0].toUpperCase() +
              requestingInfoAction.slice(1)}
          </button>
        {/if}

        {#if requestingInfoAction == "revision"}
          <button
            class="btn btn-{actionColor(requestingInfoAction)} ml-2"
            on:click={submitFunction}
          >
            Request Revision
          </button>
        {/if}

        {#if requestingInfoAction == "reject"}
          <button
            class="btn btn-{actionColor(requestingInfoAction)} ml-2"
            on:click={submitFunction}
            disabled={submitRejectBlocker}
          >
            {requestingInfoAction[0].toUpperCase() +
              requestingInfoAction.slice(1)}
          </button>
        {/if}
      </div>
    </div>
  {/if}
</li>

<style>
  .modal-veil {
    z-index: 4999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #333;
    opacity: 0.7;
  }

  .modal-info {
    z-index: 5000;
    top: 100px;
    min-width: 400px;
    background-color: white;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
  }
</style>
