// Maybe get rid of all this as I switch to Svelte
import 'jquery'
import 'popper.js'
import 'bootstrap'

// Svelte Imports
import WebpackerSvelte from 'webpacker-svelte'

import ChangeOrderQueue from '../../svelte_components/change_orders/queue.svelte'
import Comments from '../../svelte_components/comments/comments.svelte'
import PaymentQueue from '../../svelte_components/payments/queue.svelte'
import ProjectAttachments from '../../svelte_components/projects/attachments.svelte'
import ProjectGc from '../../svelte_components/projects/gc.svelte'
import ProjectRm from '../../svelte_components/projects/rm.svelte'
import DeferProject from '../../svelte_components/projects/defer_project.svelte'
import ProjectSchedule from '../../svelte_components/projects/schedule.svelte'
import ProjectWorkOrders from '../../svelte_components/projects/work_orders/list.svelte'
import ProjectWorkApprovals from '../../svelte_components/projects/work_approvals/list.svelte'
import PropertyInfoField from '../../svelte_components/property_info_fields/field'
import RmActiveRenos from '../../svelte_components/rm_profiles/active_renos.svelte'
import RenoAdminList from '../../svelte_components/projects/admin_list.svelte'
import RenoChangeOrdersSection from '../../svelte_components/projects/change_orders.svelte'
import RenoCheckInSection from '../../svelte_components/projects/check_ins.svelte'
import RenoCompletionDate from '../../svelte_components/projects/completion_date.svelte'
import RenoJointCertification from '../../svelte_components/projects/joint_certification.svelte'
import RenoLineItemViewButton from '../../svelte_components/projects/line_item_view_button.svelte'
import RenoManualExpenses from '../../svelte_components/projects/manual_expenses.svelte'
import ProjectDueDate from '../../svelte_components/projects/due_date.svelte'
import SalesRepairScopeShow from '../../svelte_components/sales_repair_scopes/show.svelte'
import SimpleScopeShow from '../../svelte_components/simple_scopes/show.svelte'
import WorkApprovalQueue from '../../svelte_components/work_approvals/queue.svelte'
import WorkOrderTabs from '../../svelte_components/work_orders/tabs.svelte'
import CategoryPlannerQueue from '../../svelte_components/planner_categorization/queue.svelte'
import PropertyFuzzySearch from '../../svelte_components/lib/property_fuzzy_search.svelte'
import PropertyChangeOrders from '../../svelte_components/properties/change_orders.svelte'
import PropertySFR3Details from '../../svelte_components/properties/sfr3_details.svelte'
import AdminPaymentModal from '../../svelte_components/admin/admin_payment_modal.svelte'

// Rails reqs
require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start() // I should take this out after I get the filepond input working
require('../../javascript/channels')

// Axios provides a nicer HTTP interface
window.axios = require('axios')

// Svelte Set-Up
WebpackerSvelte.setup({
  ChangeOrderQueue,
  Comments,
  PaymentQueue,
  ProjectAttachments,
  ProjectGc,
  ProjectRm,
  DeferProject,
  ProjectSchedule,
  ProjectWorkOrders,
  ProjectWorkApprovals,
  PropertyInfoField,
  RmActiveRenos,
  RenoAdminList,
  RenoChangeOrdersSection,
  RenoCheckInSection,
  RenoCompletionDate,
  RenoJointCertification,
  RenoLineItemViewButton,
  RenoManualExpenses,
  ProjectDueDate,
  SalesRepairScopeShow,
  SimpleScopeShow,
  WorkApprovalQueue,
  WorkOrderTabs,
  CategoryPlannerQueue,
  PropertyFuzzySearch,
  PropertyChangeOrders,
  PropertySFR3Details,
  AdminPaymentModal
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the 'imagePath' JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
