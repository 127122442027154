<script>
  import Select from 'svelte-select'

  export let selectedRoom
  export let rooms
</script>

<style>
  .themed {
    --borderFocusColor: #80bdff;
    --border: 1px solid #ced4da;
    --borderRadius: 0.25rem;
    --borderHoverColor: #ced4da;
    --height: 34px;
    --clearSelectTop: 4px;
    --clearSelectBottom: 4px;
  }
 </style>

<div class="themed">
  <Select
    items={rooms}
    optionIdentifier="name"
    labelIdentifier="name"
    placeholder="Select a Room..."
    bind:value={selectedRoom}
  />
</div>

