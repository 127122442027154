<script>
 import LIProgressBar from '../../line_items/progress_bar'

 export let milestone
 export let selectMilestone = () => {}

 $: lineItems = milestone.line_items

 const clickedMilestone = () => {
   selectMilestone()
 }
</script>

<style>
 .root:hover {
   background-color: #ddd;
   cursor: pointer;
 }
</style>

<div class="root p-3" on:click={clickedMilestone}>
  <div class="row">
    <div class="col">{milestone.start_date}</div>
    <div class="col text-right">{milestone.end_date}</div>
  </div>
  <div class="my-2"><LIProgressBar bind:lineItems/></div>
  <div class="row">
    <div class="col">{milestone.name}</div>
    <div class="col">{milestone.vendor.name}</div>
  </div>
</div>
