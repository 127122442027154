<script>
 import VendorSelect from './select'

 export let project
 export let gc

 let market = project.market_code
 // TODO Set context to the project since that is used in vendor select

 const getVendorLabel = (vendor) => {
   const vendor_gc_capacity = vendor.gc_capacity == null ? 0 : vendor.gc_capacity
   const activeNum = vendor_gc_capacity - vendor.remaining_gc_capacity
   return `${vendor.name} (${activeNum}/${vendor_gc_capacity})`
 }
</script>

<VendorSelect {market} bind:vendor={gc} {getVendorLabel} vendorType="gc" />
