<script>
 import { slide } from 'svelte/transition'

 export let maxHeightPx = 200

 let detailsVisible = false

 let boxHeight = 0
 $: showButton = boxHeight >= maxHeightPx - 2 // the -2 is for the borders
</script>

<div class="card {detailsVisible ? '' : 'preview'}"
     style={detailsVisible ? '' : `max-height: ${maxHeightPx}px`}
     bind:clientHeight={boxHeight}>
  <slot>Details go here</slot>
</div>

{#if showButton}
  <div class="text-center mt-n2 expand-button-container" transition:slide>
    <div class="btn btn-light btn-small shadow-sm"
         on:click={() => detailsVisible = !detailsVisible}>
      {detailsVisible ? 'Collapse' : 'View All...'}
    </div>
  </div>
{/if}

<style>
 .preview {
   overflow-y: scroll;
 }

 .expand-button-container {
   position: relative;
 }

 .expand-button-container > .btn-light {
   border: 1px solid #999;
   padding: 0.25rem 0.5rem;
 }
</style>
