<script>
  import { SFR3 } from "../../javascript/sfr3_api";
  import Modal from "../lib/modal";
  import LineItemView from "./line_item_view";
  import { Stretch } from "svelte-loading-spinners";

  export let lineItems = [];
  export let csvName = "Line Item Sheet";
  export let small = true;
  export let btnClass = "btn-primary";

  export let project;

  let showModal = false;
  let loading = false;

  const loadLineItems = async () => {
    loading = true;
    const response = await SFR3.projects.lineItemsProjectView(project.id);
    const data = await response.json();

    if (!response.ok) {
      alert("Error loading line items");
      loading = false;
      return;
    }

    if (response.ok) {
      lineItems = data.items;
    }

    loading = false;
  };
</script>

<button
  class="btn {btnClass}"
  class:btn-sm={small}
  on:click={() => {
    showModal = !showModal;
    loadLineItems();
  }}
>
  View All Line Items
</button>

{#if showModal}
  {#if loading}
    <div class="text-center">
      <Stretch color="#999999" />
    </div>
  {:else}
    <Modal
      showDialog={true}
      title="Line Items"
      hideFooter={true}
      on:close={() => (showModal = false)}
    >
      <LineItemView {lineItems} {csvName} />
    </Modal>
  {/if}
{/if}
