<script>
 import { onMount } from 'svelte'
 import { slide } from 'svelte/transition'
 import Fa from 'svelte-fa'
 import {
   faMinusCircle,
   faPlusCircle,
   faFileDownload,
   faHammer,
   faPencilRuler,
   faTrashAlt,
   faPaperPlane,
   faStar } from '@fortawesome/free-solid-svg-icons'

 import { SFR3 } from '../../javascript/sfr3_api'
 import { formatCents } from '../../javascript/money'
 import Permissions from '../../javascript/permissions'

 import AttachmentSection from '../lib/attachment_section'
 import Comments from '../comments/comments'     

 import ScopeLineItems from './line_items'

 /**
  * A scope has the format:
  * {
  *   id: <int>|null
  *   property: { id: <int>, address: <string>, metro: <string> etc. },
  *   line_items: [<line_items>],
  *   attachments: [<attachment>]
  *   status: <string>
  *   project_type: { name: <string> }
  *   creator: { name: <string> }
  *   scoper: { name: <string> }
  *   reviewer: { name: <string> }
  * }
  **/
 export let scope
 export let projectUrl
 export let hideDeadline

 let attachments = scope.attachments || []
 let lineItems = scope.line_items || []
 let status = scope.status || 'created'
 let deadline = ''
 let transitionBlocker = null
 const id = scope.id
 const projectType = scope.project_type.name

 $: totalCostCents = lineItems.reduce(((sum, li) => sum + li.cost_cents), 0)

 $: {
   if (lineItems.filter((li) => li.quantity != Math.floor(li.quantity)).length > 0) {
      transitionBlocker = 'Quantities must be whole numbers'
   } else if (lineItems.filter((li) => li.quantity < 1).length > 0) {
      transitionBlocker = 'Enter a quantity to all new line items'
   } else if (lineItems.filter((li) => (li.category == "Other" && li.planner_category == null)).length > 0) {
      transitionBlocker = "Must select a Service Category when selecting 'Other' Line Items"
   } else if (lineItems.filter((li) => li.name == null).length > 0) {
      transitionBlocker = "Error saving: Line items name can't be blank"
    } else if (lineItems.filter((li) => li.cost_cents == 0 && !li.covered_under_warranty).length > 0) {
      transitionBlocker = "Set a price for all line items"
    } else {
      transitionBlocker = null
   }
 }

 /**
  *  Permissions
  */
 let CAN = {}
 onMount(async () => CAN = await Permissions.forScope('simpleScopes', id))

 $: editing = CAN.edit && status == 'editing'
 $: canUpload = CAN.add_attachments && ['editing', 'with_buyer', 'reviewing'].includes(status)

 /**
  * State Machine - statuses and actions
  */
 const ACTIONS = {
   startScoping: { key: 'start_scoping', title: 'Start Scoping', color: 'primary', icon: faPencilRuler },
   finishEditing: { key: 'finish_editing', title: 'Finish Editing', color: 'success', icon: faStar },
   reject: { key: 'reject', title: 'Request Edits', color: 'primary', icon: faPencilRuler },
   approve: { key: 'approve', title: 'Approve', color: 'success', icon: faStar  },
   cancel: { key: 'cancel', title: `Cancel ${projectType}`, color: 'danger', icon: faTrashAlt },
   startProject: { key: 'start_project', title: `Start ${projectType}`, color: 'success', icon: faHammer }
 }

 const STATUSES = {
   created: {
     title: 'New',
     key: 'created',
     color: 'primary',
     actions: [ACTIONS.startScoping, ACTIONS.cancel]
   },
   editing: {
     title: 'Editing',
     key: 'editing',
     color: 'primary',
     actions: [ACTIONS.finishEditing, ACTIONS.cancel]
   },
   reviewing: {
     title: 'Reviewing',
     key: 'reviewing',
     color: 'warning',
     actions: [ACTIONS.approve, ACTIONS.reject, ACTIONS.cancel]
   },
   approved: {
     title: 'Approved',
     key: 'approved',
     color: 'success',
     actions: [ACTIONS.startProject, ACTIONS.reject, ACTIONS.cancel]
   },
   project_started: {
     title: `${projectType} Started`,
     key: 'project_started',
     color: 'success',
     actions: []
   },
   cancelled: {
     title: 'Cancelled',
     key: 'cancelled',
     color: 'danger',
     actions: []
   }
 }
 $: statusObj = (STATUSES[status] || STATUSES.created)

 const statusTransition = (action, deadline) => {
   if(transitionBlocker && action != 'cancel') {
    alert(transitionBlocker)
   } else {
      return async () => {
        if (CAN[action.key]) {
          try {
            const response = await SFR3.simpleScopes.transition(id, action.key, deadline)
            if (response.ok) {
              const data = (await response.json())
              status = data.scope.status
              projectUrl = data.project_url
            } else {
              const data = await response.json()
              alert(data.error)
            }
          } catch {
            alert('Unknown error when transitioning statuses')
          }
        }
      }
   }
 }

 /**
  * Attachment Uploading
  */
 // Right now, newAttachments is an array of strings (blob ids)
 let newAttachments = []
 let uploading = false
 let clearFiles = () => {}
 let uploadingAttachment = false
 $: {
   if (!uploadingAttachment && newAttachments.length > 0) {
     uploadNewAttachments()
   }
 }

 const uploadNewAttachments = async () => {
   uploadingAttachment = true
   if (newAttachments.length > 0) {
     const uploadedKeys = [...newAttachments]
     const response = await SFR3.simpleScopes.addAttachments(id, newAttachments)

     // Clear out attachments (especially so we do not keep uploading repeatedly)
     clearFiles(uploadedKeys)

     // Show new attachments
     const data = await response.json()
     attachments = [...attachments, ...data.attachments]
   }
   uploadingAttachment = false
 }

 /**
  * Tie it all together - Keep scope up-to-date with all the changes happening
  */
 $: scope = { ...scope, id, attachments, lineItems, status };
 $: approvedDate = scope.approved_at ? `on ${getFormattedDate(new Date(`${scope.approved_at} 00:00`.replace(/-/g, "/")))}` : '' ;
 $: scopedDate = scope.scoped_at ? `on ${getFormattedDate(new Date(`${scope.scoped_at} 00:00`.replace(/-/g, "/")))}` : '' ;

 const getFormattedDate = (date) => {
    var year = date.getFullYear()
    var month = (1 + date.getMonth()).toString()
    var day = date.getDate().toString()
    day = day.length > 1 ? day : '0' + day

    return month + '/' + day + '/' + year;
  }
</script>

<style>
 .action {
   cursor: pointer;
   font-size: x-large;
 }

 .status-badge {
   font-size: larger;
 }
</style>

<div class="container-lg mt-3">
  <div class="row bg-light pt-3 order-sm-1">
    <div class="col-sm-4 order-sm-2 mb-2">
      <div class="row">
        <div class="col order-sm-2 text-sm-right">
          <h2 class="my-0">
            {formatCents(totalCostCents)}
          </h2>
        </div>
        <div class="col col-sm-12 text-right">
          {#if statusObj}
            <div class="badge badge-{statusObj.color} status-badge">{statusObj.title}</div>
          {/if}
        </div>
      </div>
    </div>
    <div class="col-sm">
      <h2 class="mb-0">
        {projectType} Scope
        <br/>
        <small class="text-muted">{scope.property.address}</small>
        <br/>
      </h2>
      <h5 class="mb-0">
        <small class="text-muted">
          {#if scope.scoper}
            Scoped by: {scope.scoper.name} {approvedDate}
          {/if}
        </small>
        <br/>
        <small class="text-muted">
          {#if scope.reviewer}
            Approved by: {scope.reviewer.name} {scopedDate}
          {/if}
        </small>
      </h5>
      <div>
        {#if scope.project_type.has_deadline && !hideDeadline}
            {#if statusObj.key == 'created'} 
              Deadline:
              <input bind:value={deadline} placeholder="mm-dd-yyyy">
            {:else}
              <div>
                Deadline: {deadline}
              </div>
            {/if}
        {/if}
      </div>
    </div>
  </div>

  <div class="row bg-light pb-3">
    {#each statusObj.actions as action}
      {#if CAN[action.key]}
        <div class="col-sm">
          <div class="btn btn-{action.color} mr-3 mt-3 btn-block"
               on:click={statusTransition(action, deadline)}>
            <Fa icon={action.icon} class="mr-2" />{action.title}
          </div>
        </div>
      {/if}
    {/each}
    {#if projectUrl}
      <div class="col-sm">
        <a class="btn btn-outline-primary mr-3 mt-3 btn-block" target="_blank" href="{projectUrl}">
          <Fa icon={faHammer} class="mr-2" />Open {projectType}
        </a>
      </div>
    {/if}
  </div>
  <div class="col-sm">
    {#if (transitionBlocker)}
      <p class="alert alert-warning">Warning! {transitionBlocker}</p>
    {/if}
  </div>
  <ScopeLineItems bind:lineItems market={scope.property.metro} {editing} {scope} />

  <AttachmentSection bind:files={attachments} bind:canUpload
                     bind:newFiles={newAttachments} bind:uploading
                     bind:clearFiles/>

  <div class="row mt-3">
    <div class="col-sm-3"><h2>SFR3 Internal Comments</h2></div>
    <div class="col"><Comments commentableId={id} commentableType="SimpleScope"/></div>
  </div>
</div>
