import { SFR3 } from './sfr3_api.js'

if (!window._SFR3_PERMISSIONS) {
  console.error('Missing Permissions')
}

function can (permission_name) {
  return !!window._SFR3_PERMISSIONS[permission_name]
}


const PERMISSION_CACHE = {}

async function forScope(scopeName, id=null) {
  const name = id ? `${scopeName}--${id}` : scopeName
  if (!PERMISSION_CACHE[name]) {
    const permissionMethod = scopeName.split('.').reduce((o, namespace) => o[namespace] || {}, SFR3).permissions
    if (!permissionMethod) {
      PERMISSION_CACHE[name] = {}
    } else {
      const response = await permissionMethod(id)
      if (response.ok) {
        PERMISSION_CACHE[name] = await response.json()
      } else {
        console.error(`Error loading permissions for ${name}`)
        PERMISSION_CACHE[name] = {}
      }
    }
  }

  return PERMISSION_CACHE[name]
}


export default {
  can,
  forScope,
  forSalesRepairScopes: async (id=null) => forScope('salesRepairScopes', id),
  forSalesRepairs: async (id=null) => forScope('projects.salesRepairs', id)
}
