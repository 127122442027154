import { writable } from 'svelte/store';

const storedMarkets = localStorage.getItem('waStoredMarkets');
const storedProjectTypes = localStorage.getItem('waStoredProjectTypes');
export const markets = writable(JSON.parse(storedMarkets));
export const projectTypes = writable(JSON.parse(storedProjectTypes));

markets.subscribe(value => {
  localStorage.setItem("waStoredMarkets", JSON.stringify(value));
})

projectTypes.subscribe(value => {
  localStorage.setItem("waStoredProjectTypes", JSON.stringify(value));
})

const storedHiddenField = localStorage.getItem('waStoredHiddenField');
export const hiddenField = writable(storedHiddenField)

hiddenField.subscribe(value => {
  localStorage.setItem("waStoredHiddenField", value)
})
