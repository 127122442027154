<script>
 import { onDestroy } from 'svelte';
 import { onMount } from 'svelte';
 import Select from 'svelte-select';
 
 export let unselectedLineItems
 export let selectedLineItems
 export let cancellationRequestedItem
 export let update = false
 
 let selectedItem = null
 let previousItem = null
 
 $: items = (
   selectedItem ? [...$unselectedLineItems, selectedItem] : $unselectedLineItems
 ).map((li) => {
   return {
     value: li,
     label: `${li.quantity} x ${li.name}${li.section ? ` (${li.section})` : ''}`,
     status: li.status,
     vendorName: li.vendor && li.vendor.name
   }
 })

 const handleSelect = (event) => {
   selectedItem = event.detail.value
   selectedLineItems.remove(previousItem)
   selectedLineItems.add(selectedItem)
   previousItem = selectedItem
 }

 const handleClear = () => {
   selectedLineItems.remove(previousItem)
   selectedItem = null
   cancellationRequestedItem = null
   previousItem = null
 }

 const groupBy = (liOption) => liOption.vendorName || liOption.status

 onDestroy(() => cancellationRequestedItem ? selectedLineItems.remove(cancellationRequestedItem) : selectedLineItems.remove(selectedItem))

 const buildValue = () => {
   if (cancellationRequestedItem) {
    return `${cancellationRequestedItem.quantity} x ${cancellationRequestedItem.name}`
   }
 }

 const addItemToSelectedLineItems = async () => {
   if (cancellationRequestedItem) {
    selectedLineItems.remove(previousItem)
    selectedLineItems.add(cancellationRequestedItem)
    previousItem = cancellationRequestedItem
   }
 }
 onMount(async () => addItemToSelectedLineItems())
</script>

<div class="form-row my-2">
  <div class="col themed-select">
    {#if update}
      <input value="{buildValue()}" disabled class="form-control" />
    {:else}
        <Select
        value={buildValue()}
        {items}
        {groupBy}
        on:select={handleSelect}
        on:clear={handleClear}
      />
    {/if}

  </div>

  <div class="col-2">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">$</span>
      </div>
      {#if cancellationRequestedItem}
        <input
          type="number"
          step="0.01"
          class="form-control text-right"
          aria-label="Amount"
          disabled={true}
          value={-1 * cancellationRequestedItem.cost_cents / 100}>
      {:else}
        <input 
          type="number"
          step="0.01"
          class="form-control text-right"
          aria-label="Amount"
          disabled={true}
          value={selectedItem ? -1 * selectedItem.cost_cents / 100 : ''}>
      {/if}  
    </div>
  </div>
</div>

<style>
  .themed-select {
    --borderFocusColor: #80bdff;
    --border: 1px solid #ced4da;
    --borderRadius: 0.25rem;
    --borderHoverColor: #ced4da;
    --height: 34px;
    --clearSelectTop: 4px;
    --clearSelectBottom: 4px;
  }
 </style>