<script>
  import { onMount } from "svelte";
  import Fa from "svelte-fa";
  import { faMoneyCheckAlt } from "@fortawesome/free-solid-svg-icons";

  import Modal from "../lib/modal";
  import { SFR3 } from "../../javascript/sfr3_api";

  export let showDialog = false;
  export let projectId = "";
  let vendors = [];

  let submitting = false;
  let loaded = false;
  let flashMessage = null;
  let errorMessage = null;

  function toggleDialog() {
    showDialog = !showDialog;

    if (showDialog) {
      loadVendors();
    }
  }

  async function loadVendors() {
    const response = await SFR3.projects.vendorsWithPayments(projectId);

    vendors =
      (await response.json()).items.map((vendor) => ({
        ...vendor,
        selected: false,
      })) || [];
    loaded = true;
  }

  const closeModal = () => {
    showDialog = false;
  };

  function toggleVendorSelection(vendor) {
    vendors = vendors.map((v) =>
      v.id === vendor.id ? { ...v, selected: !v.selected } : v
    );
  }

  function selectAllVendors() {
    vendors = vendors.map((v) => ({ ...v, selected: true }));
  }

  async function createPayments() {
    submitting = true;

    const selectedVendors = vendors.filter((vendor) => vendor.selected);
    if (selectedVendors.length === 0) {
      submitting = false;
      return;
    }

    try {
      const response = await SFR3.projects.createPaymentsForVendors(projectId, {
        vendor_ids: selectedVendors.map((v) => v.id),
      });

      if (response.ok) {
        const data = await response.json();
        showDialog = false;
        displayFlashMessage(
          `Created ${data.items.length} payments successfully.`
        );
      } else {
        const data = await response.json();
        showDialog = false;
        if (data.error) {
          displayErrorMessage(data.error);
        } else {
          displayErrorMessage("An error occurred. Please try again.");
        }
      }
    } catch (e) {
      displayErrorMessage("An error occurred. Please try again.");
    } finally {
      submitting = false;
    }
  }

  function displayFlashMessage(message) {
    flashMessage.textContent = message;
    flashMessage.style.display = "block";
    setTimeout(() => {
      flashMessage.style.display = "none";
    }, 3500);
  }

  function displayErrorMessage(message) {
    errorMessage.textContent = message;
    errorMessage.style.display = "block";
    setTimeout(() => {
      errorMessage.style.display = "none";
    }, 3500);
  }

  onMount(() => {
    showDialog = false;
    loaded = false;
    vendors = [];
  });
</script>

<div class="edit-button">
  <div class="btn btn-primary" on:click|stopPropagation={toggleDialog}>
    <Fa icon={faMoneyCheckAlt} />
    Create Outstanding Payments
  </div>
</div>

<div class="flash-message alert alert-success" bind:this={flashMessage}></div>
<div class="flash-message alert alert-danger" bind:this={errorMessage}></div>

<Modal
  bind:showDialog
  hideFooter={true}
  title="Create payments for project {projectId}"
  on:click={closeModal}
>
  {#if !loaded}
    <div class="alert alert-info">Loading...</div>
  {:else}
    <h3 class="lead">Select vendors...</h3>
    <div class="vendor-list shadow-sm">
      <ul class="list-group">
        {#if vendors.length === 0}
          <li class="list-group-item list-group-item-danger">
            No vendors found
          </li>
        {:else}
          {#each vendors as vendor}
            <li
              class="list-group-item"
              on:click={toggleVendorSelection.bind(this, vendor)}
              style="cursor: pointer;"
            >
              <input
                type="checkbox"
                bind:checked={vendor.selected}
                on:click|stopPropagation
              />
              <span style="margin-left: 10px;">{vendor.name}</span>
              <span style="margin-left: 10px;">({vendor.id})</span>
            </li>
          {/each}
        {/if}
      </ul>
    </div>
    <hr />
    <div class="mt-3 d-flex">
      <button class="btn btn-success p-2" on:click={selectAllVendors}
        >Select All</button
      >
      <button
        class="btn btn-primary ml-auto p-2"
        on:click={createPayments}
        disabled={!vendors.some((v) => v.selected) || submitting}
        >Create Payments</button
      >
    </div>
  {/if}
</Modal>

<style>
  .vendor-list {
    margin-top: 20px;
    max-height: 250px;
    overflow-y: auto;
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 4px;
  }

  .flash-message {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    display: none;
    z-index: 9999;
    text-align: center;
  }
</style>
