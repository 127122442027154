<script>
 import { onMount } from 'svelte'
 import { slide } from 'svelte/transition'
 import Fa from 'svelte-fa'
 import { faTimes } from '@fortawesome/free-solid-svg-icons'

 import LineItemForm from './line_item_form'

 import { SFR3 } from '../../javascript/sfr3_api'

 export let lineItems = []
 export let market

 let categoryOptions

 // When initializing, set a key on each line item
 // (resetting if it is loaded by line items that already have keys; doesn't matter)
 let latestKey = 0
 lineItems.map((li) => {
   latestKey = latestKey + 1
   li.key = latestKey
 })

 function addLineItem () {
   latestKey = latestKey + 1
   lineItems = [ ...lineItems, { key: latestKey } ];
 }

 function removeLineItem (lineItem) {
   return () => {
     const idx = lineItems.indexOf(lineItem)
     lineItems = [ ...lineItems.slice(0, idx), ...lineItems.slice(idx+1) ]
   }
 }

 const loadCategoryOptions = async () => {
     const response = await SFR3.services.scopingCategoryOptions()
     categoryOptions = await response.json()
 }

 onMount(async () => {
   loadCategoryOptions();
 })
</script>

<style>
 .action {
   cursor: pointer;
 }
</style>

<div class="row my-2">
  <div class="col text-right">
    <button class="btn btn-outline-primary" on:click={addLineItem}>+ Add Line Item</button>
  </div>
</div>

{#each lineItems as lineItem (lineItem.key)}
  <div class="row" transition:slide>
    <div class="action-container text-center d-flex flex-column justify-content-center">
      <span class="text-danger action" on:click={removeLineItem(lineItem)}>
        <Fa icon={faTimes}/>
      </span>
    </div>
    <div class="col">
      <LineItemForm bind:lineItem {market} {categoryOptions}></LineItemForm>
    </div>
  </div>
{/each}
