<script>
  export let symbol = '$'
  export let amount = 0
  export let disabled
  export let isInvalid
</script>

<div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text">{symbol}</span>
  </div>
  <input type="number"
         step="0.01"
         class="form-control text-right {isInvalid ? 'is-invalid' : ''}"
         aria-label="Amount"
         disabled={disabled}
         bind:value={amount}>
</div>
