<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { SFR3 } from "../../javascript/sfr3_api";
  import { formatCents } from "../../javascript/money";

  import Select from "svelte-select";

  import AmountField from "../lib/amount_field";
  import Uploader from "../lib/uploader";
  import VendorSelect from "../vendors/select";

  export let project;

  $: address = project.property.address;

  const PAYMENT_OPTIONS = [
    {
      name: "Batch",
      value: "batch",
      helpText: "Pay with the next batch of payments on Wednesday",
    },
    {
      name: "Already Paid",
      value: "import",
      helpText: "Do NOT pay via RenoBot; already paid via another channel",
    },
  ];

  let expenseCategories = [];
  let categoriesLoaded = false;
  let amount = null;
  let attachments = [];
  let uploadingAttachments = false;
  let validationOn = false;
  let submitting = false;

  let paymentOption;
  let selectedCategory;
  let description;
  let vendor;

  const dispatch = createEventDispatcher();

  $: paymentMethod = paymentOption ? paymentOption.value : null;

  $: categoryError = !(selectedCategory && selectedCategory.id)
    ? "Select a category"
    : null;
  $: descriptionError = !(description && description.length > 0)
    ? "Add a description"
    : null;
  $: vendorError = !(vendor && vendor.id) ? "Select a vendor" : null;
  $: amountError = !(amount && amount > 0)
    ? "Amount must be greater than $0"
    : null;
  $: attachmentsError = !(attachments.length > 0)
    ? "Upload at least one attachment"
    : null;
  $: paymentMethodError = !paymentMethod ? "Select a payment option" : null;
  $: canSubmit = !(
    categoryError ||
    descriptionError ||
    vendorError ||
    amountError ||
    attachmentsError ||
    paymentMethodError
  );

  $: {
    // Set up expenseCategories so they can be used directly as options in Select
    for (const category of expenseCategories) {
      category.label = `<strong>${category.name}</strong>&nbsp;<em class="font-weight-light">${category.description}</em>`;
    }
  }

  $: noOptionsMessage = categoriesLoaded ? "No Categories Match" : "Loading...";

  const loadExpenseCategories = async () => {
    const response = await SFR3.expenseCategories.list();
    try {
      if (response.ok) {
        expenseCategories = await response.json();
      } else {
        alert((await response.json()).error);
      }
    } catch {
      alert("Unknown Error Loading Categories");
    }
    categoriesLoaded = true;
  };

  async function submit() {
    submitting = true;
    if (!canSubmit) {
      validationOn = true;
    } else {
      const response = await SFR3.manualExpenses.create({
        project_id: project.id,
        description: description,
        vendor_id: vendor.id,
        expense_category_id: selectedCategory.id,
        amount: amount,
        attachments: attachments,
        payment_method: paymentMethod,
      });

      try {
        const data = await response.json();
        if (response.ok && data.success) {
          dispatch("created", data.manual_expense);
        } else {
          alert(data.error);
        }
      } catch {
        alert("Unknown Error Submitting Expense - Reloading Page");
        window.location.reload();
      }
    }
    submitting = false;
  }

  onMount(loadExpenseCategories);
</script>

<div class="form-group">
  <label class={validationOn && paymentMethodError ? "text-danger" : ""}>
    Payment Type
    {#if validationOn && paymentMethodError}<div>
        <em class="font-weight-light">{paymentMethodError}</em>
      </div>{/if}
  </label>
  <div>
    <div class="btn-group mr-2">
      {#each PAYMENT_OPTIONS as option (option.value)}
        <button
          class="btn btn-{paymentOption == option
            ? 'info'
            : 'outline-secondary'}"
          on:click={() =>
            (paymentOption = paymentOption == option ? null : option)}
        >
          {option.name}
        </button>
      {/each}
    </div>
    {#if paymentOption}
      <div class="d-block d-md-none pb-2" />
      <em class="font-weight-light">{paymentOption.helpText}</em>
    {/if}
  </div>
</div>

<div class="form-group">
  <label class={validationOn && categoryError ? "text-danger" : ""}>
    Category
    {#if validationOn && categoryError}<div>
        <em class="font-weight-light">{categoryError}</em>
      </div>{/if}
  </label>
  <Select
    items={expenseCategories}
    optionIdentifier="id"
    {noOptionsMessage}
    bind:value={selectedCategory}
    hasError={validationOn && categoryError}
    placeholder="Category..."
  />
</div>

<div class="form-group">
  <label class={validationOn && descriptionError ? "text-danger" : ""}>
    Description
    {#if validationOn && descriptionError}<div>
        <em class="font-weight-light">{descriptionError}</em>
      </div>{/if}
  </label>
  <input
    class="form-control {validationOn && descriptionError ? 'is-invalid' : ''}"
    bind:value={description}
    placeholder="Description..."
  />
</div>

<div class="form-group">
  <label class={validationOn && vendorError ? "text-danger" : ""}>
    Vendor
    {#if validationOn && vendorError}<div>
        <em class="font-weight-light">{vendorError}</em>
      </div>{/if}
  </label>
  <VendorSelect bind:vendor hasError={validationOn && vendorError} />
</div>

<div class="form-group">
  <label class={validationOn && amountError ? "text-danger" : ""}>
    Amount
    {#if validationOn && amountError}<div>
        <em class="font-weight-light">{amountError}</em>
      </div>{/if}
  </label>
  <AmountField bind:amount isInvalid={validationOn && amountError} />
</div>

<div class="form-group">
  <label class={validationOn && attachmentsError ? "text-danger" : ""}>
    Documentation and Images
    {#if validationOn && attachmentsError}<div>
        <em class="font-weight-light">{attachmentsError}</em>
      </div>{/if}
  </label>
  <Uploader bind:attachments bind:uploading={uploadingAttachments} />
</div>

<div class="text-right my-2">
  {#if submitting}
    <button disabled class="btn btn-primary submitting">Submitting...</button>
  {:else if validationOn && !canSubmit}
    <button disabled class="btn btn-primary">Check Form For Errors</button>
  {:else}
    <button class="btn btn-primary" on:click={submit}> Submit </button>
  {/if}
</div>
