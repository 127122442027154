<script>
 import { createEventDispatcher } from 'svelte'
 import { fly, fade } from 'svelte/transition'

 export let hideFooter = false
 export let showDialog = false
 export let title = ''
 export let fullWidth = false

 const dispatch = createEventDispatcher()
 let close = () => {
   showDialog = false
   dispatch('close')
 }
</script>

{#if showDialog}
  <div
    class="overlay"
    in:fade="{{ duration: 200 }}"
    out:fade="{{ delay: 200, duration: 200 }}"
    on:click={close}
  ></div>
  <div
    class="modal-container card text-left {fullWidth ? 'full-width' : 'normal-width'}"
    in:fly="{{
            y: -10,
            delay: 200,
            duration: 200
            }}"
    out:fly="{{
             y: -10,
             duration: 200
             }}"
  >
    <h5 class="card-header">
      {title}
      <button on:click={close} type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </h5>
    <div class="card-body">

      <slot>
        <div class="alert alert-danger">
          No Content Provided!
        </div>
      </slot>

    </div>

    {#if !hideFooter}
      <div class="card-footer">
        <slot name="footer"></slot>
      </div>
    {/if}

  </div>
{/if}

<style>
 .modal-container {
   position: fixed;
   z-index: 1999;
   top: 50%;
   transform: translate(0, -50%);
   background: #FFF;
   box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
   max-height: calc(100% - 40px);
 }

 .modal-container.full-width {
   left: 20px;
   right: 20px;
 }

 .modal-container.normal-width {
   left: 50%;
   transform: translate(-50%, -50%);
   max-width: 1000px;
   width: calc(100% - 40px);
 }

 .modal-container > .card-body {
   overflow-y: scroll;
 }

 .overlay {
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   position: fixed;
   user-select: none;
   z-index: 1998;
   background: hsla(0, 0%, 0%, 80%);
 }
</style>
