<script>
 const Moment = require('moment');

 import { onMount } from 'svelte'
 import Fa from 'svelte-fa'
 import { faEdit } from '@fortawesome/free-solid-svg-icons'

 import Datepicker from 'svelte-calendar';

 import { SFR3 } from '../../javascript/sfr3_api'
 import Permissions from '../../javascript/permissions'

 export let projectId
 export let dueDate
 export let dateFormat = 'ddd YYYY-MM-DD'

 const today = new Date()
 const end = Moment().add(6, 'months').toDate()

 let dateChosen = false
 let editing = false
 let contextClass = ''
 let CAN
 let newDueDate = dueDate ? Moment(dueDate).toDate() : today

 $: dueDateMoment = dueDate ? Moment(dueDate) : null
 $: daysLeft = dueDateMoment ? Moment.duration(dueDateMoment.diff(today)).asDays() : null
 $: {
   if (!daysLeft || daysLeft < 0) {
     contextClass = 'text-danger'
   } else if (daysLeft < 2) {
     contextClass = 'text-warning'
   } else {
     contextClass = ''
   }
 }

 const selectableCallback = (date) => date >= today

 async function updateCompletionDate () {
   const response = await SFR3.projects.salesRepairs.updateDueDate(
     projectId,
     Moment(newDueDate).format('YYYY-MM-DD')
   )

   if (response.ok) {
     dueDate = newDueDate
   } else {
     const data = await response.json()
     if (data.error) {
       alert(data.error)
     }
   }
   dateChosen = false
   editing = false
 }

 $: if (dateChosen) {
   updateCompletionDate()
 }

 onMount(async () => {
   CAN = await Permissions.forSalesRepairs(projectId)
 })
</script>

<style>
 :global(.project-completion-date .datepicker .contents-wrapper) {
   padding-top: 100px;
 }

 :global(.project-completion-date .datepicker .calendar-button) {
   width: 100%;
 }
</style>

<div class="project-completion-date {contextClass}">
  <h5 class="mb-0">DUE DATE</h5>
  {#if editing}
    <Datepicker
      bind:selected={newDueDate}
                    bind:dateChosen
      start={today}
                    end={end}
      {selectableCallback}
      style="display: block"
    />
  {:else}
    {dueDateMoment ? dueDateMoment.format(dateFormat) : 'TBD'}
    {#if CAN && CAN.update_due_date}
      <span on:click={() => editing = !editing}>
        <Fa icon={faEdit} style="cursor: pointer"/>
      </span>
    {/if}
  {/if}
</div>
