<script>
 export let lineItems = []

 $: doneItems = lineItems.filter((li) => li['done?'])
 $: pctDone = lineItems.length > 0 ? 100 * doneItems.length / lineItems.length : 100
</script>

<div class="progress my-2">
  <div class="progress-bar"
       style="width: {pctDone}%"
       role="progressbar"
       aria-valuenow={pctDone} aria-valuemax="100"
       aria-valuemin="0" />
</div>
