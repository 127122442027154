import { writable } from 'svelte/store';

const storedMarkets = localStorage.getItem('coStoredMarkets');
const storedProjectTypes = localStorage.getItem('coStoredProjectTypes')
export const markets = writable(JSON.parse(storedMarkets));
export const projectTypes = writable(JSON.parse(storedProjectTypes));

markets.subscribe(value => {
  localStorage.setItem("coStoredMarkets", JSON.stringify(value));
})

projectTypes.subscribe(value => {
  localStorage.setItem('coStoredProjectTypes', JSON.stringify(value));
})

const storedHiddenField = localStorage.getItem('coStoredHiddenField');
export const hiddenField = writable(storedHiddenField)

hiddenField.subscribe(value => {
  localStorage.setItem("coStoredHiddenField", value)
})
