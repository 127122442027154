<script>
  export let images;
  export let selectedUrl;
</script>

<div id="carouselExampleControls" class="carousel slide" data-ride="false">
  <ol class="carousel-indicators">
    {#each images as image, i}
      {#if image.url === selectedUrl}
        <li data-target="#carouselExampleControls" data-slide-to={i} class="active"></li>
      {:else}
        <li data-target="#carouselExampleControls" data-slide-to={i}></li>
      {/if}
    {/each}
  </ol>
  <div class="carousel-inner">
    {#each images as image}
      {#if image.url === selectedUrl}
        <div class="carousel-item active">
          <img class="d-block w-100" src={image.url} alt="Project">
          <div style="padding: 10px 0px 0px 28px;">{image.line_item_name}</div>
        </div>
      {:else}
        <div class="carousel-item">
          <img class="d-block w-100" src={image.url} alt="Project">
          <div style="padding: 10px 0px 0px 28px;">{image.line_item_name}</div>
        </div>
      {/if}
    {/each}
  </div>
  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>

