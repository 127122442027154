<script>
  import Fa from 'svelte-fa'
  import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
  import { SFR3 } from '../../javascript/sfr3_api'
  import Select from 'svelte-select';

  export let lineItem
  export let categoryOptions

  let value = lineItem.planner_category ? lineItem.planner_category : ''
  let loading = false
  let saved = undefined
  let selected = false

  $: planner_category = value.value || lineItem.planner_category

  const submit = async () => {
    loading = true

    const response = await SFR3.lineItems.editPlannerCategory(lineItem.id, planner_category)
    if (response.ok) {
      saved = true
    } else {
      saved = false
    }

    loading = false
  }

  const handleClear = () => {
    planner_category = lineItem.planner_category
  }

  const categorySelected = (event) => {
    planner_category = event.detail.value
    selected = true
  }
 </script>

 {#if !saved}
   <Select
     placeholder="Select a planner category..."
     items={categoryOptions}
     on:select={categorySelected}
     on:clear={handleClear}
   />
   <div class="col-md text-right">
    <br>
    <div class="btn btn-success ml-2" on:click={submit}>
      Confirm
    </div>
  </div>
 {:else}
   {#if saved}
     <span class="text-success"><Fa icon={faCheckSquare}/></span>
   {/if}
   {planner_category}
 {/if}

