<script>
  import { slide } from "svelte/transition";

  import ServiceSelect from "./service_select";
  import RoomSelect from "../line_items/room_select.svelte";
  import { formatCents } from "../../javascript/money";
  import Permissions from "../../javascript/permissions";
  import Select from "svelte-select";

  export let lineItem = {};
  export let market;
  export let saving;

  export let categoryOptions;
  export let roomOptions;

  const optionIdentifier = "id";
  const labelIdentifier = "name";

  let selectedService;
  let selectedPlannerService;
  let selectedRoom = lineItem.section;

  const initiallyOther = lineItem.id && !lineItem.service_id;
  let otherDescription = lineItem.vendor_instructions;

  let isPlannerCategory = lineItem.planner_category ? true : false;
  let quantity = lineItem.quantity;
  let totalPrice = (lineItem.cost_cents || 0) / 100;
  let coveredUnderWarranty = !!lineItem.covered_under_warranty;

  if (isPlannerCategory) {
    selectedPlannerService = { id: lineItem.service_id, name: lineItem.name };
    selectedService = { id: "other", label: "Other Custom Line Item" };
  }

  $: isOther = selectedService && selectedService.id === "other";
  $: {
    if (coveredUnderWarranty) {
      totalPrice = 0;
    } else if (!isOther) {
      if (selectedService && selectedService.price_cents && quantity) {
        totalPrice = (quantity * selectedService.price_cents) / 100;
      }
    }
  }
  $: quantity = isOther ? 1 : quantity;
  $: lineItem = {
    ...lineItem,
    // service_id may start as null if it is a new line_item
    service_id:
      selectedService && selectedService.id != "other"
        ? selectedService.id
        : selectedPlannerService
        ? selectedPlannerService.id
        : lineItem.service_id,
    service:
      selectedService && selectedService.id === "other"
        ? selectedPlannerService
        : selectedService,
    quantity: quantity || 0,
    cost_cents: totalPrice * 100,
    name: isPlannerCategory
      ? otherDescription
      : selectedService
      ? selectedService.name
      : "",
    covered_under_warranty: coveredUnderWarranty,
    priceValid: priceValid, // Not used by Ruby so not in snake_case
    category: isPlannerCategory ? "Other" : null,
    planner_category: selectedPlannerService
      ? selectedPlannerService.name
      : null,
    vendor_instructions: otherDescription,
    section: selectedRoom ? selectedRoom.name : lineItem.section,
  };

  $: priceDisabled = !isOther || coveredUnderWarranty;
  $: priceValid = coveredUnderWarranty ? totalPrice >= 0 : totalPrice > 0;
</script>

<div class="form-row my-2">
  <div class="col-2">
    <input
      type="number"
      step="1"
      bind:value={quantity}
      placeholder="Quantity..."
      class="form-control {quantity < 0 ||
      (quantity && quantity != Math.floor(quantity))
        ? 'is-invalid'
        : ''}"
      min="0"
      disabled={isPlannerCategory}
    />
  </div>
  <div class="col">
    <ServiceSelect
      bind:selectedService
      bind:isPlannerCategory
      serviceId={lineItem.service_id}
      {market}
      includeOther={true}
    />
  </div>

  <div class="col-2">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">$</span>
      </div>
      <input
        type="number"
        step="0.01"
        class="form-control text-right {isOther && totalPrice < 0
          ? 'is-invalid'
          : ''}"
        aria-label="Amount"
        disabled={priceDisabled}
        bind:value={totalPrice}
      />
    </div>
  </div>
</div>

{#if isPlannerCategory}
  <div class="form-row my-2 " transition:slide>
    <div class="col">
      <Select
        {optionIdentifier}
        {labelIdentifier}
        placeholder="Select a service category..."
        items={categoryOptions}
        bind:value={selectedPlannerService}
      />
      <br />
      {#if saving && !lineItem.id}
        <textarea
          class="form-control"
          readonly={true}
          placeholder="Setting up a description for the Line Item. Please wait..."
        />
      {:else}
        <textarea
          class="form-control"
          bind:value={otherDescription}
          placeholder="Describe the work to be done"
        />
      {/if}
    </div>
    <div class="col-2" />
  </div>
{/if}

<div class="form-row my-2 " transition:slide>
  <div class="col">
    <RoomSelect bind:selectedRoom rooms={roomOptions} />
  </div>
</div>

{#if Permissions.can("CREATE_UNDER_WARRANTY_LIS")}
  <div class="form-row">
    <div class="col">
      <input type="checkbox" bind:checked={coveredUnderWarranty} />
      <em>Covered under warranty?</em>
    </div>
  </div>
{/if}
