<script>
 import { onMount } from 'svelte'

 import { SFR3 } from '../../javascript/sfr3_api'

 import ShowComment from './show'

 export let commentableId
 export let commentableType

 let comments = []

 const addComment = (event) => {
   if (event && event.detail && event.detail.comment) {
     comments = [...comments, event.detail.comment]
   }
 }

 onMount(async () => {
   const response = await SFR3.comments.for(commentableId, commentableType)
   if (response.ok) {
     const data = await response.json()
     comments = data.comments
   } else {
     alert((await response.json()).error)
   }
 })
</script>

<div class="border rounded">
  {#each comments as comment (comment.id)}
    <ShowComment {comment} />
  {/each}
</div>
