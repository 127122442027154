<script>
 import { SFR3 } from '../../javascript/sfr3_api'

 import GCSelect from '../vendors/gc_select'

 export let gc
 export let project // {:id, :market_code, :status}
 export let canEdit = false

 let selectingGC = !gc && canEdit
 let newGC = gc

 $: mustSetInitialGC = !gc && canEdit

 const saveGC = async () => {
   if (!newGC || !newGC.id) {
     alert('Error: You cannot save a blank GC')
     return
   }

   if (gc && gc.id == newGC.id) {
     selectingGC = false
     return // Nothing changed
   }

   const response = await SFR3.projects.update(project.id, { gc_id: newGC.id })
   if (response.ok) {
     gc = newGC
     project.gc_id = gc.id
   } else {
     try {
       const data = await response.json()
       alert(data.error)
     } catch {
       alert('Unknown error when saving GC')
     }
     return
   }

   selectingGC = false
 }
</script>

<div class="mt-3 mb-2" class:alert={mustSetInitialGC} class:alert-warning={mustSetInitialGC}>
  {#if mustSetInitialGC}
    <h5 class="alert-heading">Assign a GC before beginning work on this property</h5>
  {/if}

  {#if selectingGC}
    <GCSelect bind:gc={newGC} {project} />
    {#if newGC}
      <button class="btn btn-primary mt-3" on:click={saveGC}>Save GC</button>
    {:else}
      <button disabled class="btn btn-primary mt-3">Save GC</button>
    {/if}
  {:else}
    <div class="row row-cols-1 row-cols-sm-2">
      <div class="col-sm-2 font-weight-bold">GC</div>
      <div class="col-4">
        {gc ? gc.name : 'TBD'}

        {#if canEdit}
          <button class="btn btn-outline-dark btn-sm" on:click={() => selectingGC = true}>Edit GC</button>
        {/if}
      </div>
    </div>
  {/if}
</div>
