<script>
  import { now } from "moment";

  import { onMount } from "svelte";
  import { Stretch } from "svelte-loading-spinners";
  import { element } from "svelte/internal";
  import { slide } from "svelte/transition";

  import { formatCents, formatCentsRound } from "../../javascript/money";

  import { SFR3 } from "../../javascript/sfr3_api";

  export let rmName;
  export let rmProfileId;
  export let open = false;
  export let open_active_renos = true;
  export let open_not_started_renos = true;
  export let marketIds = [];

  let loading = false;
  let loaded = false;

  let notStartedRenosLoaded = false;
  let notStartedRenosLoading = false;

  let active = {};
  let not_started = {};
  let hideableMarkets = [];

  $: activeRenos = active.activeRenos || [];
  $: notStartedRenos = not_started.notStartedRenos || [];
  $: showableMarketIds = hideableMarkets
    .filter((element) => element.show)
    .map((element) => element.market_id);
  $: showableNotStartedRenos = notStartedRenos.filter((reno) =>
    showableMarketIds.includes(reno.market_id.toString())
  );

  const getHideableMarkets = async () => {
    const response = await SFR3.rmProfiles.hideScopedAndReady(marketIds);
    hideableMarkets = await response.json();
  };

  const toggleDetails = async () => {
    open = !open;
  };

  const toggleDetailsActiveRenos = async () => {
    open_active_renos = !open_active_renos;
    return loadActiveRenos();
  };

  const toggleDetailsNotStartedRenos = async () => {
    open_not_started_renos = !open_not_started_renos;
    return loadNotStartedRenos();
  };

  const load = async () => {
    loadActiveRenos();
    loadNotStartedRenos();
    getHideableMarkets();
  };

  const loadActiveRenos = async () => {
    // If already called, don't call again
    if (loaded || loading) {
      return;
    }

    loading = true;

    const response = await SFR3.rmProfiles.activeRenos(rmProfileId);
    if (response.ok) {
      active = await response.json();
    } else {
      alert("Error Loading PM Details");
    }

    loaded = true;
    loading = false;
  };

  const loadNotStartedRenos = async () => {
    // If already called, don't call again
    if (notStartedRenosLoaded || notStartedRenosLoading) {
      return;
    }

    notStartedRenosLoading = true;

    const response = await SFR3.rmProfiles.notStartedRenos(rmProfileId);
    if (response.ok) {
      not_started = await response.json();
    } else {
      alert("Error Loading PM Details");
    }

    notStartedRenosLoaded = true;
    notStartedRenosLoading = false;
  };

  async function startReno(project_id) {
    try {
      const response = await SFR3.rmProfiles.startReno(rmProfileId, project_id);
      if (response.ok) {
        const parsedResponse = await response.json();
        if (parsedResponse.success) {
          notStartedRenosLoaded = false;
          loaded = false;
          loadNotStartedRenos();
          loadActiveRenos();
        } else {
          alert(parsedResponse.message);
        }
      } else {
        try {
          const parsedResponse = await response.json();
          alert(parsedResponse.error);
        } catch {
          alert("Unknown error when submitting - contact Tech Support");
        }
      }
    } catch (e) {
      alert(e.message);
    }
  }

  const nextMilestoneEtaStyle = (reno) => {
    var today = new Date(Date.now());
    var nexMilestoneDate = new Date(
      `${reno.closest_milestone_date} 00:00`.replace(/-/g, "/")
    );

    if (today > nexMilestoneDate) {
      return "overdue";
    }
  };

  const getFormattedDate = (date) => {
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "/" + day + "/" + year;
  };

  onMount(async () => {
    load();
  });
</script>

<div class="shadow-sm p-3 bg-white mt-4">
  <h2 on:click={toggleDetails}>
    {rmName}
    {#if open}&#x25BC;{:else}&#x25B6;{/if}
  </h2>

  {#if open}
    <h4 on:click={toggleDetailsActiveRenos}>
      Active Projects {#if open_active_renos}&#x25BC;{:else}&#x25B6;{/if}
    </h4>
    {#if open_active_renos}
      {#if loaded}
        <div
          class="container-lg border-bottom border-right border-left bg-light"
          transition:slide
        >
          {#each activeRenos as reno}
            <div class="row py-3 border-top">
              <div class="col-sm-4 mb-2 mb-sm-0">
                <h4 class="mb-1">{reno.address}, {reno.market_code}</h4>
                <a href={reno.url} class="btn btn-primary btn-sm">Manage</a>
              </div>
              <div class="col-sm">
                <div>
                  {#if reno.deadline}
                    <div class="to-the-right">
                      {`Project Deadline: ${getFormattedDate(
                        new Date(`${reno.deadline} 00:00`.replace(/-/g, "/"))
                      )}`}
                    </div>
                  {/if}
                  <div class="progress">
                    <div
                      class="progress-bar"
                      style="width: {(100 * reno.doneCents) / reno.totalCents}%"
                      role="progressbar"
                      aria-valuenow={(100 * reno.doneCents) / reno.totalCents}
                      aria-valuemax="100"
                      aria-valuemin="0"
                    />
                  </div>
                  <div class="row">
                    <div class="col-sm">
                      {formatCents(reno.doneCents)} / {formatCents(
                        reno.totalCents
                      )}
                      {#if reno.cancelledCents > 0}
                        ({formatCents(reno.cancelledCents)} cancelled, not included
                        in totals)
                      {/if}
                    </div>
                    {#if reno.dollar_per_day}
                      <div class="col-sm to-the-right">
                        Avg. Pacing: {formatCentsRound(reno.dollar_per_day)}/day
                      </div>
                    {/if}
                  </div>
                </div>
                <div>
                  {#if reno.unassignedCount > 0}
                    <div
                      class="badge badge-warning badge-pill align-text-top mr-1"
                    >
                      {reno.unassignedCount} Unassigned
                    </div>
                  {/if}
                  {#if reno.assignedCount > 0}
                    <div
                      class="badge badge-primary badge-pill align-text-top mr-1"
                    >
                      {reno.assignedCount} In-Progress
                    </div>
                  {/if}
                  {#if reno.completedCount > 0}
                    <div
                      class="badge badge-info badge-pill align-text-top mr-1"
                    >
                      {reno.completedCount} Completed
                    </div>
                  {/if}
                  {#if reno.approvedCount > 0}
                    <div
                      class="badge badge-success badge-pill align-text-top mr-1"
                    >
                      {reno.approvedCount} Approved
                    </div>
                  {/if}
                  {#if reno.cancellationRequestedCount > 0}
                    <div
                      class="badge badge-secondary badge-pill align-text-top mr-1"
                    >
                      {reno.approvedCount} Approved
                    </div>
                  {/if}
                  {#if reno.cancelledCount > 0}
                    <div
                      class="badge badge-dark badge-pill align-text-top mr-1"
                    >
                      {reno.cancelledCount} Cancelled
                    </div>
                  {/if}
                </div>
              </div>
            </div>
          {:else}
            <div class="py-3 border-top" transition:slide>
              <h3 class="mb-0">No Active Renos</h3>
            </div>
          {/each}
        </div>
      {:else}
        <div class="text-center" transition:slide>
          <Stretch color="#999999" />
        </div>
      {/if}
    {/if}
    {#if hideableMarkets.some((element) => element.show)}
      <h4 class="mt-1" on:click={toggleDetailsNotStartedRenos}>
        Scoped and Ready Projects {#if open_not_started_renos}&#x25BC;{:else}&#x25B6;{/if}
      </h4>
      {#if open_not_started_renos}
        {#if notStartedRenosLoaded}
          <div
            class="container-lg border-bottom border-right border-left bg-light"
            transition:slide
          >
            {#each showableNotStartedRenos as reno}
              <div class="row py-3 border-top">
                <div class="col-sm-4 mb-2 mb-sm-0">
                  <h4 class="mb-1">{reno.address}, {reno.market_code}</h4>
                  {#if reno.showStartProjectBtn}
                    <button
                      class="btn btn-success btn-sm"
                      on:click={() => startReno(reno.id)}
                    >
                      Start Project
                    </button>
                  {/if}
                  <a href={reno.url} class="btn btn-primary btn-sm">Manage</a>
                </div>
                <div class="col-sm">
                  <div>
                    <div class="progress">
                      <div
                        class="progress-bar"
                        style="width: {(100 * reno.doneCents) /
                          reno.totalCents}%"
                        role="progressbar"
                        aria-valuenow={(100 * reno.doneCents) / reno.totalCents}
                        aria-valuemax="100"
                        aria-valuemin="0"
                      />
                    </div>
                    {formatCents(reno.doneCents)} / {formatCents(
                      reno.totalCents
                    )}
                  </div>
                  <div>
                    {#if reno.unassignedCount > 0}
                      <div
                        class="badge badge-warning badge-pill align-text-top mr-1"
                      >
                        {reno.unassignedCount} Unassigned
                      </div>
                    {/if}
                    {#if reno.assignedCount > 0}
                      <div
                        class="badge badge-primary badge-pill align-text-top mr-1"
                      >
                        {reno.assignedCount} In-Progress
                      </div>
                    {/if}
                    {#if reno.completedCount > 0}
                      <div
                        class="badge badge-info badge-pill align-text-top mr-1"
                      >
                        {reno.completedCount} Completed
                      </div>
                    {/if}
                    {#if reno.approvedCount > 0}
                      <div
                        class="badge badge-success badge-pill align-text-top mr-1"
                      >
                        {reno.approvedCount} Approved
                      </div>
                    {/if}
                    {#if reno.cancellationRequestedCount > 0}
                      <div
                        class="badge badge-secondary badge-pill align-text-top mr-1"
                      >
                        {reno.approvedCount} Approved
                      </div>
                    {/if}
                    {#if reno.cancelledCount > 0}
                      <div
                        class="badge badge-dark badge-pill align-text-top mr-1"
                      >
                        {reno.cancelledCount} Cancelled
                      </div>
                    {/if}
                  </div>
                </div>
              </div>
            {:else}
              <div class="py-3 border-top" transition:slide>
                <h3 class="mb-0">No Projects</h3>
              </div>
            {/each}
          </div>
        {:else}
          <div class="text-center" transition:slide>
            <Stretch color="#999999" />
          </div>
        {/if}
      {/if}
    {/if}
  {/if}
</div>

<style>
  h2 {
    cursor: pointer;
  }
  .to-the-right {
    text-align: right;
  }
</style>
