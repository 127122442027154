<script>
 import Paginator from 'svelte-paginator'

 import { SFR3 } from '../../javascript/sfr3_api'

 let total = null
 let address = ''
 let reset = () => {}

 const loadRenos = async (page, perPage) => {
   total = null
   const response = await SFR3.projects.list('active', page, perPage, { address })
   const responseObj = await response.json()
   total = responseObj.numItems
   return responseObj
 }

 let activeTimeout
 const filteredReset = () => {
   if (activeTimeout) {
     clearTimeout(activeTimeout)
   }
   activeTimeout = setTimeout(reset, 500)
 }
</script>

<div class="row">
  <div class="col-sm">
    <h1>{total === null ? '...' : total} Active Project{ total === 1 ? '' : 's' }</h1>
  </div>
  <div class="col-sm text-right">
    <input bind:value={address} on:input={filteredReset} class="form-control" placeholder="Address..."/>
  </div>
</div>

<Paginator loadItems={loadRenos} let:items bind:reset>
  <table class="table table-borderless table-striped">
    <thead>
      <tr>
        <th>Address</th>
        <th>Market</th>
        <th>PM</th>
        <th>RD</th>
        <th>Line Items (approved/total)</th>
      </tr>
    </thead>
    <tbody>
      {#if total === null}
        <tr><td colspan="5" class="text-center">Loading...</td></tr>
      {:else}
        {#each items as reno}
          <tr>
            <td><a href="{reno.url}">{reno.address}</a></td>
            <td>{reno.market}</td>
            <td>{reno.rm.name}</td>
            <td>{reno.md.name}</td>
            <td>{reno.line_item_approved_count}/{reno.line_item_count - reno.line_item_cancelled_count}</td>
          </tr>
        {:else}
          <tr>
            <td colspan="5" class="text-center">
              No matching projects
            </td>
          </tr>
        {/each}
      {/if}
    </tbody>
  </table>
</Paginator>
