<script>
  import { markets, hiddenField } from '../../javascript/pc_store.js';
  import Paginator from "svelte-paginator";
  import ProcessingRow from "../lib/aasm_processing_row";
  import { formatCents } from "../../javascript/money";
  import { SFR3 } from "../../javascript/sfr3_api";
  import { onMount } from "svelte";
  import { tweened } from "svelte/motion";
  import { bounceOut } from "svelte/easing";
  import CategorySelector from "./category_selector";
  import FilterByMarket from "../lib/filter_by_market";

  let numItems = null;
  let projectId = "";
  let reset = () => {};
  let categoryOptions;
  let forceShowProjectFilter = false;
  let arrowProjectRotation = tweened(0, { duration: 700, easing: bounceOut });  
  let hiddenProjectFilter;
  let activeTimeout;
  let hiddenMarketFilterValue;
  let marketStoreValue;
  
  hiddenProjectFilter = forceShowProjectFilter ? false : true;

  markets.subscribe(value => {
    marketStoreValue = value
  })

  hiddenField.subscribe(value => {
    hiddenMarketFilterValue = value
  })

  let marketIds = marketStoreValue || []
  let hiddenMarketFilter = hiddenMarketFilterValue === 'true';

  const toggleProjectDisplay = () => {
    hiddenProjectFilter = !hiddenProjectFilter;
    $arrowProjectRotation = $arrowProjectRotation > 0 ? 0 : 90;
  }

  const loadItems = async (page, perPage) => {
    numItems = null;
    const response = await SFR3.lineItems.uncategorizedLineItems(
      page,
      perPage,
      {
        project_id: projectId,
        market_ids: (marketIds || [])
      }
    );
    const responseObj = await response.json();
    numItems = responseObj.numItems;
    return responseObj;
  };

  const filteredReset = () => {
    if (activeTimeout) {
      clearTimeout(activeTimeout);
    }
    activeTimeout = setTimeout(reset, 750);
  };

  const loadCategoryOptions = async () => {
    const response = await SFR3.services.categoryOptions();
    categoryOptions = await response.json();
  };
  onMount(async () => loadCategoryOptions());

  const hideProjectDisplay = () => {
    hiddenProjectFilter = true;
    $arrowProjectRotation = 0;
  };

  const updateStore = () => {
    markets.set(marketIds)
  }

  const updateFilter = (event) => {
    hiddenField.set(event.detail)
  }
</script>

<h1>
  Line Item{numItems === 1 ? "" : "s"}
  &ndash;
  {#if numItems !== null}
    {numItems}
  {:else}
    &hellip;
  {/if}
</h1>

<div
  class="col-sm text-left"
  style="cursor: pointer"
  on:click={toggleProjectDisplay}
>
  <h5>
    Search by Project ID <span
      style="display: inline-block; transform: rotate({$arrowProjectRotation}deg);"
      >&#x25B6;</span
    >
  </h5>
</div>

{#if !hiddenProjectFilter}
  <input
    bind:value={projectId}
    on:input={filteredReset}
    class="form-control"
    placeholder="Project ID..."
  />
{/if}

<FilterByMarket on:marketsUpdated={updateStore} 
                on:toggleHiddenFilter={updateFilter} 
                bind:hiddenMarketFilter 
                bind:marketIds 
                bind:projectId {filteredReset} />

{#if projectId != ''}
  <p class="col-sm text-left">Filtering by Project...</p>
{:else}
  {#if marketIds.length > 0}
    <p class="col-sm text-left">Filtering by Markets...</p>
  {/if}
{/if}

<Paginator
  {loadItems}
  perPage={50}
  let:items
  bind:reset
  class_button="btn btn-outline-secondary"
  class_current_page="btn btn-secondary"
  class_button_group="btn-group"
>
  <div class="my-3">
    {#if items === null}
      Loading...
    {:else}
      <ul class="list-group">
        {#if items.length === 0}
          <li class="list-group-item">No uncategorized line items found.</li>
        {/if}
        {#each items as lineItem}
          <ProcessingRow aasm_object={lineItem} aasm_api={SFR3.lineItems}>
            <div slot="header">
              <h5 class="mb-0 font-weight-light">
                <span class="font-weight-bold">
                  {formatCents(lineItem.cost_cents)} - {lineItem.status} - {lineItem.name}
                </span>
                for {lineItem.project.address}
                <span class="badge badge-primary ml-2"
                  >{lineItem.project.market_name}</span
                >
                <span />
              </h5>
            </div>

            <div slot="details">
              <div class="container mb-3">
                <h5>Select Planner Category</h5>
                <CategorySelector {lineItem} {categoryOptions} />
              </div>
            </div>
          </ProcessingRow>
        {/each}
      </ul>
    {/if}
  </div>
</Paginator>
