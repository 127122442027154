<script>
  import { Pulse } from "svelte-loading-spinners";
  import { slide } from "svelte/transition";
  import Fa from "svelte-fa";
  import { faTimes } from "@fortawesome/free-solid-svg-icons";
  import { onMount } from "svelte";

  import { SFR3 } from "../../javascript/sfr3_api";
  import { formatCents } from "../../javascript/money";

  import LineItemForm from "./line_item_form";

  export let scope;
  export let lineItems = [];
  export let market;
  export let editing = false;

  let categoryOptions;
  let roomOptions;
  let debounceTimeout;

  // When initializing, set a key on each line item
  // (resetting if it is loaded by line items that already have keys; doesn't matter)
  let latestKey = 0;
  lineItems.map((li) => {
    latestKey = latestKey + 1;
    li.key = latestKey;
  });

  function generateTempId() {
    return `temp_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
  }

  function addLineItem() {
    latestKey = latestKey + 1;
    lineItems = [...lineItems, { key: latestKey, tempId: generateTempId() }];
  }

  function removeLineItem(lineItem) {
    if (saving) {
      return;
    }

    return () => {
      lineItems = lineItems.filter(({ key }) => key !== lineItem.key);
    };
  }

  let saving = false;
  const saveLineItems = async (lineItemsToSave) => {
    if (!editing) {
      return;
    }
    if (saving) {
      return;
    }
    saving = true;
    await new Promise((resolve) => setTimeout(resolve, 2000));
    try {
      const response = await SFR3.simpleScopes.editLineItems(
        scope.id,
        lineItemsToSave
      );
      const data = await response.json();

      if (!response.ok) {
        alert(data.error);
      }
    } catch (e) {
      console.error(e);
      alert(
        "Unknown error when attempting to save line items; please reload the page"
      );
    }
    saving = false;
  };

  let strCache;
  $: validLineItems = editing
    ? lineItems.filter((li) => li.priceValid)
    : lineItems;
  $: invalidLineItems = lineItems.filter(
    (li) => !li.name || li.name === 0 || li.quantity == 0
  );
  $: if (strCache != JSON.stringify(validLineItems)) {
    strCache = JSON.stringify(validLineItems);
    if (invalidLineItems.length === 0) {
      const filteredLineItems = lineItems.filter(
        (li) => li.name && li.name !== 0 && li.quantity != 0
      );
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(
        () => saveLineItems(filteredLineItems),
        1000
      );
    }
  }

  const loadCategoryOptions = async () => {
    try {
      const response = await SFR3.services.scopingCategoryOptions();
      categoryOptions = await response.json();
    } catch (error) {
      console.error("Error loading category options:", error);
    }
  };

  const loadRoomOptions = async () => {
    try {
      const response = await SFR3.lineItems.roomOptions();
      roomOptions = await response.json();
    } catch (error) {
      console.error("Error loading room options:", error);
    }
  };

  onMount(async () => {
    await Promise.all([loadCategoryOptions(), loadRoomOptions()]);
  });
</script>

<div class="row my-2">
  <div class="col-6">
    <h2>
      Line Items
      {#if saving}
        <span style="display: inline-block; vertical-align: middle">
          <Pulse color="#007bff" size="45" />
        </span>
      {/if}
    </h2>
  </div>

  {#if editing}
    <div class="col text-right">
      <button class="btn btn-outline-primary" on:click={addLineItem}
        >+ Add Line Item</button
      >
    </div>
  {/if}
</div>

{#if editing}
  {#each lineItems as lineItem (lineItem.key)}
    <div class="row" transition:slide>
      <div
        class="action-container text-center d-flex flex-column justify-content-center"
      >
        <span
          class="{saving ? 'text-secondary' : 'text-danger'} action"
          on:click={removeLineItem(lineItem)}
        >
          <Fa icon={faTimes} />
        </span>
      </div>
      <div class="col">
        <LineItemForm
          bind:lineItem
          {market}
          {categoryOptions}
          {roomOptions}
          {saving}
        />
      </div>
    </div>
  {/each}
{:else}
  <table class="table mt-3 mb-0 table-striped table-sm border">
    <tbody>
      {#each lineItems as lineItem (lineItem.key)}
        <tr>
          <td class="text-right">{lineItem.quantity}</td>
          <td>{lineItem.name || "TBD"}</td>
          <td class="text-right">{formatCents(lineItem.cost_cents)}</td>
        </tr>
      {:else}
        <tr>
          <td class="text-center">No Line Items</td>
        </tr>
      {/each}
    </tbody>
  </table>
{/if}

<style>
  .action {
    cursor: pointer;
  }
</style>
