<script>
 import { setContext } from 'svelte'
 import { slide } from 'svelte/transition'

 import Fa from 'svelte-fa'
 import { faEdit } from '@fortawesome/free-solid-svg-icons'

 import { SFR3 } from '../../javascript/sfr3_api'

 import strings from './string'
 import selects from './select'
 import integers from './integer'

 export let property
 export let field

 let editing = false

 setContext('property', property)
 setContext('field', field)

 let originalValue = property[field.botfield]
 let value = originalValue

 const components = {
   integer: integers,
   string: strings,
   select: selects
 }

 const editable = field.editable
 const datatype = field.datatype || 'string'
 const ShowComponent = components[datatype].Show
 const EditComponent = components[datatype].Edit

 const toggle = () => {
   editing = !editing
 }

 $: if (value != originalValue) { submit() }

 const submit = async () => {
   if (!editable) { editing = false; return }
   if (value == originalValue) { editing = false; return }

   //loading = true

   const propertyAttributes = { };
   propertyAttributes[field.botfield] = value
   const response = await SFR3.properties.update(
     property.id, propertyAttributes
   )

   if (response.ok) {
     editing = false
     originalValue = value
   } else {
     try {
       const data = await response.json()
       if (data.error) {
         alert(data.error)
       }
     } catch {
       alert('Error Saving')
     }
   }
 }
</script>

<div class="col mb-2">
  <div class="row">
    <div class="col-4">
      <strong class:text-danger={!value}>{ field.name }</strong>
    </div>
    <div class="col">
      {#if editing}
        <div transition:slide>
          <svelte:component this={EditComponent} bind:value {field} bind:editing/>
        </div>
      {:else}
        <div transition:slide>
          {#if editable}
            <span class="btn btn-sm btn-outline-dark mr-1" on:click={toggle}>
              <Fa icon={faEdit} style="cursor: pointer"/>
            </span>
          {/if}
          {#if value}
            <svelte:component this={ShowComponent} bind:value />
          {:else}
            <strong>NOT SET</strong>
          {/if}
        </div>
      {/if}
    </div>
  </div>
</div>
