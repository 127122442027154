<script>
 import { slide } from 'svelte/transition'
 import { onMount } from 'svelte'

 import AttachmentSection from '../lib/attachment_section'
 import LineItemTable from '../line_items/table'
 import NewCheckInButton from '../check_ins/new_modal_button'
 import { SFR3 } from '../../javascript/sfr3_api'

 export let project
 export let numCheckIns = 0
 export let assignedLineItems = []

 let checkIns = []
 let show = false
 let marketId = project.market_id
 let enabled = true

 $: loaded = checkIns.length === numCheckIns

 async function loadCheckIns () {
   let response = await SFR3.projects.checkIns(project.id)
   if (response.ok) {
     checkIns = (await response.json()).items
   } else {
     alert(`Error loading Check-Ins for project ${project.id}. Contact Tech Support`)
   }
 }

 async function toggle () {
   if (!loaded) {
     await loadCheckIns()
   }

   show = !show
 }

 const checkInsEnabled = async () => {
   const data = await SFR3.checkIns.enabled( marketId )
   enabled = (await data.json())['check_ins_enabled']
 }

 function handleNewCheckIn (event) {
   const newCheckIn = event.detail.checkIn
   checkIns = [newCheckIn].concat(checkIns)

   numCheckIns = numCheckIns + 1
 }

 onMount(async () => checkInsEnabled())
</script>

<style>
 h2 {
   cursor: pointer;
 }

 img {
   max-width: 100%;
 }
</style>

<div class="my-4">
  <div class="row">
    <div class="col">
      <h2 on:click={toggle}>
        Check Ins
        {#if numCheckIns > 0}
          ({numCheckIns})
          {#if show}&#x25BC;{:else}&#x25B6;{/if}
        {:else}
          (none yet)
        {/if}
      </h2>
    </div>
    <div class="col-sm-5 text-sm-right">
      <NewCheckInButton
        {project}
        {assignedLineItems}
        {enabled}
        on:created={handleNewCheckIn}/>
    </div>
  </div>

  {#if show}
    {#each checkIns as checkIn, i (checkIn.id)}
      <div class="card my-3" transition:slide>
        <div class="card-body">
          <h5 class="card-title">
            {checkIn.date}
            <small class="text-muted">
              {checkIn.creator.name} - {checkIn.creator.roles}
            </small>
          </h5>
          <p class="card-text font-italic">{checkIn.note}</p>

          <AttachmentSection files={checkIn.attachments} canUpload={false} titleClass="h5" />
        </div>
        {#if checkIn.work_approval}
          <LineItemTable lineItems={checkIn.work_approval.line_items}/>
        {/if}
      </div>
  {:else}
      <div class="text-center font-italic">
        No check-ins yet
      </div>
    {/each}
  {/if}
</div>
