<script>
 import { createEventDispatcher, onMount } from 'svelte'
 import { slide } from 'svelte/transition'
 import { Stretch } from 'svelte-loading-spinners'
 import { formatCents } from '../../javascript/money'
 import { SFR3 } from '../../javascript/sfr3_api'
 import LineItemForm from './update_line_item_form'
 import CancelLineItemsForm from './cancel_line_items_form'

 export let changeOrder
 export let project

 let cancellableLineItems = []
 let changeOrderItems = []
 let lineItems = []
 let formError = null
 let cancellationRequestedItems = []
 let lineItemsToCancel = changeOrder.lineItemsToCancel || [];
 let loadedCancellableLineItems = false
 let loadedLineItems = false
 let description = changeOrder.description || 'Please, add a description'
 let submitBlocker = 'Add at least one line item'
 let submitting = false
 let latestKey = 0
 let categoryOptions
 let roomOptions
 let update = true

 const dispatch = createEventDispatcher()

 $: totalCents = (lineItems.reduce((acc, li) => acc + (li.cost_cents ? li.cost_cents : 0), 0)
                  - lineItemsToCancel.reduce((acc, li) => acc + (li.cost_cents || 0), 0))
 $: {
   if (lineItems.length == 0 && lineItemsToCancel.length == 0) {
     submitBlocker = 'Please, add at least one line item'
   } else if (totalCents == 0) {
     submitBlocker = 'Please, complete all the line items'
   } else if (lineItems.filter((li) => li.quantity < 1).length > 0) {
     submitBlocker = 'Please, enter a quantity to all line items'
   } else if (lineItems.filter((li) => li.quantity != Math.floor(li.quantity)).length > 0) {
     submitBlocker = 'Quantities must be whole numbers'
   } else if (lineItems.filter((li) => li.name == "").length > 0) {
     submitBlocker = 'Please, complete all the line items. '
   } else if (lineItems.filter((li) => li.isOther && !li.planner_category).length > 0) {
     submitBlocker = 'Select a Service category for every custom line item'
   } else if (lineItems.filter((li) => li.isOther && !li.name).length > 0) {
     submitBlocker = 'Please, write a description for every custom line item'
   } else if (lineItems.filter((li) => li.isOther && li.name && li.cost_cents == 0 & !li.priceValid).length > 0) {
     submitBlocker = 'Please, enter a cost for every custom line item'
   } else if (lineItems.filter((li) => li.attachments.length == 0).length > 0) {
     submitBlocker = 'Upload at least one photo for every new line item'
   } else if (description.length < 1) {
     submitBlocker = 'Please, write a note to explain this change order'
   } else {
     submitBlocker = null
   }
 }

 function addLineItem () {
   latestKey = latestKey + 1
   lineItems = [ ...lineItems, { key: latestKey } ];
 }

 function deleteLineItem (lineItem) {
   const idx = lineItems.findIndex(item => item.id == lineItem.id);
   lineItems.splice(idx, 1);
   lineItems = [ ...lineItems ];
 }

 async function submit () {
   if (submitBlocker) {
     alert(`You cannot submit yet: ${submitBlocker}`)
   }

   submitting = true

   SFR3.changeOrders.update(
     project.id,
     changeOrder.id,
     lineItems.map((li) => {
       return {
         id: li.id,
         service_id: li.service_id,
         quantity: li.quantity,
         is_other: li.isOther,
         name: li.name,
         cost_cents: li.cost_cents,
         covered_under_warranty: li.covered_under_warranty,
         planner_category: li.planner_category,
         attachments: li.attachments,
         section: li.section,
         vendor_instructions: li.vendor_instructions
       }
     }),
     lineItemsToCancel.map(li => li.id),
     description
   ).then(async (response) => {
     const data = await response.json()

     formError = data.error

     if (data.success) {
       dispatch('updated', { changeOrder: data.change_order })
     }
   }).catch((e) => {
     submitting = false
     alert('Unknown Error')
     formError = 'Unknown Error'
   })
 }

 const loadCancellableLineItems = async () => {
   const response = await SFR3.projects.cancellableLineItems(project.id)
   cancellableLineItems = (await response.json()).items || []
   loadedCancellableLineItems = true
 }

 const loadChangeOrderItems = async () => {
   const response = await SFR3.changeOrders.list_line_items(changeOrder.id)
   changeOrderItems = (await response.json())
   lineItems = changeOrderItems.line_items || []
   // add index to lineItems
   lineItems.forEach((li, idx) => {
     li.key = idx
   })
   latestKey = lineItems.length
   cancellationRequestedItems = changeOrderItems.line_items_to_cancel || []
   loadedLineItems = true
 }

 const loadCategoryOptions = async () => {
     const response = await SFR3.services.scopingCategoryOptions()
     categoryOptions = await response.json()
 }

 const loadRoomOptions = async () => {
   const response = await SFR3.lineItems.roomOptions()
   roomOptions = await response.json()
 }

 onMount(async () => {
   loadChangeOrderItems();
   loadCategoryOptions();
   loadCancellableLineItems();
   loadRoomOptions();
  })

</script>

<style>
 .action-container {
   flex: 0 0 4%;
   max-width: 4%;
   position: relative;
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
 }

 .action {
   cursor: pointer;
 }

 .border-li {
    border-style: solid;
    border-width: 0 0 3px 0;
    border-color: #e6e6e6;
    padding: 5px 0 10px 0;
    margin: 30px 0 10px 0;
 }
</style>

{#if formError}
  <div class="alert alert-danger" transition:slide>
    {formError}
  </div>
{/if}

{#if loadedCancellableLineItems && loadedLineItems}
  <div class="row my-2">
    <div class="col text-right">
      <button class="btn btn-outline-primary" on:click={addLineItem}>+ Add Line Item</button>
    </div>
  </div>

  {#each lineItems as lineItem (lineItem.key)}
    <div class="row border-li" style="margin-top: 30px" transition:slide>
      <div class="action-container text-center d-flex flex-column justify-content-center" on:click={deleteLineItem(lineItem)}>
        <span class="action">❌</span>
      </div>
      <div class="col">
        <LineItemForm bind:lineItem market={project.market_code} {categoryOptions} {roomOptions} />
      </div>
    </div>
  {/each}

  <CancelLineItemsForm {cancellableLineItems} {cancellationRequestedItems} bind:lineItemsToCancel {update} />

  <div class="row my-2">
    <div class="col text-right">
      Total = {formatCents(totalCents)}
    </div>
  </div>

  <textarea
    bind:value={description}
    class="form-control my-2"
    placeholder="Enter a note to explain why you are updating this change order"
  />

  <div class="text-right my-2">
    {#if submitBlocker}
      <button disabled class="btn btn-primary">{submitBlocker}</button>
    {:else if submitting}
      <button disabled class="btn btn-primary submitting">Updating...</button>
    {:else}
      <button class="btn btn-primary" on:click={submit}>
        Update {formatCents(totalCents)} Change Order
      </button>
    {/if}
  </div>
{:else}
  <div class="text-center">
    <Stretch/>
  </div>
{/if}
