<script>
 import moment from 'moment'
 import { SvelteGantt, SvelteGanttDependencies, SvelteGanttTable } from 'svelte-gantt'
 import { onMount } from 'svelte';
 import Fa from 'svelte-fa'
 import {
   faAngleDoubleLeft,
   faAngleDoubleRight
 } from '@fortawesome/free-solid-svg-icons'

 export let start
 export let end

 const colors = ['blue', 'green', 'orange']

 export let rows = []
 export let tasks = []

 export let timeRanges = [];

 export let headers = [{ unit: 'month', format: 'MMMM YYYY', sticky: true }, { unit: 'day', format: 'ddd DD', sticky: true, offset: 7 }]

 // Callbacks
 export let onTaskChanged = (task) => {} // Runs after a task's changes are saved. This is the main event to use
 export let onTaskSelect = (task) => {} // When a task is selected
 export let onSwitchRow = (task, row, previousRow) => {}
 export let onChange = (data) => {} // Runs before a task's changes are saved

 // The svelte-gantt API is broken and returns an array that is always just 1 element.
 // This facade converts it to a reasonable API
 const onTasksChanged = (tasks) => { onTaskChanged(tasks[0]); };

 // Methods
 export const unselectTasks = () => { gantt && gantt.unselectTasks() };

 // Internal - calculate start and end times
 let chartStart
 if (start) {
   chartStart = moment(start) // Do not require a Moment as input
 } else {
   const firstTask = tasks.reduce((t1, t2) => t1 && t1.from < t2.from ? t1 : t2, null)
   chartStart = (firstTask ? moment(firstTask.from) : moment()).startOf('month')
 }

 let chartEnd
 if (end) {
   chartEnd = moment(end) // Do not require a Moment as input
 } else {
   const lastTask = tasks.reduce((t1, t2) => t1 && t1.to > t2.to ? t1 : t2, null)
   chartEnd = (lastTask ? moment(lastTask.to) : moment()).endOf('month')
 }

 // Set up the internal variable to hold the gantt chart
 let ganttDiv
 let gantt

 // Reactivity
 $: gantt && gantt.$set({ rows })
 $: gantt && gantt.$set({ tasks })
 $: gantt && gantt.$set({ timeRanges })
 $: gantt && gantt.$set({ from: chartStart })
 $: gantt && gantt.$set({ to: chartEnd })
 $: gantt && gantt.$set({ headers })

 // Constants
 const rowHeight = 52

 const initialOptions = {
   rows,
   tasks,
   timeRanges,
   headers,
   from: chartStart,
   to: chartEnd,

   fitWidth: true,
   tableWidth: 200,
   rowHeight,
   rowPadding: 6,
   columnUnit: 'day',
   columnOffset: 1,
   tableHeaders: [{ title: 'Vendor', property: 'label', width: 200, type: 'tree' }],
   ganttTableModules: [SvelteGanttTable],
   ganttBodyModules: [SvelteGanttDependencies]
 }

 onMount(() => {

   gantt = new SvelteGantt({ target: ganttDiv, props: initialOptions })

   window.gantt = gantt

   gantt.api.tasks.on.switchRow(onSwitchRow)
   gantt.api.tasks.on.select(onTaskSelect)
   gantt.api.tasks.on.change(onChange)
   gantt.api.tasks.on.changed(onTasksChanged)
 })

 function onSetMonthView() {
   chartStart = chartStart.startOf('month')
   chartEnd = chartStart.endOf('month')
 }

 function onSetNextWeek() {
   chartEnd = chartEnd.add(1, 'week')
   chartStart = chartStart.add(1, 'week')
 }

 function onSetPreviousWeek() {
   chartStart = chartStart.subtract(1, 'week')
   chartEnd = chartEnd.subtract(1, 'week')
 }
</script>

<style>
 :global(.sg-table-header-cell.sg-table-cell) {
   font-weight: bold;
   padding-left: 2em;
 }

 :global(.sg-task) {
   color: #656565 !important;
 }

 :global(.sg-table-body-cell.sg-table-cell) {
   font-weight: inherit !important;
 }

 :global(.sg-task.selected) {
   color: white;
   font-weight: 700;
 }

 :global(.sg-task.blue) {
   background: rgb(116, 191, 255);
 }

 :global(.sg-task.blue:hover) {
   background: rgb(98, 161, 216);
 }

 :global(.sg-task.blue.selected) {
   background: rgb(69, 112, 150);
 }

 :global(.sg-task.selected:hover) {
   background: rgb(60, 98, 131);
 }

 :global(.sg-task.green) {
   background-color: rgb(188, 235, 194);
 }

 :global(.sg-task.green:hover) {
   background-color: rgb(143, 197, 150);
 }

 :global(.sg-task.green.selected) {
   background: rgb(121, 150, 125);
 }

 :global(.sg-task.green.selected:hover) {
   background: rgb(100, 126, 103);
 }

 :global(.sg-task.orange) {
   background-color: orange;
 }

 :global(.sg-task.orange:hover) {
   background-color: rgb(238, 161, 45)
 }

 :global(.sg-task.orange.selected) {
   background-color: rgb(219, 149, 45)
 }

 :global(.sg-task.orange.selected:hover) {
   background-color: rgb(199, 135, 38)
 }

 :global(.sg-task-background) {
   background-color: #000103;
   opacity: 0.15;
 }

 :global(.context-menu) {
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
 }

 :global(.row-disabled) {
   background: repeating-linear-gradient(45deg, rgba(55, 55, 55, 0.1), rgba(55, 55, 55, 0.1) 10px, transparent 10px, transparent 20px);
 }

 :global(.sg-table-row.row-group .sg-table-body-cell) {
   background-color: #f3f3f3;
 }

 :global(.sg-tree-expander) {
   border-radius: 50%;
   margin-right: 4px;
   background-color: #f3f3f3;
 }

 :global(.sg-tree-expander > .fas.fa-angle-down::after) {
   content: '-';
   font-weight: bold;
   font-style: normal;
   display: block;
   margin-left: 1px;
   margin-top: -2px;
 }

 :global(.sg-tree-expander > .fas.fa-angle-right::after) {
   content: '+';
   font-weight: bold;
   font-style: normal;
   display: block;
   margin-left: 1px;
   margin-top: -2px;
 }
</style>

<div class="row justify-content-end mb-3">
  <div class="col-2 text-right">
    <button class="btn btn-outline-secondary" on:click={onSetPreviousWeek}>
      <Fa icon={faAngleDoubleLeft} />
    </button>
    <button class="btn btn-outline-secondary ml-3" on:click={onSetNextWeek}>
      <Fa icon={faAngleDoubleRight} />
    </button>
  </div>
</div>

<div class="border">
  <div bind:this={ganttDiv} style="height: 600px; max-height: 100%;"></div>
</div>
