<script>
 import { getContext } from 'svelte'

 export let field
 export let value
 export let editing = true

 let liveValue = value

 const onBlur = () => {
   if (value != liveValue) {
     value = liveValue
   } else {
     editing = false
   }
 }
</script>

<input class="form-control"
       type="number" step="1"
       required={true} bind:value={liveValue}
       on:blur={onBlur} />
