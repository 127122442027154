<script>
 import { fly, fade } from 'svelte/transition'
 import Modal from './modal'

 export let cancelTitle = 'Cancel'
 export let cancelClass = 'outline-secondary'

 export let confirmTitle = 'Confirm'
 export let confirmClass = 'danger'

 export let title = 'Are you sure you want to perform this action?'

 let showDialog = false
 let functionToCall = {
   func: null,
   args: null
 }

 function callFunction () {
   showDialog = false
   functionToCall['func'](...functionToCall['args'])
 }

 function confirm (func, ...args) {
   functionToCall = {func, args}
     showDialog = true
 }
</script>

<slot confirm={confirm}></slot>

{#if showDialog}
  <Modal showDialog={true} title="{title}">
    <p>
      <slot name="description">
        This action can't be undone!
      </slot>
    </p>

    <div slot="footer" class="text-right">
      <button class="btn btn-{cancelClass}" on:click|stopPropagation="{() => showDialog = false }">
        {cancelTitle}
      </button>

      <button class="btn btn-{confirmClass}" on:click|stopPropagation="{callFunction}">
        {confirmTitle}
      </button>
    </div>
  </Modal>
{/if}
