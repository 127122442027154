// The SFR3 CSRF token should be rendered to window._SFR3_TOKEN
if (!window._SFR3_TOKEN) {
  console.error('Missing Authentication Token')
}

const moment = require('moment')

const SFR3_JS_API_ROOT_PATH = '/js_api'

const validateResponse = (response) => {
  if (response.status == 418 && !window.activelyLoggingOut) {
    window.activelyLoggingOut = true
    alert('You have been logged out; please log in again')
    window.location = '/'
    return false
  }

  return true
}

const baseGet = async function (rootPath, url, params = {}) {
  const urlParams = Object.entries(params)
    .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
    .join('&')

  const response = await fetch(`${rootPath}${url}?${urlParams}`, {
    headers: { Accept: 'application/json' },
    cache: 'no-cache',
  })

  if (validateResponse(response)) {
    return response
  }
}

const baseFetchWithBody = async function (path, method, bodyJSON = {}) {
  const response = await fetch(`${SFR3_JS_API_ROOT_PATH}${path}`, {
    method: method,
    cache: 'no-cache',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...{ authenticity_token: window._SFR3_TOKEN },
      ...bodyJSON,
    }),
  })

  if (validateResponse(response)) {
    return response
  }
}

const sfr3Get = async function (url, params = {}) {
  return baseGet(SFR3_JS_API_ROOT_PATH, url, params)
}

const sfr3Post = async function (url, bodyJSON = {}) {
  return baseFetchWithBody(url, 'POST', bodyJSON)
}

const sfr3Put = async function (url, bodyJSON = {}) {
  return baseFetchWithBody(url, 'PUT', bodyJSON)
}

const sfr3Delete = async function (url, bodyJSON = {}) {
  return baseFetchWithBody(url, 'DELETE', bodyJSON)
}

const SFR3 = {
  buyerRequests: {
    // Attributes of BuyerRequests
    //  id
    //  sales_repair_scope_id
    //  sfr3_response
    //  description
    //  line_items
    save: async (buyer_request) => sfr3Post(`/buyer_requests/save`, buyer_request),
    destroy: async (buyer_request) => sfr3Delete(`/buyer_requests/${buyer_request.id}`),
  },
  changeOrders: {
    approve: async (changeOrderId, options = {}) => sfr3Post(`/change_orders/${changeOrderId}/approve`, options),
    cancel: async (changeOrderId) => sfr3Post(`/change_orders/${changeOrderId}/cancel`),
    create: async (projectId, vendorId, lineItems, lineItemIdsToCancel, description, attachments) => {
      return sfr3Post('/change_orders', {
        project_id: projectId,
        vendor_id: vendorId,
        line_items: lineItems,
        line_item_ids_to_cancel: lineItemIdsToCancel,
        description: description,
        attachments: attachments || [],
      })
    },
    update: async (projectId, changeOrderId, lineItems, lineItemIdsToCancel, description, attachments) => {
      return sfr3Put(`/change_orders/${changeOrderId}`, {
        project_id: projectId,
        line_items: lineItems,
        line_item_ids_to_cancel: lineItemIdsToCancel,
        description: description,
        attachments: attachments || []
      })
    },
    // options:
    // - project_id
    // - status
    // - page
    // - per_page
    list: async (options) => sfr3Get('/change_orders', options),
    permissions: async (id = null) => sfr3Get('/change_orders/permissions', id ? { id } : {}),
    reject: async (changeOrderId, options = {}) => sfr3Post(`/change_orders/${changeOrderId}/reject`, options),
    revision: async (changeOrderId, options = {}) => sfr3Post(`/change_orders/${changeOrderId}/revision`, options),
    list_line_items: async (changeOrderId) => sfr3Get(`/change_orders/${changeOrderId}/line_items`),
    listByProject: async (project_id) =>
      sfr3Get('/change_orders/list_by_project', { project_id }),
    updateApprovalReason: async (changeOrderId, approvalReason) => sfr3Put(`/change_orders/${changeOrderId}/update_approval_reason`, {
      approval_reason: approvalReason
    })
  },

  checkIns: {
    create: async (projectId, note, completedLineItemIds, attachments) => {
      return sfr3Post('/check_ins', {
        project_id: projectId,
        note: note,
        completed_line_items: completedLineItemIds || [],
        attachments: attachments || [],
      })
    },
    enabled: async (marketId) => sfr3Get('/check_ins/enabled', { market_id: marketId })
  },

  comments: {
    create: async (commentable_id, commentable_type, text) => {
      return sfr3Post('/comments', { commentable_id, commentable_type, text })
    },
    for: async (commentable_id, commentable_type) => {
      return sfr3Get('/comments/for', { commentable_id, commentable_type })
    },
    edit: async (id, text) => sfr3Put(`/comments/${id}`, { text }),
  },

  expenseCategories: {
    list: async () => sfr3Get('/expense_categories'),
  },

  manualExpenses: {
    create: async (options) => sfr3Post('/manual_expenses', options),
    // project_id: optional
    list: async (options) => sfr3Get('/manual_expenses', options),
    enabled: async (marketId) => sfr3Get('/manual_expenses/enabled', { market_id: marketId })
  },

  payments: {
    list: async (options) =>
      sfr3Get('/payments', options),
    approve: async (paymentId) => sfr3Post(`/payments/${paymentId}/approve`),
    cancel: async (paymentId) => sfr3Post(`/payments/${paymentId}/cancel`),
    restart: async (paymentId) => sfr3Post(`/payments/${paymentId}/restart`),
  },

  projects: {
    // options includes
    // address: string
    list: async (status = 'active', page = 1, perPage = 40, options) =>
      sfr3Get('/projects', { ...options, status, page, per_page: perPage }),
    checkIns: async (projectId) => sfr3Get(`/projects/${projectId}/check_ins`),
    cancellableLineItems: async (projectId) => sfr3Get(`/projects/${projectId}/cancellable_line_items`),
    // options:
    // { status: <str> }
    lineItems: async (projectId, options) => sfr3Get(`/projects/${projectId}/line_items`, options),
    lineItemsProjectView: async (projectId, options) => sfr3Get(`/projects/${projectId}/line_items_project_view`, options),
    permissions: async (id = null) => sfr3Get('/projects/permissions', id ? { id } : {}),
    update: async (projectId, params) => sfr3Put(`/projects/${projectId}`, { project: params }),
    vendorsWithPendingWorkOrders: async (projectId) => sfr3Get(`/projects/${projectId}/vendors_with_pending_work_orders`),
    vendorsWithPayments: async (projectId) => sfr3Get(`/projects/${projectId}/vendors_with_payments`),
    createPaymentsForVendors: async (projectId, options) => sfr3Post(`/projects/${projectId}/create_payments_for_vendors`, options),
    qcScheduling: async (marketId) => sfr3Get('/projects/qc_scheduling_enabled', { market_id: marketId }),

    salesRepairs: {
      permissions: async (id = null) =>
        sfr3Get('/projects/sales_repairs/permissions', id ? { id } : {}),
      create: async (options) => sfr3Post('/projects/sales_repairs', options),
      updateDueDate: async (projectId, newDate) =>
        sfr3Post(`/projects/sales_repairs/${projectId}/update_due_date`, { due_date: newDate }),
    },

    milestones: {
      list: async (projectId) => sfr3Get(`/projects/${projectId}/milestones`),
      cancel: async (projectId, id) => sfr3Delete(`/projects/${projectId}/milestones/${id}/cancel`),
      create: async (projectId, options) => sfr3Post(`/projects/${projectId}/milestones`, options),
      changeDates: async (id, projectId, start_date, end_date) => sfr3Post(
        `/projects/${projectId}/milestones/${id}/change_dates`, {
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD')
      }),
      editLineItems: async (id, projectId, line_item_ids) => sfr3Post(
        `/projects/${projectId}/milestones/${id}/line_items`, { line_item_ids })
    },

    changeOrders: {
      vendorEnabled: async (projectId) => sfr3Get(
        `/projects/${projectId}/change_orders/vendor_enabled`),
    }
  },

  properties: {
    lineItemsAvailableForSalesRepair: async (sfr3_id) =>
      sfr3Get(`/properties/${sfr3_id}/line_items_available_for_sales_repair`),
    update: async (id, params) => sfr3Put(`/properties/${id}`, { property: params }),
    fuzzySearch: async (value) => sfr3Get('/properties/fuzzy_list', { value }),
    sfr3Params: async (id) => sfr3Get(`/properties/${id}/sfr3_params`)
  },

  rmProfiles: {
    list: async (market_id) => sfr3Get('/rm_profiles', { market_id: market_id }),
    activeRenos: async (rmProfileId) => sfr3Get(`/rm_profiles/${rmProfileId}/active_renos`),
    notStartedRenos: async (rmProfileId) => sfr3Get(`/rm_profiles/${rmProfileId}/not_started_renos`),
    startReno: async (rmProfileId, project_id) => sfr3Post(`/rm_profiles/${rmProfileId}/start_reno`, { project_id }),
    hideScopedAndReady: async (marketIds) => sfr3Get('/rm_profiles/hide_scoped_and_ready', { market_ids: marketIds })
  },

  salesRepairScopes: {
    permissions: async (id = null) => sfr3Get('/sales_repair_scopes/permissions', id ? { id } : {}),
    transition: async (id, transition) =>
      sfr3Post(`/sales_repair_scopes/${id}/transition`, { transition }),
    addAttachments: async (id, attachments) =>
      sfr3Post(`/sales_repair_scopes/${id}/add_attachments`, { attachments }),
  },

  services: {
    list: async (market) => sfr3Get('/catalog/services', { market }),
    categoryOptions: async () => sfr3Get('/catalog/services/category_options'),
    scopingCategoryOptions: async () => sfr3Get('/catalog/services/scoping_category_options'),
  },

  simpleScopes: {
    editLineItems: async (id, line_items) => sfr3Post(`/simple_scopes/${id}/edit_line_items`, { line_items }),
    permissions: async (id = null) => sfr3Get('/simple_scopes/permissions', id ? { id } : {}),
    transition: async (id, transition, deadline) =>
      sfr3Post(`/simple_scopes/${id}/transition`, { transition, deadline }),
    addAttachments: async (id, attachments) =>
      sfr3Post(`/simple_scopes/${id}/add_attachments`, { attachments }),
  },

  vendors: {
    // Options are { market: :code, project_id: :id }. All can be null.
    list: async (options) => sfr3Get('/vendors', options),
  },

  workApprovals: {
    approve: async (workApprovalId) => sfr3Post(`/work_approvals/${workApprovalId}/approve`),
    list: async (status, page = 1, perPage = 40, options) =>
      sfr3Get('/work_approvals', { ...options, status, page, per_page: perPage }),
    reject: async (workApprovalId, options = {}) => sfr3Post(`/work_approvals/${workApprovalId}/reject`, options),
    create: async (lineItemIds, attachments) => {
      return sfr3Post('/work_approvals', {
        line_items: lineItemIds,
        attachments: attachments || [],
      })
    },
    listByProject: async (project_id, page = 1, perPage = 10) =>
      sfr3Get('/work_approvals/list_by_project', { project_id, page, perPage })
  },

  workOrders: {
    cancel: async (id) => sfr3Delete(`/work_orders/${id}/cancel`),
    createFromSchedule: async (project_id) => sfr3Post('/work_orders/create_from_schedule', { project_id }),
    index: async (project_id, data) => sfr3Get('/work_orders', { project_id, ...data })
  },

  lineItems: {
    unassignLineItems: async (work_order_id, line_item_ids) => sfr3Post(`/work_orders/${work_order_id}/line_items/unassign_line_items`, { line_item_ids }),
    uncategorizedLineItems: async (page = 1, perPage = 10, options) => sfr3Get('/line_items/category_planner', { ...options, page, perPage }),
    editPlannerCategory: async (line_item_id, planner_category) => sfr3Post(`/line_items/${line_item_id}/edit_planner_category`, { planner_category }),
    roomOptions: async () => sfr3Get('/line_items/room_options')
  },

  markets: {
    list: async (options) => sfr3Get('/markets', options),
  },

  project_types: {
    list: async (options) => sfr3Get('/project_types', options),
  }
}

export { SFR3 }
