<script>
 import { flip } from 'svelte/animate'

 import { formatCents } from '../../javascript/money'

 import SelectHeader from './qi_table/select_header'
 import IntHeader from './qi_table/int_header'
 import MoneyHeader from './qi_table/money_header'
 import StringHeader from './qi_table/string_header'

 export let columns = []
 export let objects = []
 export let defaultSortAttribute = 'status'
 export let defaultSortDirection = 'asc'
 export let csvUri = ''

 // If you will be adding/removing items from the table, you can optionally
 // pass in send/receive functions as created by crossfade. These will be used
 // to animate the rows entering or leaving the table
 export let send = () => {}
 export let receive = () => {}

 const DEFAULT_SORT = { attr: defaultSortAttribute, dir: defaultSortDirection }

 let sortData = DEFAULT_SORT

 const HEADER_COMPONENTS = {
   int: IntHeader,
   money: MoneyHeader,
   string: StringHeader
 }

 let filteredObjects = objects

 const filterAndSort = () => {
   filteredObjects = objects
     .filter(o => {
       for (let column of columns) {
         if (column._qt_show) {
           if (!column._qt_show(o[column.attr])) { return false }
         }
       }

       return true
     })
     .sort((a, b) => {
       const attr = sortData.attr
       const dir = sortData.dir

       let aAttr = a[attr]
       let bAttr = b[attr]

       if (aAttr && aAttr.toLowerCase) { aAttr = aAttr.toLowerCase() }
       if (bAttr && bAttr.toLowerCase) { bAttr = bAttr.toLowerCase() }

       if (attr && dir && aAttr != bAttr) {
         if (aAttr === null || aAttr === undefined) {
           if (bAttr === null || bAttr === undefined) { return 0; }
           return dir === 'desc' ? 1 : -1
         } else if (bAttr === null || bAttr === undefined) {
           return dir === 'desc' ? -1 : 1
         } else if (dir === 'desc') {
           return aAttr > bAttr ? -1 : 1
         } else {
           return aAttr > bAttr ? 1 : -1
         }
       } else {
         return 0
       }
     })
 }

 // Add reactivity to updates to the `objects` array
 $: if (objects) { filterAndSort() }

 $: objRows = filteredObjects.map(o => {
   let arr = columns.map((column) => {
     if (column.type === 'money') { return formatCents(o[column.attr]) }
     return o[column.attr] || ''
   })
   arr._obj = o
   return arr
 })

 $: csvUri = encodeURI(
   'data:text/csv;charset=utf-8,'
   + columns.map(h => h.title).join(',') + "\n"
   + objRows.map(row => row.map(data => csvEscape(data || '')).join(',')).join("\n"))

 const sortOn = (attr) => {
   if (sortData.attr !== attr || sortData.dir === null) {
     sortData = { attr: attr, dir: 'asc' }
   } else if (sortData.dir === 'asc') {
     sortData = { attr: attr, dir: 'desc' }
   } else {
     sortData = DEFAULT_SORT
   }

   filterAndSort()
 }

 const csvEscape = (obj) => '"' + String(obj).replaceAll('"','""') + '"'
</script>

<style>
 th {
   vertical-align: top;
 }

 th, td {
   min-width: 150px;
 }

 th.longtext, td.longtext {
   min-width: 400px;
 }

 th.custom, td.custom {
   min-width: 0;
 }

 th>h5 {
   cursor: pointer;
 }
</style>

<div id="dataWindow">
  <table class="table table-striped table-sm table-responsive mb-0" id="dataTable">
    <thead>
      <tr>
        {#each columns as column}
          <th class="{column.type}">
            <h5 on:click={() => sortOn(column.attr)}>
              {column.title}
            </h5>
            {#if column.filter === undefined || column.filter}
              {#if column.filterType === 'select'}
                <SelectHeader
                  bind:column
                  options={objects.map((o) => { return { value: o[column.attr], label: o[column.attr] || 'None' }})}
                          on:change={filterAndSort}>
                </SelectHeader>
              {:else}
                <svelte:component
                  this={HEADER_COMPONENTS[column.type] || HEADER_COMPONENTS['string']}
                       bind:column
                  on:change={filterAndSort}>
                </svelte:component>
              {/if}
            {/if}
          </th>
        {/each}
      </tr>
    </thead>
    <tbody>
      {#each objRows as row (row._obj.id)}
        <tr animate:flip={{duration: 400}} in:receive={{key: row._obj.id}} out:send={{key: row._obj.id}}>
          {#each row as cell, i}
            <td class="{columns[i].type} {['money', 'int'].includes(columns[i].type) ? 'text-right' : ''}" >
              {#if columns[i].type === 'custom'}
                <slot name="customCell" object={row._obj} column={columns[i]} {row}>
                </slot>
              {:else}
                {cell}
              {/if}
            </td>
          {/each}
        </tr>
      {/each}
    </tbody>
  </table>
</div>
