<script>
 // This whole thing might be temporary for just a few days, or it might evolve into something much bigger & better
 import { onMount } from 'svelte'

 import { SFR3 } from '../../../javascript/sfr3_api'

 import Confirm from '../../lib/confirm'

 // { id: <x> }
 export let project

 export let vendors = []

 export const refresh = async () => {
   const response = await SFR3.projects.vendorsWithPendingWorkOrders(project.id)
   if (response.ok) {
     const data = await response.json()
     vendors = data.items || []
   }
 }

 $: plural = vendors.length == 1 ? '' : 's'
 $: actionSentence = `Create ${vendors.length} Work Order${plural}`

 const submit = async () => {
   const response = await SFR3.workOrders.createFromSchedule(project.id)
   if (response.ok) {
     // TEMP: Reload the page so that the list of WOs gets updated.
     // That's the next thing to move to Svelte
     window.location.reload()

     // TEMP: Enable after we no longer need the reload() above
     // refresh()
   } else {
     alert('Error creating work orders')
   }
 }

 onMount(refresh)
</script>

<Confirm
  title="{actionSentence}?"
  confirmTitle="Create Work Order{plural}"
  cancelTitle="Nevermind"
  let:confirm={confirmThis}>

  <button disabled={vendors.length == 0} class="btn btn-danger" on:click|stopPropagation={confirmThis(submit)}>
    {actionSentence}
  </button>

  <span slot="description">
    Confirm that you'd like to create work orders for the following vendors:
    <ul>
      {#each vendors as vendor}
        <li>{vendor.name}</li>
      {/each}
    </ul>
  </span>
</Confirm>
