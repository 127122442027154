<script>
 import { slide } from 'svelte/transition'
 import Fa from 'svelte-fa'
 import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
 import Select from 'svelte-select'

 import { SFR3 } from '../../javascript/sfr3_api'
 import { formatCents } from '../../javascript/money'

 import AddLineItems from './add_line_items'

 export let buyerRequest = {}
 export let editable = true
 export let market

 let id = buyerRequest.id
 let description = buyerRequest.description || ''
 let sfr3_response = buyerRequest.sfr3_response || ''
 let line_items = buyerRequest.line_items || []
 let response = buyerRequest.response || ''
 let sales_repair_scope_id = buyerRequest.sales_repair_scope_id || null
 let transitionBlocker = null

 $: buyerRequest = { ...buyerRequest, id, description, sfr3_response, line_items, response }

 $: {
   if (line_items.filter((li) => li.quantity != Math.floor(li.quantity)).length > 0) {
      transitionBlocker = 'Quantities must be whole numbers'
   } else if (line_items.filter((li) => li.quantity < 1).length > 0) {
      transitionBlocker = 'Enter a quantity to all new line items'
   } else if (line_items.filter((li) => (li.category == "Other" && li.planner_category == null)).length > 0) {
      transitionBlocker = "Must select a Service Category when selecting 'Other' Line Items"
   } else {
      transitionBlocker = null
   }
 }

 let editing = editable && !id

 const toggleEditing = async () => {
   if(transitionBlocker) {
     alert(transitionBlocker)
     return
   } else {
    if (editing) {
     response = await SFR3.buyerRequests.save(buyerRequest)
     if (response.ok) {
       const data = await response.json()
       id = id || (data.buyer_request && data.buyer_request.id)
     } else {
       try {
         const data = await response.json()
         alert(data.error)
       } catch {
         alert('Unknown error when saving buyer request')
       }
       return // Do not close editing
     }
    }
   }

   editing = editable && !editing
 }

 const RESPONSE_OPTIONS = [
   { value: 'will_repair', label: 'Will Repair', badge: 'secondary' },
   { value: 'modified', label: 'Repair With Modifications', badge: 'info' },
   { value: 'rejected', label: 'Rejected', badge: 'danger' },
   { value: 'needs_clarification', label: 'Needs Clarification', badge: 'warning' }
 ]
 let selectedResponseOption = RESPONSE_OPTIONS.find((o) => o.value == response)
 $: response = selectedResponseOption ? selectedResponseOption.value : response
 $: responseBadge = selectedResponseOption ? selectedResponseOption : { label: 'TBD', badge: 'dark' }

                    //TODO get validations to work
</script>

<div class="my-3">
  {#if editing}
    <div transition:slide>
      <div class="form-group">
        <label>
          Description
        </label>
        <textarea class="form-control" bind:value={description} placeholder="Copy the buyer's description here" />
      </div>

      <div class="form-group">
        <label>
          Response
        </label>
        <Select items={RESPONSE_OPTIONS} bind:value={selectedResponseOption} />
      </div>

      <div class="form-group">
        <label>
          Response Note
        </label>
        <textarea class="form-control" bind:value={sfr3_response} placeholder="(optional) If we need to reply to the buyer, write a note for them here" />
      </div>

      {#if ['will_repair', 'modified'].includes(response) }
        <div class="form-group" transition:slide>
          <label>Line Items</label>
          <AddLineItems bind:lineItems={line_items} {market}/>
        </div>
      {/if}

      <button class="btn btn-primary" on:click={toggleEditing}>Save</button>
    </div>
  {:else}
    <div out:slide>
      <div style="font-size: large">
        {#if editable}
          <span style="cursor: pointer" on:click={toggleEditing}><Fa icon={faPencilAlt}/></span>
        {/if}
        <div class="badge badge-{responseBadge.badge}">{responseBadge.label}</div>
      </div>
      <div class="mb-3">
        {description}
      </div>
      {#if sfr3_response}
        <div class="row">
          <div class="col-sm font-weight-bold">SFR3 Reply&raquo;</div>
          <div class="col-md-10 font-italic">{sfr3_response}</div>
        </div>
      {/if}
      {#if line_items.length > 0}
        <table class="table mt-3 mb-0 table-striped table-sm border">
          <tbody>
            {#each line_items as lineItem (lineItem.key)}
              <tr>
                <td class="text-right">{lineItem.quantity}</td>
                <td>{lineItem.name || 'TBD'}</td>
                <td class="text-right">{formatCents(lineItem.cost_cents)}</td>
              </tr>
            {/each}
          </tbody>
        </table>
      {/if}
    </div>
  {/if}
</div>
