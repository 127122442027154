<script>
 import { createEventDispatcher, onMount } from 'svelte'
 import { slide } from 'svelte/transition'

 import { formatCents } from '../../../javascript/money'
 import { SFR3 } from '../../../javascript/sfr3_api'

 import LineItemTable from '../../line_items/table'

 export let milestoneId
 export let lineItems
 export let projectId
 export let vendorId

 let newLineItems = [...lineItems]
 let lineItemOptions = []

 const dispatch = createEventDispatcher()

 let selected = []
 let clearSearch
 let addSelectedToMilestone = () => {
   while (selected.length > 0) {
     const item = selected.pop()
     lineItemOptions.splice(lineItemOptions.indexOf(item), 1)
     newLineItems.push(item)
   }

   // selected, lineItems, and milestone were modified in place,
   // so now reassign them and force refresh of the component
   selected = selected
   newLineItems = newLineItems
   lineItemOptions = lineItemOptions

   clearSearch && clearSearch()
 };

 let canSubmit = false
 $: {
   const newIds = newLineItems.map((li) => li.id).sort()
   const oldIds = lineItems.map((li) => li.id).sort()
   canSubmit = (() => {
     if (newIds.length != oldIds.length) { return true; }
     for (let i=0; i<newIds.length; i++) {
       if (newIds[i] != oldIds[i]) { return true; }
     }
     return false
   })()
 }

 let submitting = false
 export const submit = async () => {
   if (!canSubmit) { return; }

   submitting = true

   const response = await SFR3.projects.milestones.editLineItems(milestoneId, projectId, newLineItems.map((li) => li.id))

   if (response.ok) {
     const data = await response.json()
     lineItems = [...newLineItems]
     dispatch('doneEditing')
     dispatch('lineItemsEdited', { milestone: data.milestone })
   } else {
     try {
       const data = await response.json()
       createError = data.error
     } catch (e) {
       createError = 'Unknown error while updating Milestone'
     }
   }

   submitting = false
 }

 // Load line items
 onMount(async () => {
   const response = await SFR3.projects.lineItems(projectId, { 
     elegible_for_milestones: true, 
     vendor_id: vendorId 
    })
   if (response.ok) {
     lineItemOptions = (await response.json()).items
   }
 })
</script>

<h3>Add Line Items</h3>

<div class="row line-item-assignment-form">
  <div class="col-lg-4 order-lg-2 mb-3">
    <div class="row">
      {#if submitting}
        <div class="col">
          <button disabled class="btn btn-primary submitting btn-block">Submitting...</button>
        </div>
      {:else}
        <div class="col">
          <button class="btn btn-danger btn-block" on:click={() => { dispatch('doneEditing') }}>
            Cancel
          </button>
        </div>
        <div class="col">
          {#if canSubmit}
            <button class="btn btn-primary btn-block" on:click={submit}>
              Update Milestone
            </button>
          {:else}
            <button disabled class="btn btn-primary btn-block">
              Edit line items...
            </button>
          {/if}
        </div>
      {/if}
    </div>

    <div class="row my-3">
      <span class="col-4">New Total</span>
      <span class="col text-right font-weight-bold">
        {formatCents(newLineItems.map((item) => item.cost_cents).reduce((a,b) => a+b,0))}
      </span>
    </div>

    <table class="table table-sm table-borderless mb-0">
      <tbody>
        {#each newLineItems as item (item.id)}
          <tr key={item.id} transition:slide>
            <td>{item.quantity}</td>
            <td>{item.name}</td>
            <td class="text-right">{formatCents(item.cost_cents)}</td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>

  <div class="col-lg line-item-selector order-lg-1 mb-3 border-right-lg">
    <div class="row border-top my-2 d-lg-none"></div>
    <div class="row mb-2">
      <div class="col-md-8">
        <h4>Select Line Items</h4>
      </div>
      <div class="col-md">
        <button on:click={addSelectedToMilestone} disabled={selected.length === 0}
                class="btn btn-block {selected.length > 0 ? 'btn-info' : 'btn-outline-info'}"
        >
          Add {selected.length} to Milestone &raquo;
        </button>
      </div>
    </div>

    <LineItemTable
      bind:lineItems={lineItemOptions}
                     bind:clearSearch
      bind:group={selected}
                     showStatus={false} showQuantity={true}
                     selectable={ ()=> true }
    />
  </div>
</div>
