<script>
 export let validationOn = false
 export let label
 export let value
 export let error
 export let placeholder = ''
 export let calcError = () => null

 let formField

 $: error = calcError(value) // value is in here to prompt recalculating when it changes
 $: hasError = validationOn && !!error
 $: if (hasError && formField) { formField.setCustomValidity(error) }
</script>

<div class="form-group" class:was-validated={hasError}>
  <label class:text-danger={hasError}>
    {label}
  </label>

  {#if $$slots.default}
    <div class:is-invalid={hasError} class:is-valid={!hasError}>
      <slot {value} {hasError}/>
    </div>
  {:else}
    <input class="form-control"
           bind:value bind:this={formField}
                  placeholder={placeholder} />
  {/if}
  <div class="invalid-feedback">
    {error}
  </div>
</div>
