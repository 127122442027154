<script>
  import { getContext, onMount } from "svelte";
  import { SFR3 } from "../../javascript/sfr3_api";

  import Select from "svelte-select";

  export let market = "";
  export let vendor = null;
  export let hasError;
  export let getVendorLabel = (v) =>
    `${v.name}${v.contact_name ? ` (${v.contact_name})` : ""}`;
  export let vendorType = "specialty";

  const project = getContext("project");

  let loaded = false;
  let items = [];
  let vendors = [];
  let value;

  $: noOptionsMessage = loaded ? "No Matching Vendors" : "Loading...";
  $: vendor = value ? value.vendor : null;

  const loadVendors = async () => {
    const options = {
      market,
      type: vendorType,
    };
    if (project) {
      options["project_id"] = project.id;
    }
    const response = await SFR3.vendors.list(options);
    if (response.ok) {
      vendors = await response.json();
      items = vendors.map((v) => {
        return {
          value: v.id,
          label: getVendorLabel(v),
          vendor: v,
          // Specialty vendors are always selectable
          // If we are selecting a GC, then either the GC must have extra capacity or
          // or must be currently assigned to this project
          selectable:
            vendorType != "gc" ||
            v.remaining_gc_capacity > 0 ||
            (vendor && v.id == vendor.id),
        };
      });
      value = vendor ? items.find((i) => i.value == vendor.id) : null;
    }
    loaded = true;
  };

  const groupBy = (vendorOption) => vendorOption.vendor.main_specialty;

  const itemFilter = (label, filterText, option) => {
    const lowCaseFilter = filterText.toLowerCase();
    const vendor = option.vendor;
    return (
      vendor.name.toLowerCase().includes(lowCaseFilter) ||
      vendor.main_specialty.toLowerCase().includes(lowCaseFilter) ||
      (vendor.contact_name || "").toLowerCase().includes(lowCaseFilter)
    );
  };

  onMount(loadVendors);
</script>

<div class="text-body">
  <Select
    {items}
    {noOptionsMessage}
    {groupBy}
    {itemFilter}
    bind:value
    bind:hasError
    placeholder="Vendor..."
    isClearable
  />
</div>
