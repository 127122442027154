<script>
  import { onMount } from 'svelte'
  import { SFR3 } from '../../javascript/sfr3_api'
  import { formatCents } from '../../javascript/money'

  import Select from 'svelte-select'

  export let market
  export let selectedService = undefined
  export let includeOther = false
  export let serviceId = false
  export let isPlannerCategory

  let options = []
  const groupBy = (service) => service.template_category

  const loadOptions = async () => {
    const response = await SFR3.services.list(market)
    const newOptions = (await response.json())
      .map((service) => {
        service.label = `${service.name}: ${formatCents(service.price_cents)}`
        if (service.units.toUpperCase() !== 'COUNT') {
          service.label = service.label + ` / ${service.units}`
        }
        return service
      })
      .sort((a, b) => a.name > b.name ? 1 : -1)
    if (includeOther) {
      newOptions.push({
        id: 'other',
        label: 'Other Custom Line Item'
      })
    }

    options = newOptions

    // If a serviceId was provided but not a default selected service, set up the selected service
    if (serviceId && !selectedService) {
      selectedService = newOptions.find((opt) => opt.id === serviceId)
    }
  }
  onMount(async () => loadOptions())

  const handleSelect = (event) => {
    if (event.detail.id === "other" || event.detail.id > 999) {
      isPlannerCategory = true;
    } else {
      isPlannerCategory = false;
    }
  }
 </script>

 <style>
  .themed {
    --borderFocusColor: #80bdff;
    --border: 1px solid #ced4da;
    --borderRadius: 0.25rem;
    --borderHoverColor: #ced4da;
    --height: 34px;
    --clearSelectTop: 4px;
    --clearSelectBottom: 4px;
  }
 </style>

 <div class="themed">
   <Select
     items={options}
     groupBy={groupBy}
     bind:value={selectedService}
     on:select={handleSelect}
     optionIdentifier="id"
     noOptionsMessage="Loading..."
     placeholder="Type to Select a Service..."></Select>
 </div>
