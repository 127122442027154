<script>
 import { createEventDispatcher } from 'svelte'

 import Fa from 'svelte-fa'
 import { faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons'

 import Modal from '../lib/modal'
 import Create from './create'

 export let project
 export let enabled

 let showDialog = false

 const dispatch = createEventDispatcher()

 function toggleDialog () {
   showDialog = !showDialog
 }

 function created (event) {
   showDialog = false
   dispatch('created', event.detail)
 }
</script>

{#if enabled}
  <div class="btn btn-success btn-block" on:click|stopPropagation={toggleDialog}>
    <Fa icon={faMoneyBillAlt}/>
    New Manual Expense
  </div>
{:else}
  <button disabled class="btn btn-secondary submitting">New Manual Expense</button>
{/if}

<Modal bind:showDialog hideFooter={true} title="New Manual Expense: {project.property.address}">
  <Create {project} on:created={created}/>
</Modal>
