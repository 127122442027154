<script>
 import { onMount } from 'svelte'
 import { slide } from 'svelte/transition'
 import { tweened } from 'svelte/motion'
 import { bounceOut } from 'svelte/easing'

 export let project

 let hidden = true
 let arrowRotation = tweened(0, { duration: 700, easing: bounceOut })

 $: files = (project.attachments || []).filter((a) => !a.is_image)
 $: images = (project.attachments || []).filter((a) => a.is_image)

 const toggleDisplay = () => {
   hidden = !hidden
   $arrowRotation = $arrowRotation > 0 ? 0 : 90
 }

 onMount(async () => {
   // Preload images so when the user opens the section for the first time, they load smoothly
   for (const image of images) {
     (new Image()).src = image.url
   }
 })
</script>

<style>
 h2 {
   cursor: pointer;
 }
</style>

{#if files.length > 0 || images.length > 0}
  <h2 class="mt-3" on:click={toggleDisplay}>
    Files ({files.length + images.length})
    <span style="display: inline-block; transform: rotate({$arrowRotation}deg);">&#x25B6;</span>
  </h2>

  {#if !hidden}
    <div class="container" transition:slide>
      <ul class="mb-0">
        {#each files as file (file.url)}
          <li>
            <a href="{file.url}" target="_blank">{file.filename}</a>
          </li>
        {/each}
      </ul>

      <div class="row row-cols-3 row-cols-md-6">
        {#each images as image}
          <div class="col mt-3">
            <div class="card"><img src="{image.url}" class="img-fluid" alt="Project" /></div>
          </div>
        {/each}
      </div>
    </div>
  {/if}
{/if}
