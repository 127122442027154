<script>
 import Datepicker from 'svelte-calendar';
 import Fa from 'svelte-fa'
 import { faEdit, faCalendarCheck } from '@fortawesome/free-solid-svg-icons'
 import { Stretch } from 'svelte-loading-spinners'
 import Moment from 'moment'
 import { slide } from 'svelte/transition'

 import Modal from '../lib/modal'
 import { SFR3 } from '../../javascript/sfr3_api'
 import { onMount } from 'svelte';

 export let project = {}
 export let dateFormat = 'ddd YYYY-MM-DD'

 const property = project.property
 const marketId = project.market_id
 const start = new Date()
 const end = Moment().add(2, 'weeks').toDate()
 let editing = false
 let loading = false
 let jcMoment
 let newJCDate
 let enabled = true

 // Initialize jcMoment
 if (property && property.reno_ready_for_joint_inspection && property.joint_inspection_status) {
   jcMoment = Moment(property.joint_inspection_status)
 }

 const toggle = () => editing = !editing

 const submit = async () => {
   // Skip if no change
   if (Moment(newJCDate).isSame(jcMoment)) {
     editing = false
     return
   }

   loading = true
   const response = await SFR3.projects.update(
     project.id,
     {
       property_attributes: {
         reno_ready_for_joint_inspection: true,
         joint_inspection_status: Moment(newJCDate).format('YYYY-MM-DD')
       }
     }
   )

   if (response.ok) {
     jcMoment = Moment(newJCDate)
     editing = false
     loading = false
   } else {
     try {
       const data = await response.json()
       if (data.error) {
         alert(data.error)
       }
     } catch {
       alert('Error Saving')
     }
     loading = false
   }
 }

 const qcInspectionEnabled = async () => {
   const data = await SFR3.projects.qcScheduling( marketId )
   enabled = (await data.json())['qc_scheduling_enabled']
 }

 onMount(async () => qcInspectionEnabled())
</script>

<style>
 :global(.project-joint-certification .datepicker .calendar-button) {
   width: 100%;
 }

 .disabled-button {
   margin-right: 15px;
 }
</style>

<div class="row">
  <div class="col">
    <h2>QC Inspection</h2>
  </div>
    {#if enabled}
      {#if !editing}
        <div class="col-sm-5 text-sm-right">
          {#if jcMoment}
            {jcMoment.format(dateFormat)}
            <span on:click={toggle}>
              <Fa icon={faEdit} style="cursor: pointer"/>
            </span>
          {:else}
            <div class="btn btn-success btn-block disabled-button" on:click|stopPropagation={toggle}>
              <Fa icon={faCalendarCheck}/>
              Schedule QC Inspection
            </div>
          {/if}
        </div>
      {/if}
    {:else}
      <button disabled class="btn btn-secondary submitting disabled-button">Schedule in OnSite</button>
    {/if}
</div>

<div class="project-joint-certification mt-3">
  {#if loading}
    <div class="text-center">
      <Stretch color="#999999"/>
    </div>
  {:else}
    {#if editing}
      <div transition:slide>
        <Datepicker {start} {end} bind:selected={newJCDate} style="display: block"/>
        <div class="row my-3">
          <div class="col text-left">
            <button class="btn btn-danger" on:click={toggle}>Cancel</button>
          </div>
          <div class="col text-right">
            <button class="btn btn-success" on:click={submit}>Schedule</button>
          </div>
        </div>
      </div>
    {/if}
  {/if}
</div>
