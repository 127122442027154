<script>
 const Moment = require('moment');

 import Fa from 'svelte-fa'
 import { faEdit } from '@fortawesome/free-solid-svg-icons'

 import Datepicker from 'svelte-calendar'

 import { SFR3 } from '../../javascript/sfr3_api'

 export let project = {}
 export let dateFormat = 'ddd YYYY-MM-DD'

 const today = new Date()
 const end = Moment().add(6, 'months').toDate()

 let dateChosen = false
 let editing = false
 let contextClass = ''

 let estimatedCompletionDate
 if (project.property && project.property.build_end_date_estimate_latest) {
   estimatedCompletionDate = Moment(project.property.build_end_date_estimate_latest)
   if (!estimatedCompletionDate.isValid()) {
     estimatedCompletionDate = null
   }
 }

 let newCompletionDate = estimatedCompletionDate ? estimatedCompletionDate.toDate() : today
 if (newCompletionDate < today) {
   newCompletionDate = today
 }

 $: daysUntilComplete = estimatedCompletionDate ? Moment.duration(estimatedCompletionDate.diff(today)).asDays() : null

 $: {
   if (!daysUntilComplete || daysUntilComplete < 0) {
     contextClass = 'text-danger'
   } else if (daysUntilComplete < 2) {
     contextClass = 'text-warning'
   } else {
     contextClass = ''
   }
 }

 const selectableCallback = (date) => date >= today

 async function updateCompletionDate () {
   const response = await SFR3.projects.update(project.id, { property_attributes: { build_end_date_estimate_latest: Moment(newCompletionDate).format('YYYY-MM-DD') } })

   if (response.ok) {
     estimatedCompletionDate = Moment(newCompletionDate)
   } else {
     const data = await response.json()
     if (data.error) {
       alert(data.error)
     }
   }
   dateChosen = false
   editing = false
 }

 $: if (dateChosen) {
   updateCompletionDate()
 }
</script>

<style>
 :global(.project-completion-date .datepicker .contents-wrapper) {
   padding-top: 100px;
 }

 :global(.project-completion-date .datepicker .calendar-button) {
   width: 100%;
 }
</style>

<div class="project-completion-date {contextClass}">
  Estimated Completion
  <br/>
  {#if editing}
    <Datepicker
      bind:selected={newCompletionDate}
                    bind:dateChosen
      start={today}
      end={end}
                    {selectableCallback}
                    style="display: block"
    />
  {:else}
    <strong>{estimatedCompletionDate ? estimatedCompletionDate.format(dateFormat) : 'Not Set'}</strong>
    <span on:click={() => editing = !editing}>
      <Fa icon={faEdit} style="cursor: pointer"/>
    </span>
  {/if}
</div>
