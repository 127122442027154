<script>
 const Moment = require('moment')

 export let history
</script>

<ul>
  {#each history as event}
    <li>
      {Moment(event.date).format('YYYY-MM-DD')}: {event.event}
      {#if event.line_items}
        {#each event.line_items as line_item}
          <br/>
          - {line_item.quantity}x{line_item.name} (${line_item.cost_cents/100})
          {/each}
      {/if}
    </li>
  {/each}
</ul>
