<script>
 import { tweened } from 'svelte/motion'
 import { circIn } from 'svelte/easing'

 const Moment = require('moment')
 import { onMount } from 'svelte'

 import { formatCents } from '../../javascript/money'
 import { SFR3 } from '../../javascript/sfr3_api'

 import AttachmentSection from '../lib/attachment_section'
 import NewButton from '../manual_expenses/new_modal_button'

 export let project

 let expenses = []
 let loaded = false
 let totalCentsForHiddenExpenses = 0
 let marketId = project.market_id
 let enabled = true

 let totalCents = tweened(0, { delay: 200, duration: 1000, easing: circIn })

 $: $totalCents = expenses.reduce((acc, expense) => acc + expense.amount_cents, 0) + totalCentsForHiddenExpenses

 const loadExpenses = async () => {
   const response = await SFR3.manualExpenses.list({project_id: project.id})
   const data = await response.json()
   expenses = data.items || []
   totalCentsForHiddenExpenses = data.totalCostCents - expenses.reduce((acc, expense) => acc + expense.amount_cents, 0)
   loaded = true
 }

 const addExpense = (event) => {
   expenses = [...expenses, event.detail]
 }

 const manualExpenseEnabled = async () => {
   const data = await SFR3.manualExpenses.enabled( marketId )
   enabled = (await data.json())['manual_expense_enabled']
 }

 onMount(async () => {
  manualExpenseEnabled();
  loadExpenses();
 })
</script>

<div class="row mb-3">
  <div class="col-sm">
    <h2>Manual Expenses <small class="text-muted">{formatCents($totalCents)}</small></h2>
  </div>
  <div class="col-sm-5 text-sm-right">
    <NewButton {project} {enabled} on:created={addExpense} />
  </div>
</div>

{#if loaded}
  {#each expenses as expense (expense.id)}
    <div class="card mb-3">
      <div class="card-header">
        <div class="row">
          <div class="col col-sm-3 col-md-2 text-bold font-weight-bold">
            {Moment(expense.created_at).format('YYYY-MM-DD')}
          </div>
          <div class="col col-sm-2 order-sm-12 font-weight-bold text-right">{formatCents(expense.amount_cents)}</div>
          <div class="col-sm d-none d-sm-block">{expense.vendor.name}</div>
          <div class="col-sm d-none d-sm-block text-italic">{expense.expense_category.name}</div>
        </div>
      </div>
      <div class="card-body">
        <div class="d-sm-none font-weight-bold">{expense.vendor.name}</div>
        <div class="d-sm-none pb-2 border-bottom mb-2">{expense.expense_category.name}</div>
        <div class="font-italic">{expense.description}</div>
        <div>Creator: <em>{expense.creator.name}</em></div>
        <div>Payment: <em>{expense.payment_status}</em></div>

        <AttachmentSection files={expense.attachments} canUpload={false} titleClass="h5"/>
      </div>
    </div>
  {/each}
{/if}
