<script>
  export let sectionAttachments = []

  $: nonImages = (sectionAttachments || []).filter((a) => !a.is_image);
  $: images = (sectionAttachments || []).filter((a) => a.is_image);

  const removeImage = (image) => { 
    sectionAttachments = sectionAttachments.filter((a) => a !== image)
  }
</script>

{#if nonImages.length > 0 || images.length > 0}
  <div class="container" style="padding-bottom: 10px">
    <ul class="mb-0">
      {#each nonImages as file (file.url)}
        {#if file.filename != null}
          <li>
            <a href={file.url} target="_blank">{file.filename}</a>
          </li>
        {/if}
      {/each}
    </ul>
    <div class="row row-cols-3 row-cols-sm-4 row-cols-md-6">
      {#each images as image}
        <div class="col mt-3">
            <div class="delete-image" on:click={removeImage(image)}>X</div>
          <div class="card">
            <img src={image.url} class="img-fluid" alt="Project" />
          </div>
        </div>
      {/each}
    </div>
  </div>
{/if}

<style>
  .delete-image {
    position: absolute;
    display: flex;
    align-items: center;
    top: -7px;
    right: 7px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: indianred;
    transition: background-color .5s;
    cursor: pointer;
    justify-content: center;
    color: #fff;
    z-index: 1;
    }
    .delete-image:hover {
      background-color: orangered;
    }
</style>
