<script>
 import { createEventDispatcher } from 'svelte'
 import { Stretch } from 'svelte-loading-spinners'

 import { formatDollars } from '../../javascript/money'
 import { formatCents } from '../../javascript/money'
 import { SFR3 } from '../../javascript/sfr3_api'

 import Uploader from '../lib/uploader'

 export let lineItems = []

 let attachments = []
 let submitting = false
 let submitBlocker = null
 let selectionTotalCost = 0
 let uploader = null
 let uploadingPhotos = false
 let holdbackLineItem = false

 const dispatch = createEventDispatcher()

 $: itemsRequiringPhotos = lineItems.filter((li) => li['photo_required_to_approve?'])
 $: numAdditionalPhotosRequired = Math.max(0, itemsRequiringPhotos.length - attachments.length)
 $: additionalPhotosString = `${numAdditionalPhotosRequired} more photo${numAdditionalPhotosRequired == 1 ? '' : 's'}`
 $: holdbackLineItem = lineItems.filter((li) => li['is_holdback?'] === true).length > 0
 $: holdbackCanBeCompleted  = lineItems.filter((li) => li['is_holdback?'] === true && li['can_be_completed?'] === true).length > 0
 $: {
   if (lineItems.length === 0) {
     submitBlocker = 'Select at least one item to complete'
   } else if (uploadingPhotos) {
     submitBlocker = 'Wait for all photos to upload'
   } else if (numAdditionalPhotosRequired > 0) {
     submitBlocker = `Add at least ${additionalPhotosString}`
   } else if (holdbackLineItem && !holdbackCanBeCompleted) {
     submitBlocker = 'Selected line items cannot be completed'
   } else {
     submitBlocker = null
   }
 }
 $: selectionTotalCost = lineItems.reduce((subTotal, {final_cost_cents}) => subTotal + final_cost_cents, 0)

 const createWorkApproval = async () => {
   submitting = true
   try {
     const response = await SFR3.workApprovals.create(
       lineItems.map((item) => item.id),
       attachments
     )

     if (response.ok) {
       const parsedResponse = await response.json()

       for (let updatedLineItem of parsedResponse.work_approval.line_items) {
         const lineItem = lineItems.find((li) => li.id === updatedLineItem.id)
         if (lineItem) {
           const newStatus = updatedLineItem.status
           lineItem.status = newStatus
           lineItem['done?'] =
             ['approved', 'cancelled', 'rejected', 'completed'].indexOf(newStatus) > -1
         }
       }

       // Alert the parent component that the line items have been updated
       dispatch('created', parsedResponse.work_approval)

       // Reset the list of line items
       lineItems = []

       // Reset attachments
       uploader && uploader.clear()
     } else if (response.status === 403) {
        return alert('Planner is live, ping market ACE for help')
     } else {
       try {
         const parsedResponse = await response.json()
         alert(parsedResponse.error)
       } catch {
         alert('Unknown error when submitting - contact Tech Support')
       }
     }

   } catch (e) {
     alert('Error when submitting - contact Tech Support')
   } finally {
     submitting = false
   }
 }

 const unassignLineItems = async() => {
  submitting = true
   try {
     const response = await SFR3.lineItems.unassignLineItems(
       lineItems[0].work_order_id,
       lineItems.map((item) => item.id)
     )

     if (response.ok) {
        const parsedResponse = await response.json()

        for (let updatedLineItem of lineItems) {
         const lineItem = updatedLineItem
         if (lineItem) {
           lineItem.status = 'unassigned'
           lineItem.work_order_id = null
         }
       }

        dispatch('unassign', parsedResponse.work_order)

        lineItems = []

        uploader && uploader.clear()
     } else {
       try {
         const parsedResponse = await response.json()
         alert(parsedResponse.error)
       } catch {
         alert('Unknown error when submitting - contact Tech Support')
       }
     }

   } catch (e) {
     alert('Error when submitting - contact Tech Support')
   } finally {
     submitting = false
   }
 }
</script>

<Uploader bind:attachments bind:this={uploader} bind:uploading={uploadingPhotos}/>

{#if selectionTotalCost > 0}
  <div class="row mt-2 mb-2">
    <div class="col font-weight-bold text-left">
      Selected total:
      <div class="d-inline text-muted">
        {formatCents(selectionTotalCost)}
      </div>
    </div>
  </div>
{/if}

{#if submitting}
  <div class="text-center">
    <Stretch color="#999" />
  </div>
{:else}

  {#if lineItems.length === 0}
    <button disabled class="btn btn-primary">{submitBlocker}</button>
  {:else}
    {#if submitBlocker }
      <button disabled class="btn btn-primary">{submitBlocker}</button>
    {:else}
      <button on:click={createWorkApproval} class="btn btn-primary">
        Complete
        {lineItems.length ? lineItems.length : ''}
        Line Item{lineItems.length === 1 ? '' : 's'}
      </button>
    {/if}
    {#if !holdbackLineItem}
      <hr/>
      <div>
        <button on:click={unassignLineItems} class="btn btn-warning mb-2">
          Unassign
          {lineItems.length ? lineItems.length : ''}
          Line Item{lineItems.length === 1 ? '' : 's'}
        </button>
      </div>
    {/if}
    {#if numAdditionalPhotosRequired > 0}
      <div class="alert alert-warning text-left mt-1">
        <strong>Upload {additionalPhotosString}</strong>
        <ul class="mt-1">
          {#each itemsRequiringPhotos as item (item.id)}
            <li>{item.name}</li>
          {/each}
        </ul>
      </div>
    {/if}
    {#if holdbackLineItem && !holdbackCanBeCompleted}
      <div class="alert alert-warning text-left mt-1">
        <strong>Retainage item selected</strong>
        <ul class="mt-1">
          <li>All Line Items must be completed prior to completing the retainage item</li>
        </ul>
      </div>
    {/if}
  {/if}
{/if}
