<script>
 import { onMount } from 'svelte'
 import { writable, derived } from 'svelte/store'
 import { slide } from 'svelte/transition'
 import CancelLineItemForm from './cancel_line_item_form'

 export let cancellableLineItems = []
 export let lineItemsToCancel = []
 export let cancellationRequestedItems = []
 export let update = false

 // Create stores for selected and unselected line items in order to coordinate options between
 // multiple selects
 let selectedLineItemsBase = writable([])
 const selectedLineItems = {
   subscribe: selectedLineItemsBase.subscribe,
   add: (lineItem) => {
     if (lineItem) {
       selectedLineItemsBase.update(arr => [...arr, lineItem])
     }
   },
   remove: (lineItem) => {
     let idx = $selectedLineItemsBase.indexOf(lineItem)
     if (idx >= 0) {
       selectedLineItemsBase.update((arr) => {
         return [...arr.slice(0, idx), ...arr.slice(idx+1, arr.length)]
       })
     }
   }
 }

 const unselectedLineItems = derived(
   selectedLineItems,
   $selectedLineItems => cancellableLineItems.filter(li => !$selectedLineItems.includes(li))
 )

 $: lineItemsToCancel = [...$selectedLineItems]
 
 // Manage rows in the form
 let rows = []
 let nextRowKey = 1

 const addRow = () => {
   if (rows.length < cancellableLineItems.length) {
     rows = [...rows, { key: nextRowKey }]
     nextRowKey = nextRowKey + 1
   }
 }

 const deleteRow = (row) => {
   let idx = rows.indexOf(row)
   if (idx >= 0) {
     rows = [...rows.slice(0, idx), ...rows.slice(idx+1, rows.length)]
   }
 }

 const loadRows = async () => {
  if (cancellationRequestedItems.length > 0) {
      for (let i = 0; i < cancellationRequestedItems.length; i++) {
        rows = [...rows, { key: nextRowKey }]
        nextRowKey = nextRowKey + 1
      }
    }
 }
 onMount(async () => loadRows())

 const disable_button = () => {
   if (rows.length >= cancellableLineItems.length || update == true ) {
     return true
   } else {
     return false
   }
 }

</script>

<style>
 .action-container {
   flex: 0 0 4%;
   max-width: 4%;
   position: relative;
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
 }

 .action {
   cursor: pointer;
 }
</style>

<div class="row my-2">
  <div class="col text-right">
    <button class="btn btn-outline-danger" on:click={addRow} disabled={disable_button()}>
      - Cancel Line Item
    </button>
  </div>
</div>

{#each rows as row (row.key)}
  <div class="row" transition:slide>
    <div class="action-container text-center d-flex flex-column justify-content-center">
      <span class="action" on:click={() => deleteRow(row)}>❌</span>
    </div>
    <div class="col">
      {#if cancellationRequestedItems.length > 0 && cancellationRequestedItems[row.key - 1]}
        <CancelLineItemForm 
          bind:cancellationRequestedItem={cancellationRequestedItems[row.key - 1]} 
          {selectedLineItems} 
          {unselectedLineItems}
          {update}
          />
      {:else}
        <CancelLineItemForm {selectedLineItems} {unselectedLineItems} {update}></CancelLineItemForm>
      {/if}
    </div>
  </div>
{/each}
