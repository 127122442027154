<script>
 import { formatCents } from '../../javascript/money'
 import QiTable from '../lib/qi_table'

 export let lineItems = []
 export let csvName = 'Sheet'

 const columns = [
   { title: 'Vendor', attr: 'vendor_name' },
   { title: 'Task', attr: 'name', type: 'longtext' },
   { title: 'Quantity', attr: 'quantity', type: 'int' },
   { title: 'Section', attr: 'section' },
   { title: 'Status', attr: 'status' },
   { title: 'Labor Cost', attr: 'cost_cents', type: 'money' },
   { title: 'Work Order ID', attr: 'work_order_id', type: 'int' },
   { title: 'Category', attr: 'category' }
 ]

 let csvUri = ''
</script>

<div class="text-right my-3">
  <a class="btn btn-primary btn-sm" href="{csvUri}" download="{csvName}.csv">
    Download Line Item Spreadsheet
  </a>
</div>

<QiTable {columns} objects={lineItems} bind:csvUri selectable={true}></QiTable>
