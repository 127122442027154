<script>
  import { SFR3 } from "../../javascript/sfr3_api";
  import { onMount } from "svelte";
  import { tweened } from "svelte/motion";
  import { bounceOut } from "svelte/easing";
  import { createEventDispatcher } from "svelte";

  let marketsList;
  let arrowMarketRotation = tweened(0, { duration: 700, easing: bounceOut });

  export let marketIds;
  export let hiddenMarketFilter;
  export let projectId;
  export let filteredReset;

  const dispatch = createEventDispatcher();

  const toggleMarketDisplay = () => {
    hiddenMarketFilter = !hiddenMarketFilter;
    dispatch("toggleHiddenFilter", hiddenMarketFilter);
    updateArrowRotation();
  };

  const updateArrowRotation = () => {
    if (!hiddenMarketFilter) {
      $arrowMarketRotation = 90;
    } else {
      $arrowMarketRotation = 0;
    }
  };

  // Load Markets
  onMount(async () => {
    const response = await SFR3.markets.list();
    if (response.ok) {
      marketsList = (await response.json()).markets;
    }

    updateArrowRotation();
  });

  const handleClick = (event) => {
    event.target.checked
      ? marketIds.push(event.target.value)
      : marketIds.splice(marketIds.indexOf(event.target.value), 1);

    updateStore();
  };

  function updateStore() {
    dispatch("marketsUpdated", marketIds);
  }

  const filterByMarket = () => {
    projectId = "";
    filteredReset();
  };

  const cleanFilterByMarket = () => {
    marketsList.forEach((market) => {
      unCheckedMarket(market.id);
    });
    if (marketIds.length != 0) {
      marketIds = [];
      filteredReset();
    }

    updateStore();
  };

  const marketChecked = (marketId) => {
    return marketIds.includes(marketId.toString());
  };

  const unCheckedMarket = (marketId) => {
    const market = document.getElementById(marketId);
    market.checked = false;
  };
</script>

<div class="col-sm text-left">
  <div>
    <div class="h5 mt-3" style="cursor: pointer" on:click={toggleMarketDisplay}>
      Search by Market <span
        style="display: inline-block; transform: rotate({$arrowMarketRotation}deg);"
        >&#x25B6;</span
      >
    </div>
    {#if !hiddenMarketFilter}
      <div class="mt-3">
        <div
          style="border:1px solid #ced4da; border-radius: 0.25rem; padding: 0.375rem 0.25rem"
        >
          {#if marketsList == null}
            Loading...
          {:else}
            {#each marketsList as market}
              <div style="display: inline-block; width: 60px;">
                <input
                  id={market.id}
                  type="checkbox"
                  value={market.id}
                  on:click={handleClick}
                  checked={marketChecked(market.id)}
                />
                <label for={market.id}>{market.code}</label>
              </div>
            {/each}
          {/if}
        </div>

        <button
          class="btn btn-primary btn-sm"
          style="font-size: 1rem; padding: 0.3rem 0.6rem; margin-top: 10px;"
          on:click={filterByMarket}
        >
          Apply Filter by Markets
        </button>
        <button
          class="btn btn-secondary btn-sm"
          style="font-size: 1rem; padding: 0.3rem 0.6rem; margin-top: 10px;"
          on:click={cleanFilterByMarket}
        >
          Clean Filter by Markets
        </button>
      </div>
    {/if}
  </div>
</div>
