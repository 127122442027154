<script>
 import { createEventDispatcher } from 'svelte'
 const dispatch = createEventDispatcher()

 export let column = {}

 column._qt_show = (cellData) => !filterFloat || isNaN(filterFloat) || currentFilter(cellData)

 let filterText = ''
 let currentFilter = (cellData) => true
 let filterFloat

 const OPERATOR_FILTERS = {
   '<': (cellData) => cellData < filterFloat,
   '<=': (cellData) => cellData <= filterFloat,
   '>': (cellData) => cellData > filterFloat,
   '>=': (cellData) => cellData >= filterFloat,
   '==': (cellData) => cellData == filterFloat
 }

 $: {
   filterText = filterText.trim()
   filterFloat = parseFloat(filterText.replace(/[^0-9.]+/g, ''))

   const operator = filterText[1] === '=' ? filterText.slice(0, 2) : filterText[0]
   currentFilter = OPERATOR_FILTERS[operator] || OPERATOR_FILTERS['==']

   dispatch('change')
 }
</script>

<input
  class="form-control form-control-sm"
  bind:value={filterText}/>
