<script>
  import { slide } from 'svelte/transition'
  import Fa from 'svelte-fa'
  import { faSort } from '@fortawesome/free-solid-svg-icons'
  import { SFR3 } from '../../javascript/sfr3_api'

  export let project

  let deferProject = project.defer_this_project

  const deferThisProject = async () => {
    let confirmed = await confirm("Are you sure you want to toggle deferral for this project?")
    if (confirmed) {
      deferProject = !project.defer_this_project
      const response = await SFR3.projects.update(project.id, { defer_this_project: deferProject })
      if (response.ok) {
        const data = await response.json()
        if (data.project.defer_this_project != deferProject) {
          alert('Project could not be deferred')
        }
      } else {
        try {
          const data = await response.json()
          alert(data.error)
        } catch {
          alert("Unknown error when deferring project")
        }
        return
      }
      window.location.reload()
    } else {
      return
    }
  }
</script>

<style>
  .btn {
    cursor: pointer;
  }
 </style>

<div class="col mb-2">
  <div class="row">
    <div class="col-4">
      <strong>Defer Project</strong>
    </div>
    <div class="col">
        <div transition:slide>
          <span class="btn btn-sm btn-outline-dark mr-1" on:click={deferThisProject}>
            <Fa icon={faSort} />
          </span>
          {project.defer_this_project ? "Deferred": "Not deferred"}
        </div>
    </div>
  </div>
</div>
