<script>
 import LineItemTable from '../line_items/table'
 import LineItemHistory from './history'

 export let workOrder
 let selectedTab = 'line_items'
</script>

<style>
</style>

<div class="card">
  <div class="card-header">
    <div class="nav nav-tabs card-header-tabs">
      <div class="nav-item">
        <div class="nav-link user-select-none {selectedTab === 'line_items' ? 'active' : ''}"
             on:click={() => selectedTab = 'line_items'}>
          Line Items
        </div>
      </div>
      <div class="nav-item">
        <div class="nav-link user-select-none {selectedTab === 'history' ? 'active' : ''}"
             on:click={() => selectedTab = 'history'}>
          History
        </div>
      </div>
    </div>
  </div>

  <div class="card-body">
    {#if selectedTab === 'line_items'}
      <LineItemTable lineItems={workOrder.lineItems} />
    {:else if selectedTab = 'history'}
      <LineItemHistory history={workOrder.history} />
    {/if}
  </div>
</div>
