<script>
  import { flip } from "svelte/animate";

  import { formatCents } from "../../javascript/money";

  import ExpandableText from "../lib/expandable_text";

  export let lineItems = [];
  export let group; // If this is not assigned a value, this is not an interactive table
  export let showQuantity = false;
  export let showStatus = true;
  export let selectable = (item) => item["status"] === "completed";
  export let filterable = true;
  export const clearSearch = () => (search = "");

  let selectedAll = false;
  let search = "";

  const visibleFilter = (item) => {
    if (!filterable) {
      return true;
    }

    return (
      !!item.name.toLowerCase().match(search.toLowerCase()) ||
      !!(item.category || "").toLowerCase().match(search.toLowerCase()) ||
      !!(item.section || "").toLowerCase().match(search.toLowerCase())
    );
  };

  $: visibleLineItems = lineItems;
  $: selectableLineItems = visibleLineItems.filter(selectable) || [];
  $: group = (search || true) && group && group.filter(visibleFilter);
  $: selectedAll =
    group &&
    selectableLineItems.length > 0 &&
    group.length == selectableLineItems.length;

  const toggleSelectAll = () => {
    selectedAll = !selectedAll;
    if (selectedAll) {
      group = visibleLineItems.filter(selectable);
    } else {
      group = [];
    }
  };
</script>

<table class="table table-sm table-striped table-borderless mb-0">
  {#if group && (lineItems.filter(selectable).length > 1 || filterable)}
    <thead>
      <tr>
        <td class="align-middle pl-3">
          <input
            type="checkbox"
            checked={selectedAll}
            on:change={toggleSelectAll}
          />
        </td>
        <td colspan="8">
          <input
            bind:value={search}
            class="form-control"
            placeholder="Search..."
          />
        </td>
      </tr>
    </thead>
  {/if}
  <tbody>
    {#each lineItems as item (item.id)}
      <tr animate:flip={{ duration: 400 }}>
        {#if group}
          <td class="align-middle pl-3">
            <input
              type="checkbox"
              bind:group
              value={item}
              disabled={!selectable(item)}
            />
          </td>
        {/if}

        {#if showStatus}
          <td class="align-middle {group ? '' : 'pl-3'}">
            {#if item["status"] == "provisional"}
              <span class="badge badge-info">Provisional</span>
            {:else if item["status"] == "rejected"}
              <span class="badge badge-dark">Rejected</span>
            {:else if item["status"] == "unassigned"}
              <span class="badge badge-warning">Unassigned</span>
            {:else if item["status"] == "assigned"}
              <span class="badge badge-primary">In Progress</span>
            {:else if item["status"] == "completed"}
              <span class="badge badge-info">Completed</span>
              <div class="row font-weight-light">
                <div class="col-md text-left">
                  Marked complete by: <em>{item.marked_completed_by}</em>
                </div>
              </div>
            {:else if item["status"] == "approved"}
              <span class="badge badge-success">Approved</span>
              <div class="row font-weight-light">
                <div class="col-md text-left">
                  Marked complete by: <em>{item.marked_completed_by}</em>
                </div>
                <div class="col-md text-left">
                  Approved by: <em>{item.approved_by}</em>
                </div>
              </div>
            {:else if item["status"] == "finished_internal" }
              <span class="badge status-internal">Finished (Internal)</span>
              <div class="row font-weight-light">
                <div class="col-md text-left">
                  Marked complete by: <em>{item.marked_completed_by}</em>
                </div>
                <div class="col-md text-left">
                  Approved by: <em>{item.approved_by}</em>
                </div>
              </div>
            {:else if item["status"] == "cancellation_requested"}
              <span class="badge badge-secondary">Cancellation Requested</span>
            {:else if item["status"] == "cancelled"}
              <span class="badge badge-dark">Cancelled</span>
            {:else}
              <span class="badge badge-danger">Error</span>
            {/if}
          </td>
        {/if}

        <td class="align-middle text-right"
          >{formatCents(item.final_cost_cents)}</td
        >

        <td class="align-middle" style="width: 30%">
          <ExpandableText
            text={(item.materials.length > 0 ? "LABOR ONLY: " : "") + item.name}
            hasDetails={item.vendor_instructions ||
              item.issue_description ||
              item.custom_standard ||
              item.materials.length > 0}
          >
            {#if item.vendor_instructions}
              <br />Vendor Instructions: {item.vendor_instructions}<br />
            {/if}
            {#if item.issue_description}
              <br />Issue Description: {item.issue_description}<br />
            {/if}
            {#if item.custom_standard}
              <br />Custom Standards: {item.custom_standard}<br />
            {/if}
            <ul>
              {#each item.materials as material}
                <li>
                  {material.name}: {material.quantity} x {formatCents(
                    material.unit_cost
                  )} = {formatCents(material.total_cost)}
                </li>
              {/each}
            </ul>
          </ExpandableText>
        </td>

        {#if showQuantity}
          <td class="align-middle text-right">x{item.quantity}</td>
        {/if}

        <td class="align-middle pr-3">
          {#if item.section}
            <div class="badge badge-secondary badge-pill search-tag">
              {item.section}
            </div>
          {/if}
          {#if item.category}
            <div class="badge badge-info badge-pill search-tag">
              {item.category}
            </div>
          {/if}
          {#if item.planner_category}
            <div class="badge badge-info badge-pill search-tag">
              {item.planner_category}
            </div>
          {/if}
        </td>
      </tr>
    {/each}
  </tbody>
</table>
