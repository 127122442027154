<script>
  import { slide } from "svelte/transition";
  import Fa from "svelte-fa";
  import { faEdit } from "@fortawesome/free-solid-svg-icons";
  import Select from "svelte-select";

  import { SFR3 } from "../../javascript/sfr3_api";

  export let project = { id: null, market_id: null };
  export let rmProfile;
  export let editable = false;

  let editing = false;
  let rmsLoaded = false;
  let rmOptions = [];
  let currentRmId = rmProfile ? rmProfile.id : null;

  rmProfile = rmProfile || {
    id: null,
    user: { name: null },
    md_profile: { user: { name: null } },
  };
  rmProfile.label = (rmProfile.user && rmProfile.user.name) || "Choose an PM";

  $: rmName = (rmProfile.user && rmProfile.user.name) || "TBD";
  $: if (editable && !rmsLoaded) {
    loadRMs();
  }
  $: if (editable && rmProfile.id != currentRmId) {
    saveRm();
  }

  const toggle = () => {
    editing = editable && !editing;
  };

  const saveRm = async () => {
    if (!rmProfile.id) {
      alert("Error: attempting to save a blank pmProfile id");
      return;
    }

    const response = await SFR3.projects.update(project.id, {
      rm_profile_id: rmProfile.id,
    });
    if (response.ok) {
      const data = await response.json();
      if (data.project.rm_profile_id === rmProfile.id) {
        currentRmId = data.project.rm_profile_id;
      } else {
        alert("PM was not saved");
      }
    } else {
      try {
        const data = await response.json();
        alert(data.error);
      } catch {
        alert("Unknown error when saving PM");
      }
      return;
    }

    editing = false;
  };

  async function loadRMs() {
    const response = await SFR3.rmProfiles.list(project.market_id);
    if (response.ok) {
      rmOptions = (await response.json()).items.map((profile) => {
        const option = {
          ...profile,
          label: profile.user.name,
        };
        if (profile.id == rmProfile.id) {
          rmProfile = option;
        }
        return option;
      });
    } else {
      alert("Error loading PMs");
    }
    rmsLoaded = true;
  }
</script>

<div class="col mb-2">
  <div class="row">
    <div class="col-4"><strong>PM</strong></div>
    <div class="col">
      {#if editing}
        <div transition:slide>
          <Select
            items={rmOptions}
            bind:value={rmProfile}
            optionIdentifier="id"
            isClearable={false}
          />
          <div class="mt-2">
            <button class="btn btn-sm btn-danger" on:click={toggle}
              >Cancel</button
            >
          </div>
        </div>
      {:else}
        <div transition:slide>
          {#if editable}
            <span class="btn btn-sm btn-outline-dark mr-1" on:click={toggle}>
              <Fa icon={faEdit} />
            </span>
          {/if}

          {rmName}
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  .btn {
    cursor: pointer;
  }
</style>
