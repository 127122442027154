<script>
 import { createEventDispatcher } from 'svelte'
 const dispatch = createEventDispatcher()

 import { SFR3 } from '../../../javascript/sfr3_api'
 import Datepicker from 'svelte-calendar'
 import FormGroup from '../../lib/form_group'
 const moment = require('moment')

 export let milestone

 let startDate = moment(milestone.start_date).toDate()
 let endDate = moment(milestone.end_date).toDate()

 const originalStartDate = startDate
 const originalEndDate = endDate

 const today = moment().toDate()
 let earliestDate = today < startDate ? today : startDate
 let latestDate = moment(today > startDate ? today : startDate).add(3, 'months').toDate()

 $: if (startDate && endDate && endDate <= startDate) {
   endDate = moment(startDate).add(1, 'day').toDate()
 }

 // Validation & Submitting
 let validationOn = false
 let submitting = false
 let startDateError, endDateError
 const required = (v) => v ? null : 'Required'
 $: canSubmit = !(startDateError || endDateError)
 $: submitDisabled = validationOn && !canSubmit

 export const submit = async () => {
   if (originalStartDate == startDate && originalEndDate == endDate) {
     dispatch('done')
   }

   if (!canSubmit) {
     validationOn = true
     return
   }

   submitting = true

   const response = await SFR3.projects.milestones.changeDates(
     milestone.id,
     milestone.project_id,
     startDate,
     endDate
   )

   if (response.ok) {
     const data = await response.json()
     dispatch('done')
     dispatch('datesChanged', { milestone: data.milestone })
     milestone = {
       ...milestone,
       start_date: startDate,
       end_date: endDate
     }
   } else {
     try {
       const data = await response.json()
       createError = data.error
     } catch (e) {
       createError = 'Unknown error while changing dates'
     }
   }

   submitting = false
 }
</script>

<style>
 :global(.contents-wrapper.visible) {
   position: fixed !important;
 }
</style>

<div class="row">
  <div class="col-sm">
    <FormGroup label="Start Date" let:hasError
               bind:value={startDate} bind:validationOn
               bind:error={startDateError} calcError={required}>
      <Datepicker
        start={earliestDate} end={latestDate}
        bind:selected={startDate} style="display: block;"
      />
    </FormGroup>
  </div>
  <div class="col-sm">
    <FormGroup label="End Date" let:hasError
               bind:value={endDate} bind:validationOn
               bind:error={endDateError} calcError={required}>
      <Datepicker
        start={startDate ? startDate : earliestDate} end={latestDate}
        bind:selected={endDate} style="display: block;"
      />
    </FormGroup>
  </div>
</div>

<div class="row">
  <div class="col">
    <button class="btn btn-danger btn-block" on:click={() => { dispatch('done') }}>
      Cancel
    </button>
  </div>
  <div class="col">
    <button on:click={submit} class="btn btn-success btn-block">Save</button>
  </div>
</div>
