<script>
 import { slide } from 'svelte/transition'
 import Fa from 'svelte-fa'
 import { faEdit } from '@fortawesome/free-solid-svg-icons'
 import Select from 'svelte-select'
 import { formatCents } from '../../javascript/money'
 import AttachmentSection from '../lib/attachment_section'
 import LineItemTable from '../line_items/table'
 import EditChangeOrderButton from '../change_orders/edit_modal_button'
import { SFR3 } from '../../javascript/sfr3_api';

 export let project
 export let changeOrder

 let showDetails = false
 let editing = false
 let currentApprovalReason = changeOrder.approval_reason
 let approvalReason

 const capitalize = (s) => {
   if (typeof s !== 'string') return s
   return s.charAt(0).toUpperCase() + s.slice(1)
 }

 const onUpdate = () => {
    window.location.reload();
  };

  const toggleEditing = () => {
    editing = !editing
  }

  const approvalReasonOptions = [
   'Missed',
   'Pricing',
   'Rework',
   'Opportunistic',
   'Unforeseen',
   'Rescope',
   'Savings',
   'Appliance Shortage',
   'Emergency Work'
 ]

 const updateApprovalReason = async () => {
   const response = await SFR3.changeOrders.updateApprovalReason(changeOrder.id, approvalReason)
   if (response.ok) {
     const data = await response.json()
     currentApprovalReason = data.change_order.approval_reason
   } else {
     try {
       const data = await response.json()
       alert(data.error)
     } catch {
       alert("Unknown error when saving approval reason")
     }
     return
   }
   toggleEditing()
   onUpdate()
 }

 $: lineItemsToAdd = changeOrder.lineItemsToAdd
 $: lineItemsToCancel = changeOrder.lineItemsToCancel
 $: sections = Array.from(new Set(lineItemsToCancel.map((li) => capitalize(li.section)))).filter((o) => o)
 $: default_categories = Array.from(new Set([...lineItemsToAdd, ...lineItemsToCancel].map((li) => capitalize(li.category)))).filter((o) => o)
 $: planner_categories = Array.from(new Set([...lineItemsToAdd, lineItemsToCancel].map((li) => capitalize(li.planner_category)))).filter(planner_category => planner_category)
 $: categories = default_categories.concat(planner_categories)
</script>

<div class="row">
  <div class="col-md-8">
    <h5 class="mb-0 font-weight-light">
      <span style="cursor: pointer;" on:click={() => showDetails = !showDetails}>
        {#if showDetails}&#x25BC;{:else}&#x25B6;{/if}
      </span>

      <span class="font-weight-bold">{formatCents(changeOrder.costCents)}</span>

      {#each categories as category}
        <span class="badge badge-pill badge-info mx-1">{category}</span>
      {/each}{#each sections as section}
        <span class="badge badge-pill badge-secondary mx-1">{section}</span>
      {/each}
    </h5>
  </div>
  <div class="col-md text-right font-weight-bold">{changeOrder.created_date}</div>
</div>

<div class="row font-weight-light">
  <div class="col-md">Submitted By: <em>{changeOrder.creator}</em></div>
  {#if changeOrder.reviewer_name}
    <div class="col-md text-right">Reviewer: <em>{changeOrder.reviewer_name}</em></div>
  {/if}
</div>

{#if showDetails}
  <div transition:slide class="my-3">
    <EditChangeOrderButton {project} {changeOrder} on:updated={onUpdate} />
    <div class="row mb-2">
      <div class="col">
        {#if changeOrder.description}
          <h5>Note</h5>
          <div class="font-italic">{changeOrder.description}</div>
        {/if}
      </div>
      {#if changeOrder.rejection_reason && changeOrder.status == 'rejected'}
        <div class="col-5 alert alert-danger" style="margin: 1rem 1rem 0 0;">
          <strong>Rejection Reason</strong>
          <div>{changeOrder.rejection_reason}</div>
        </div>
      {/if}

      {#if changeOrder.revision_reason && changeOrder.status == 'revision_needed'}
      <div class="col-5 alert alert-secondary" style="margin: 1rem 1rem 0 0;">
        <strong>Revision Reason</strong>
        <div>{changeOrder.revision_reason}</div>
      </div>
      {/if}

      {#if changeOrder.approval_reason && changeOrder.status == 'approved'}
      <div class="col-4 alert alert-success">
          <div>
            <strong>Approval Reason</strong>
            {#if editing}
              <div transition:slide>
                <Select items={approvalReasonOptions} bind:value={approvalReason} on:select={updateApprovalReason}
                        optionIdentifier="id" isClearable={false} />
                <div class="mt-2">
                  <button class="btn btn-sm btn-danger" on:click={toggleEditing}>Cancel</button>
                </div>
              </div>
            {:else}
              <div class="col-md-12 text-right" transition:slide>
                <span class="btn btn-sm btn-outline-dark mr-1" on:click={toggleEditing}>
                  <Fa icon={faEdit} />
                </span>
              </div>
            {/if}
          </div>
        <div>{currentApprovalReason}</div>
      </div>
    {/if}

    </div>

    <AttachmentSection files={[ ...changeOrder.attachments, ...changeOrder.line_items_attachments ]} canUpload={false} titleClass="h5" />

    {#if changeOrder.lineItemsToAdd.length > 0}
      <div class="mt-2">
        <h5>
          {changeOrder.lineItemsToAdd.length} Line Item{changeOrder.lineItemsToAdd.length == 1 ? '' : 's'} Added
        </h5>
      </div>
      <LineItemTable lineItems={changeOrder.lineItemsToAdd} showQuantity={true}/>
    {/if}

    {#if changeOrder.lineItemsToCancel.length > 0}
      <div class="mt-2">
        <h5>
          {changeOrder.lineItemsToCancel.length} Line Item{changeOrder.lineItemsToCancel.length == 1 ? '' : 's'} Cancelled
        </h5>
      </div>

      <LineItemTable lineItems={changeOrder.lineItemsToCancel} showQuantity={true}/>
    {/if}
  </div>
{/if}
