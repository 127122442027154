<script>
 import { createEventDispatcher } from 'svelte'
 const dispatch = createEventDispatcher()

 export let column = {}

 column._qt_show = (cellData) => {
   if (!filterText || filterText === '') { return true }
   if (!cellData) { return false }
   if (!cellData.match) { return false }
   return cellData.toLowerCase().match(filterText)
 }

 let value
 let filterText = '' // This could be filterRegex some day...

 $: {
   filterText = value && value.trim().toLowerCase()
   dispatch('change')
 }
</script>

<input
  class="form-control form-control-sm"
  bind:value/>
