<script>
  import { onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { tweened } from "svelte/motion";
  import { bounceOut } from "svelte/easing";

  import Uploader from "./uploader";
  import Modal from "./modal.svelte";
  import Carousel from "./carousel.svelte";

  // Each file should have the format { filename:, url:, is_image: }
  export let files = [];
  export let title = "Attachments";
  export let canUpload = false;
  export let canRemove = false;
  export let canHide = !canUpload;
  export let titleClass = "h2";
  export let forceShow = false;
  export let showDialog = false;

  // Uploading
  export let newFiles = [];
  export let uploading = false;
  export let clearFiles = () => {};

  export let selectedUrl;
  let hidden;

  if (forceShow) {
    hidden = false
  } else {
    hidden = canHide
  }

  let arrowRotation = tweened(0, { duration: 700, easing: bounceOut });

  $: nonImages = (files || []).filter((a) => !a.is_image);
  $: images = (files || []).filter((a) => a.is_image);

  const toggleDisplay = () => {
    hidden = !hidden;
    $arrowRotation = $arrowRotation > 0 ? 0 : 90;
  };

  const removeImage = (image) => {
    files = files.filter((a) => a !== image);
  };

  const showCarousel = (image) => {
    selectedUrl = image.url;
    showDialog = true;
  }

  onMount(async () => {
    // Preload images so when the user opens the section for the first time, they load smoothly
    for (const image of images) {
      new Image().src = image.url;
    }
  });

  const line_item_name = (image) => {
    return image.line_item_name.substring(0, 21);
  }
</script>

{#if canUpload || nonImages.length > 0 || images.length > 0}
  <div
    class="{titleClass} mt-3"
    style="cursor: pointer"
    on:click={toggleDisplay}
  >
    {title}
    {#if nonImages.length > 0 || images.length > 0}
      ({nonImages.length + images.length})
    {/if}
    {#if canHide}
      <span
        style="display: inline-block; transform: rotate({$arrowRotation}deg);"
        >&#x25B6;</span
      >
    {/if}
  </div>

  {#if !hidden}
    <div class="container" transition:slide>
      <ul class="mb-0">
        {#each nonImages as file (file.url)}
          {#if file.filename != null}
            <li>
              <a href={file.url} target="_blank">{file.filename}</a>
            </li>
          {/if}
        {/each}
      </ul>

      <div class="row row-cols-3 row-cols-sm-4 row-cols-md-6">
        {#each images as image}
          <div class="col mt-3">
            {#if canRemove}
              <div class="delete-image" on:click={removeImage(image)}>X</div>
            {/if}
            <div class="card">
              <img src={image.url} class="img-fluid" alt="Project" on:click={showCarousel(image)} />
              <div>{line_item_name(image)}</div>
            </div>
          </div>
        {/each}
      </div>

      <Modal bind:showDialog>
        <Carousel 
          bind:images={images}
          bind:selectedUrl={selectedUrl}
        />
      </Modal>

      {#if canUpload}
        <div class="mt-3">
          <Uploader
            bind:attachments={newFiles}
            bind:uploading
            bind:clearFiles
          />
        </div>
      {/if}
    </div>
  {/if}
{/if}

<style>
  .delete-image {
    position: absolute;
    display: flex;
    align-items: center;
    top: -7px;
    right: 7px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: indianred;
    transition: background-color .5s;
    cursor: pointer;
    justify-content: center;
    color: #fff;
    z-index: 1;
    }
    .delete-image:hover {
      background-color: orangered;
    }
</style>
