<script>
 import { onMount } from 'svelte'
 import { slide } from 'svelte/transition'
 import { tweened } from 'svelte/motion'
 import { bounceOut } from 'svelte/easing'

 import { SFR3 } from '../../javascript/sfr3_api'
 import Permissions from '../../javascript/permissions'
 import { formatCentsRound } from '../../javascript/money'

 import Gantt from '../lib/gantt'
 import Modal from '../lib/modal'

 import NewMilestoneButton from './milestones/new_modal_button'
 import CreateWorkOrdersButton from './milestones/create_work_orders_button'
 import ShowMilestone from './milestones/show'
 import MobileRow from './milestones/mobile_row'

 const Moment = require('moment')

 export let project // { id: X, target_date: X }

 let CAN = {} // Permissions

 let vendors = []
 let milestones = []

 let gantt
 let createWorkOrdersButton

 $: targetMoment = project.target_date && Moment(project.target_date)

 // Update vendors list from milestones
 $: {
   for (let m of milestones) {
     if (!vendors.find((v) => v.id == m.vendor.id)) {
       vendors = [...vendors, m.vendor]
     }
   }
 }

 $: milestones.sort((a, b) => a.end_date < b.end_date ? -1 : 1)

 $: rows = vendors.map((vendor) => {
   const vendorMilestones = milestones.filter((m) => m.vendor.id == vendor.id)
   const milestoneRows =
     vendorMilestones
       .map((m) => {
         let cents_per_day = m.cents_per_day ? `(${formatCentsRound(m.cents_per_day)}/day)` :  ''

         return {
           id: `${vendor.id}-${m.id}`,
           label: `${m.name} ${cents_per_day}` ,
           enableDragging: CAN.edit_milestones
         }
       })

   const vendorTotalCents =
     vendorMilestones
       .reduce((prev, curr) => prev + curr.total_cent_cost, 0)

   const vendorStartDate = () => {
     var minStartDate = vendorMilestones[0].start_date
     vendorMilestones
       .forEach(milestone => {
         if (milestone.start_date < minStartDate) {
           minStartDate = milestone.start_date
         }
       })
     return minStartDate
   }

   const vendorEndDate = () => {
     var maxEndDate = vendorMilestones[0].end_date
     vendorMilestones
        .forEach(milestone => {
          if (milestone.end_date > maxEndDate) {
            maxEndDate = milestone.end_date
          }
        })
     return maxEndDate
    }

   const vendorDatePeriod = () => {
     return Math.floor((Date.parse(vendorEndDate()) - Date.parse(vendorStartDate())) / 86400000 + 1)
    }

   return {
     id: vendor.id,
     classes: [],
     enableDragging: CAN.edit_milestones,
     label: `${vendor.name} (${formatCentsRound(vendorTotalCents / vendorDatePeriod())}/day) `|| 'Unknown',
     children: milestoneRows,
     vendor: vendor,
     age: 10 // What is age?
     // contentHtml: '',
     // headerHtml: ''
   }
 })

 $: tasks = milestones.map((milestone) => {

   const doneItems = milestone.line_items.filter((li) => li['done?'])
   const pctDone = 100 * (
     milestone.line_items.length > 0 ? doneItems.length / milestone.line_items.length : 1)

   const fromMoment = Moment(milestone.start_date)
   const toMoment = Moment(milestone.end_date)
   const isDone = milestone.done
   const isOverdue = milestone.overdue
   const colorClass = isOverdue ? 'orange' : (isDone ? 'green' : 'blue')

   return {
     id: milestone.id,
     classes: [colorClass],
     amountDone: pctDone,
     from: fromMoment,
     to: toMoment,
     label: `${milestone.name || 'Unknown'}`,
     showButton: true,
     enableDragging: !milestone['done?'] && CAN.edit_milestones,
     milestone: milestone,
     resourceId: `${milestone.vendor.id}-${milestone.id}`,
     // html: ''
   }
 })

 // TODO
 $: timeRanges = targetMoment ? [
   {
     id: 0,
     from: targetMoment,
     to: targetMoment.clone().add('year', 1),
     classes: null,
     label: 'Lunch'
   }
 ] : []

 let loaded = false
 async function loadMilestones () {
   const response = await SFR3.projects.milestones.list(project.id);
   const data = await response.json()
   milestones = data.items
   loaded = true
 }

 let show = false
 let arrowRotation = tweened(0, { duration: 700, easing: bounceOut })
 $: $arrowRotation = show ? 90 : 0
 async function toggle () {
   if (!loaded) {
     await loadMilestones()
   }

   show = !show
 }

 onMount(async () => {
   CAN = await Permissions.forScope('projects', project.id)
 })

 const onCreateMilestone = (event) => {
   milestones = [...milestones, event.detail.milestone]
   if (createWorkOrdersButton) {
     createWorkOrdersButton.refresh()
   }
 }

 let cancelNextSelect = false
 let selectedMilestone = null
 const onTaskSelect = (tasks) => {
   if (cancelNextSelect) {
     cancelNextSelect = false
     gantt.unselectTasks()
     return
   }

   if (tasks[0]) {
     selectedMilestone = tasks[0].model.milestone
   }
 }

 const onTaskChanged = async (taskData) => {
   const taskModel = taskData.task && taskData.task.model
   const milestone = taskModel && taskData.task.model.milestone
   if (!milestone) { return }

   const newStart = Moment(taskModel.from).startOf('day')
   const newEnd = Moment(taskModel.to).startOf('day')
   const newMilestoneData = {
     start_date: newStart,
     end_date: newEnd > newStart ? newEnd : newStart.clone().add(1, 'day')
   }

   const newStartString = newMilestoneData.start_date.format()
   const oldStartString = Moment(milestone.start_date).format()
   const newEndString = newMilestoneData.end_date.format()
   const oldEndString = Moment(milestone.end_date).format()
   if (newStartString != oldStartString && newEndString == oldEndString) {
     // Annoying; when only the start date is modified, the milestone counts
     // as selected for some reason
     cancelNextSelect = true
   }

   if (newStartString != oldStartString || newEndString != oldEndString) {
     const response = await SFR3.projects.milestones.changeDates(
       milestone.id,
       project.id,
       newMilestoneData.start_date,
       newMilestoneData.end_date
     )

     if (response.ok) {
       const data = await response.json()
       project = {...project, ...data.milestone.project}
     } else {
       alert('Error updating dates for milestone')
       milestones = milestones // Refresh
       return
     }
   }

   Object.assign(milestone, newMilestoneData)
   milestones = milestones // Refresh
 }

 const onMilestoneChanged = (event) => {
   const newMilestoneData = event.detail.milestone
   const i = milestones.findIndex((m) => m.id == newMilestoneData.id)

   // Refresh the gantt chart set of milestones
   milestones = [...milestones.slice(0, i), newMilestoneData, ...milestones.slice(i+1)]

   // Refresh the project data as well, e.g. targetDate
   project = {...project, ...newMilestoneData.project}
 }

 const clearSelected = () => {
   selectedMilestone = null
   gantt.unselectTasks()
 }
</script>

<style>
  .toggle {
    cursor: pointer;
  }
 
  .arrow {
    display: inline-block;
  }
 </style>
 
 <div class="my-4">
   <div class="row">
     <div class="col">
       <h2>
         <span class="toggle" on:click={toggle}>
           Schedule
           {#if project.dollar_per_day}
             ({formatCentsRound(project.dollar_per_day)}/day)
           {/if}
           <span class="arrow" style="transform: rotate({$arrowRotation}deg);">
             &#x25B6;
           </span>
         </span>
       </h2>
     </div>
 
     {#if CAN.edit_milestones}
       <div class="col-sm-5 text-sm-right">
         <NewMilestoneButton {project} on:created={onCreateMilestone} />
       </div>
     {/if}
   </div>
 </div>

{#if loaded && show}
  <div transition:slide class="my-4">
    {#if CAN.create_work_order}
      <CreateWorkOrdersButton {project} bind:this={createWorkOrdersButton}/>
    {/if}

    <div class="d-none d-sm-block">
      <Gantt {rows} {tasks} {onTaskChanged} {onTaskSelect} bind:timeRanges bind:this={gantt} />
    </div>

    <div class="d-block d-sm-none mt-3">
      {#each milestones as milestone, i (milestone.id)}
        <div class:border-top={!i} class:bg-light={i % 2 ==0 } class="border-bottom border-left border-right">
          <MobileRow bind:milestone selectMilestone={() => selectedMilestone = milestone} />
        </div>
      {/each}
    </div>
  </div>
{/if}

<Modal showDialog={selectedMilestone} fullWidth={true}
       title="{selectedMilestone && selectedMilestone.name}"
       on:close={clearSelected}>
  <ShowMilestone bind:milestone={selectedMilestone} on:datesChanged={onMilestoneChanged} on:lineItemsEdited={onMilestoneChanged} />
</Modal>
