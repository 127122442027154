<script>
  import { SFR3 } from '../../javascript/sfr3_api';
  import Typeahead from "svelte-typeahead";

  let data = []
  let value = ''

  const searchAddress = async (value) => {
    if (value.detail.length > 2) {
      const response = await SFR3.properties.fuzzySearch(value.detail);
      data = await response.json();
    }
  }

  const goToProperty = (selected) => {
    window.location = selected.detail.original.url;
  }

  const extract = (property) => {
    let numberRegex = /^\d+$/;
    let result = '';
    if (numberRegex.test(value)) {
      result = property.sfr3_id.toString()
    } else {
      result = property.address
    }
    return result
  }
</script>

<style>
  :global([data-svelte-search] input) {
    width: 100%;
    font-size: 1rem;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border: 1px solid #0558f3;
    border-radius: 0.5rem;
  }
</style>

<Typeahead
  label=""
  placeholder="Address or Property ID"
  focusAfterSelect
  limit={4}
  {data}
  {extract}
  bind:value
  on:type={searchAddress}
  on:select={goToProperty}>
  <svelte:fragment slot="no-results">
    No results found for "{value}"
  </svelte:fragment>
</Typeahead>
<br/>
<br/>
<br/>
