<script>
  import { onMount } from 'svelte'
  import { slide } from 'svelte/transition'
  import { tweened } from 'svelte/motion'
  import { bounceOut } from 'svelte/easing'
  import { Stretch } from 'svelte-loading-spinners'

  import { SFR3 } from '../../../javascript/sfr3_api'

  import WorkApprovalRow from './show'

  export let project // { id: X }

  let workApprovals = []

  // Loading WAs
  let loaded = false
  async function loadWorkApprovals () {
    const response = await SFR3.workApprovals.listByProject(project.id);
    const data = await response.json()
    if (!response.ok) {
      alert('Error loading work approvals')
      return
    }

    workApprovals = data.items || []
    loaded = true
  }

  onMount(loadWorkApprovals)

  // Toggling
  let show = false
  let arrowRotation = tweened(0, { duration: 700, easing: bounceOut })
  $: $arrowRotation = show ? 90 : 0
  async function toggle () {
    show = !show
  }
 </script>

 <style>
  .toggle {
    cursor: pointer;
  }

  .arrow {
    display: inline-block;
  }
 </style>

 <div class="row my-4">
   <div class="col">
     <h2>
       <span class="toggle" on:click={toggle}>
         Work Approvals

         {#if workApprovals.length > 0}
           ({workApprovals.length})

           <span class="arrow" style="transform: rotate({$arrowRotation}deg);">
             &#x25B6;
           </span>
         {/if}
       </span>
     </h2>
   </div>
 </div>

 {#if show}
   {#if !loaded}
     <div transition:slide class="my-4 text-center">
       <Stretch color="#999" />
     </div>
   {/if}

   <div transition:slide class="my-4">
     {#each workApprovals as workApproval (workApproval.id)}
       <WorkApprovalRow {...workApproval}/>
     {/each}
   </div>
 {/if}
