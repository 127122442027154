
<style global>
  @import 'filepond/dist/filepond.css';
  @import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
</style>

<script>
  import FilePond, { registerPlugin, supported } from 'svelte-filepond';
  import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
  import { FileChecksum } from '@rails/activestorage/src/file_checksum'

  // Register the plugins
  registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

  // a reference to the component, used to call FilePond methods
  // e.g. pond.getFiles() will return the active files
  let pond

  // the name to use for the internal file input
  let name = 'filepond'

  let fileMap = {}
  let serverIdsToFileIds = {}
  export let attachments = []
  export const clear = () => {
    for (const file of pond.getFiles()) {
      pond.removeFile(file)
    }
  }

  let pondFiles = []

  $: attachments = Object.values(fileMap)

  export const clearFiles = (fileServerIds) => {
    fileServerIds.map((id) => {
      const fileId = serverIdsToFileIds[id]
      pond.removeFile(fileId)
      delete serverIdsToFileIds[id]
      delete fileMap[fileId]
    })
  }

  // handle filepond events
  function serverFileProcess(fieldName, file, metadata, load, errorFn, progress, abort) {
    let source, request

    FileChecksum.create(
      file,
      (checksum_error, checksum) => {
        if (checksum_error) errorFn()

        source = axios.CancelToken.source()
        request = axios.post(
          '/rails/active_storage/direct_uploads',
          {
            authenticity_token: window._SFR3_TOKEN,
            blob: {
              filename: file.name,
              content_type: file.type,
              byte_size: file.size,
              checksum: checksum
            },
            cancelToken: source.token
          }
        ).then(response => {
          if (!response.data) return errorFn()
          const signed_id = response.data.signed_id
          source = axios.CancelToken.source()
          const directUploadConfig = response.data.direct_upload
          request = axios.put(directUploadConfig.url, file, {
            headers: directUploadConfig.headers,
            cancelToken: source.token,
            onUploadProgress: (progressEvent) => {
              progress(progressEvent.lengthComputable, progressEvent.loaded, progressEvent.total)
            },
          }).then(response => {
            load(signed_id)
          }).catch(error => {
            if (!axios.isCancel(error)) {
              errorFn()
            }
          })
        }).catch(error => {
          if (!axios.isCancel(error)) {
            errorFn()
          }
        })
      }
    )

    return { abort: () => { if (source) source.cancel() } }
  }

  const serverConfig = {
    process: serverFileProcess,
    load: null, // Edit existing files on the server
    fetch: null, // Load from other servers
    revert: null // Also disabled
  }

  function onprocessfile (error, file) {
    const idToServerId = { };
    idToServerId[file.id] = file.serverId
    fileMap = {...fileMap, ...idToServerId};

    const serverIdToId = {};
    serverIdToId[file.serverId] = file.id
    serverIdsToFileIds = {...serverIdsToFileIds, ...serverIdToId}
  }

  function onremovefile (error, file) {
    delete fileMap[file.id]
    fileMap = fileMap // Force re-evaluation of dynamic code in Svelte
  }

  // Every time the set of uploaded files is modified, force a dynamic Svelte update as well
  const onupdatefiles = () => { pondFiles = pond.getFiles() }
</script>

<div class="app">
  <FilePond 
    {name}
    bind:this={pond} 
    allowMultiple={true}  
    {onprocessfile}
    {onremovefile}
    {onupdatefiles}
    server={serverConfig}
    labelIdle='Drag & Drop Files or <span class="filepond--label-action">Browse</span>'
    imagePreviewHeight={120}
    allowImagePreview={true}
    imagePreviewMaxFileSize={100}
    imagePreviewZoomFactor={1}
  />
</div>

