<script>
 import { createEventDispatcher } from 'svelte'

 import Fa from 'svelte-fa'
 import { faPencilRuler } from '@fortawesome/free-solid-svg-icons'

 import Modal from '../lib/modal'
 import UpdateChangeOrder from './update'

 export let changeOrder
 export let project

 export let showDialog = false

 const dispatch = createEventDispatcher()

 function toggleDialog () {
   showDialog = !showDialog
 }

 const closeModal = () => {
   showDialog = false
 }

 function handleUpdate (event) {
   showDialog = false
   dispatch('updated', event.detail) // Manually forward event after closing
 }
</script>

<style>
  .edit-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
</style>

{#if changeOrder.status == 'revision_needed'}
  <div class="edit-button"> 
    <div class="btn btn-success btn-block" style="width: 43%;" on:click|stopPropagation={toggleDialog}>
      <Fa icon={faPencilRuler}/>
      Edit Change Order
    </div>
  </div>
{/if}

<Modal bind:showDialog hideFooter={true} title="Edit Change Order" on:click={closeModal}>
  <UpdateChangeOrder {project} {changeOrder} on:updated={handleUpdate}></UpdateChangeOrder>
</Modal>
