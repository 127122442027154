<script>
 import { slide } from 'svelte/transition'

 export let text
 export let hasDetails = true
 export let expandedClass = 'font-italic'

 let detailsVisible = false
</script>

<div>
  <span style="cursor: pointer;" on:click={() => detailsVisible = !detailsVisible}>
    {#if hasDetails}
      <span>
        {#if detailsVisible}&#x25BC;{:else}&#x25B6;{/if}
      </span>
    {/if}

    <span>{text}</span>
  </span>

  {#if detailsVisible}
    <div transition:slide class="{expandedClass}">
      <slot>Details go here</slot>
    </div>
  {/if}
</div>
