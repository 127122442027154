import { writable } from 'svelte/store';

const storedMarkets = localStorage.getItem('payStoredMarkets');
export const markets = writable(JSON.parse(storedMarkets));

markets.subscribe(value => {
  localStorage.setItem("payStoredMarkets", JSON.stringify(value));
})

const storedHiddenField = localStorage.getItem('payStoredHiddenField');
export const hiddenField = writable(storedHiddenField)

hiddenField.subscribe(value => {
  localStorage.setItem("payStoredHiddenField", value)
})
