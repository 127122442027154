<script>
 import { createEventDispatcher } from 'svelte'
 const dispatch = createEventDispatcher()

 import Select from 'svelte-select'

 export let options = []
 export let column = {}

 let items = []

 column._qt_show = (cellData) => {
   if (!selectedOptions || selectedOptions.length === 0) { return true }
   return selectedOptions.indexOf(cellData) > -1
 }

 // Filter options to make sure it is a unique, sorted set
 function compareStrings (a, b) {
   const aBig = (a && a.toString().toUpperCase()) || ''
   const bBig = (b && b.toString().toUpperCase()) || ''

   if (aBig < bBig) { return -1 }
   if (aBig > bBig) { return 1 }
   return 0
 }

 $: {
   const optionValues = options.map((o) => o.value).filter((v, i, arr) => arr.indexOf(v) === i)
   items = optionValues.map((v) => options.find((o) => o.value === v)).sort((a, b) => compareStrings(a.label, b.label))
 }

 let selectedData // Ends up being an array of [{ index: i, value: 'abc', label: 'Abc' }...]
 $: selectedOptions = selectedData ? selectedData.map((o) => o.value) : []

 const fireChangeEvent = () => dispatch('change')
</script>

<style>
 .qi-table-select-header {
   font-size: 1rem;
   font-weight: 400;
   --inputPadding: 0;
   --height: 25px;
   --clearSelectRight: 0;
   --clearSelectTop: 0;
   --clearSelectWidth: 1.3rem;
   --multiSelectInputPadding: 0;
   --multiSelectPadding: 0 0.5rem;
   --multiItemHeight: 1.5rem;
   --multiItemMargin: 2px 2px 1px 0;
   --multiItemPadding: 0 5px 0 8px;
   --multiClearTop: 4px;
   --multiClearWidth: 12px;
   --multiClearHeight: 12px;
 }

 :global(.qi-table-select-header svg) {
   height: auto;
   width: auto;
 }
</style>

<div class="qi-table-select-header">
  <Select
    {items}
    isMulti={true}
    bind:value={selectedData}
    on:select={fireChangeEvent}
  ></Select>
</div>
