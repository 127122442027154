<script>
  import { SFR3 } from "../../javascript/sfr3_api";
  import { onMount } from "svelte";
  import { tweened } from "svelte/motion";
  import { bounceOut } from "svelte/easing";
  import { createEventDispatcher } from 'svelte'

  let projectTypesList;
  let arrowProjectTypeRotation = tweened(0, { duration: 700, easing: bounceOut });

  export let projectTypeIds;
  export let hiddenProjectTypeFilter;
  export let projectId
  export let filteredReset

  const dispatch = createEventDispatcher();

  const toggleProjectTypeDisplay = () => {
    hiddenProjectTypeFilter = !hiddenProjectTypeFilter;
    dispatch('toggleHiddenFilter', hiddenProjectTypeFilter)
    updateArrowRotation();
  };

  const updateArrowRotation = () => {
    if (!hiddenProjectTypeFilter) {
      $arrowProjectTypeRotation = 90;
    } else {
      $arrowProjectTypeRotation= 0;
    }
  }

  onMount(async () => {
    const response = await SFR3.project_types.list();
    if (response.ok) {
      projectTypesList = (await response.json()).project_types;
    }

    updateArrowRotation();
  });

  const handleClick = (event) => {
    event.target.checked
      ? projectTypeIds.push(event.target.value)
      : projectTypeIds.splice(projectTypeIds.indexOf(event.target.value), 1);

      updateStore();
  };

  function updateStore() {
    dispatch('projectTypesUpdated', projectTypeIds)
  }

  const filterByProjectType = () => {
    projectId = ''
    filteredReset();
  };

  const cleanFilterByProjectType = () => {
    projectTypesList.forEach((projectType) => {
      unCheckedProjectType(projectType.name);
    });
    if (projectTypeIds.length != 0) {
      projectTypeIds = [];
      filteredReset();
    }
  };

  const projectTypeChecked = projectTypeId => {
    return projectTypeIds.includes(projectTypeId.toString());
  };

  const unCheckedProjectType = projectTypeId => {
    const projectType = document.getElementById(projectTypeId);
    projectType.checked = false;
  };
</script>
<div class="col-sm text-left">
  <div>
    <div class="h5 mt-3" style="cursor: pointer" on:click={toggleProjectTypeDisplay}>
      Search by Project Type <span
        style="display: inline-block; transform: rotate({$arrowProjectTypeRotation}deg);"
        >&#x25B6;</span
      >
    </div>
    {#if !hiddenProjectTypeFilter}
      <div class="mt-3">
        <div
          style="padding: 0.375rem 0.25rem"
        >
          {#if projectTypesList == null}
            Loading...
          {:else}
            {#each projectTypesList as projectType}
              <div style="">
                <input
                  id={projectType.name}
                  type="checkbox"
                  value={projectType.name}
                  on:click={handleClick}
                  checked={projectTypeChecked(projectType.name)}
                />
                <label for={projectType.name}>{projectType.name}</label>
              </div>
            {/each}
          {/if}
        </div>

        <button
          class="btn btn-primary btn-sm"
          style="font-size: 1rem; padding: 0.3rem 0.6rem; margin-top: 10px;"
          on:click={filterByProjectType}
        >
          Apply Filter by Project Type
        </button>
        <button
        class="btn btn-secondary btn-sm"
        style="font-size: 1rem; padding: 0.3rem 0.6rem; margin-top: 10px;"
        on:click={cleanFilterByProjectType}
      >
        Clean Filter by Project Type
      </button>
      </div>
    {/if}
  </div>
</div>
