<script>
  import { SFR3 } from '../../javascript/sfr3_api';

  export let propertyId = null;
  
  let sfr3_params = null;
  let loaded = false; 

 const loadSFR3Params = async (page, perPage) => {
    const response = await SFR3.properties.sfr3Params(propertyId);
    sfr3_params = await response.json();
    Object.entries(sfr3_params).map((val) => val[0])
    loaded = true;
  };
</script>

<hr/>
<div class="row">
  <div class="col-12">
    {#if !loaded}
      <div class="row d-flex justify-content-center">
        <button class="btn btn-secondary" on:click={loadSFR3Params}>Load SFR3 params</button>
      </div>
    {:else}
      {#if sfr3_params}
        <h2 class="mt-3 mb-3 d-flex justify-content-center">Property data from SFR3Bot</h2>
        <div class="row">
          <div class="col-12">
            <table class="table table-striped">
              <tbody>
                {#each sfr3_params as param}
                  <tr>
                    <th>{param.name}</th>
                    <td>{param.value}</td>
                    <td>{param.type}</td>
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
        </div>
      {/if}
    {/if}
  </div>
</div>


