import { writable } from 'svelte/store';

const storedMarkets = localStorage.getItem('pcStoredMarkets');
export const markets = writable(JSON.parse(storedMarkets));

markets.subscribe(value => {
  localStorage.setItem("pcStoredMarkets", JSON.stringify(value));
})

const storedHiddenField = localStorage.getItem('pcStoredHiddenField');
export const hiddenField = writable(storedHiddenField)

hiddenField.subscribe(value => {
  localStorage.setItem("pcStoredHiddenField", value)
})
