<script>
  import { onMount } from "svelte";
  import { SFR3 } from '../../javascript/sfr3_api';
  import Paginator from "svelte-paginator";

  import AttachmentSection from "../lib/attachment_section";
  import LineItemTable from "../line_items/table";
  import ProcessingRow from "../lib/aasm_processing_row";
  import { formatCents } from "../../javascript/money";
  import FilterByMarket from "../lib/filter_by_market.svelte"
  import { tweened } from "svelte/motion";
  import { bounceOut } from "svelte/easing";

  export let propertyId = null

  let marketIds = []
  let resetQueue = null;
  let numChangeOrders = null

 const loadChangeOrders = async (page, perPage) => {
    numChangeOrders = null;
    const response = await SFR3.changeOrders.list({
      page: page,
      per_page: perPage,
      market_ids: (marketIds || []),
      property_info: propertyId
    });
    const responseObj = await response.json();
    numChangeOrders = responseObj.numItems;
    return responseObj;
  };
</script>

<div>
  <h1>
    Change Order{numChangeOrders === 1 ? "" : "s"}
    &ndash;
    {#if numChangeOrders !== null}
      {numChangeOrders}
    {:else}
      &hellip;
    {/if}
  </h1>
  <Paginator 
    loadItems={loadChangeOrders}
    bind:reset={resetQueue}
    let:items
    class_button="btn btn-outline-secondary"
    class_current_page="btn btn-secondary"
    class_button_group="btn-group"
  >

  <div class="my-3">
    {#if items === null}
      Loading...
    {:else}
      <ul class="list-group">
        {#each items as changeOrder}
          <ProcessingRow
            detailsVisible={true}
            aasm_object={changeOrder}
            aasm_api={SFR3.changeOrders}
            colors={{approve: 'success', cancel: 'danger', reject: 'danger', revision: 'secondary'}}
            modals={{
              reject: [
                { field: "rejection_reason", title: "Reason for Rejecting" },
              ],
              revision: [
                { field: "revision_reason", title: "Reason for Requesting a Revision" },
              ],
              approve: [
                { field: "approval_reason", title: "Reason for Approving" },
              ]
            }}
          >
            <div slot="header">
              <h5 class="mb-0 font-weight-light">
                <span class="font-weight-bold"
                  >{formatCents(changeOrder.costCents)}</span
                >
                for
                <span class="font-weight-normal">
                  {changeOrder.project.address}
                </span>
              </h5>
              <div class="row font-weight-light">
                <div class="col-sm-1 ml-3">
                  <a
                    on:click|stopPropagation
                    href="/renovations/{changeOrder.project.id}"
                    target="_blank">View</a
                  >
                </div>
                <div class="col-md">PM: <em>{changeOrder.project.rm}</em></div>
                <div class="col-md-3">
                  Metro: <em>{changeOrder.project.market}</em>
                </div>
              </div>
            </div>

            <div slot="details">
              <div class="container mb-3">
                {#if changeOrder.description}
                  <h5>Note</h5>
                  <div class="font-italic">{changeOrder.description}</div>
                {/if}
                <AttachmentSection
                  files={[ ...changeOrder.attachments, ...changeOrder.line_items_attachments ]}
                  canUpload={false}
                  forceShow={true}
                  titleClass="h5"
                />
              </div>

              {#if changeOrder.rejection_reason}
                <div class="container mb-3">
                  <h5>Reason for Rejection</h5>
                  <div>{changeOrder.rejection_reason}</div>
                </div>
              {/if}

              {#if changeOrder.approval_reason}
                <div class="container mb-3">
                  <h5>Reason for Approval</h5>
                  <div>{changeOrder.approval_reason}</div>
                </div>
              {/if}

              {#if changeOrder.lineItemsToAdd.length > 0}
                <div class="container">
                  <h5>
                    {changeOrder.lineItemsToAdd.length} Line Item{changeOrder
                      .lineItemsToAdd.length == 1
                      ? ""
                      : "s"} Added
                  </h5>
                </div>
                <LineItemTable
                  lineItems={changeOrder.lineItemsToAdd}
                  showQuantity={true}
                />
              {/if}

              {#if changeOrder.lineItemsToCancel.length > 0}
                <div class="container">
                  <h5>
                    {changeOrder.lineItemsToCancel.length} Line Item{changeOrder
                      .lineItemsToCancel.length == 1
                      ? ""
                      : "s"} Cancelled
                  </h5>
                </div>
                <LineItemTable
                  lineItems={changeOrder.lineItemsToCancel}
                  showQuantity={true}
                />
              {/if}
            </div>
          </ProcessingRow>
        {/each}
      </ul>
    {/if}
  </div>
  
    
  </Paginator>
</div>
