<script>
 import { createEventDispatcher } from 'svelte'
 import LineItemTable from './table'
 import CreateWorkApproval from '../work_approvals/create_form'

 export let lineItems

 let selectedItems = []

 const dispatch = createEventDispatcher()

 $: unassignedLineItems = lineItems.filter((li) => !li.work_order_id && !li['done?'])
 $: activeLineItems = lineItems.filter((li) => li.work_order_id && !li['done?'])
 $: doneLineItems = lineItems.filter((li) => li['done?'])

 const refreshComponents = () => { 
   lineItems = [...lineItems]

   dispatch('refresh', null)
  } // Force Refresh of LineItems and WorkOrders

 const unassignLineItems = (event) => lineItems = event.detail.line_items // Force Refresh after line items are unassigned

</script>

<div class="row no-gutters">
  <div class="col-md-9 border-right">
    <LineItemTable lineItems={lineItems} bind:group={selectedItems} showQuantity={true} />
  </div>

  <div class="col-md-3 text-right d-flex flex-column p-3">
    {#if activeLineItems.length > 0}
      <div class="flex-grow-1 mb-3">
        <CreateWorkApproval bind:lineItems={selectedItems} on:created={refreshComponents} on:unassign={unassignLineItems}/>
      </div>
    {:else if unassignedLineItems.length > 0}
      <div class="alert alert-warning">
        Click the "Create WO" Button to assign these line items to the vendor
      </div>
    {/if}

    <slot name="destructiveActions" {activeLineItems}>
    </slot>
  </div>
</div>
